import React from 'react'

import Container from '@material-ui/core/Container'

import { makeStyles } from '@material-ui/core/styles'

import BusSchedule, { BusScheduleTime } from '../Map/BusSchedule'

const line = 'V15'
const destination = "Vall d'Hebron"

const stop = 'Via Laietana - Pl Ramon Berenguer'

const useStyles = makeStyles(theme => ({
  darkContainer: {
    background: '#000 linear-gradient(135deg, #666 0%, #000 40%)',
    height: '100%',
    width: '60vw',
    padding: theme.spacing(2),
    //color: theme.palette.common.white,
    margin: theme.spacing(2)
  },
  lightContainer: {
    border: '1px solid black',
    height: '100%',
    width: '60vw',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  fullContainer: {
    width: '95vw',
    padding: theme.spacing(2),
    border: '1px solid black',
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    maxWidth: '50vw'
  },
  image: {
    width: 64,
    height: 64
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  grow: {
    flexGrow: 1,
    textAlign: 'left'
  },
  line: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  rightValue: {
    width: '48px',
    textAlign: 'right'
  },
  vehicle: {
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  lineDestination: {
    lineHeight: 1,
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    paddingBottom: '.5rem',
    paddingTop: '.3rem'
  },
  stop: {
    lineHeight: 1,
    fontSize: '1rem',
    fontWeight: 200
    //textTransform: 'uppercase'
  },
  schedule: {
    textAlign: 'right'
  },
  time: {
    lineHeight: 1,
    fontSize: '2rem',
    textTransform: 'uppercase',
    fontWeight: 900
  },
  units: {
    lineHeight: 1,
    fontSize: '.8rem',
    textTransform: 'uppercase',
    fontWeight: 900
  }
}))

function Bus() {
  const classes = useStyles()
  const soon = Date.now() + 1000 * 60 * 7
  const late = Date.now() + 1000 * 60 * 23
  const now = Date.now() + 1000 * 19
  const lost = Date.now() - 1000 * 19
  return (
    <>
      <Container className={classes.fullContainer}>Full width</Container>

      <BusScheduleTime expected={lost} />
      <BusScheduleTime expected={now} />
      <BusScheduleTime expected={soon} />
      <BusScheduleTime expected={late} />

      {/* Schedule big, now*/}
      <Container className={classes.lightContainer}>
        <BusSchedule line={line} stop={stop} destination={destination} expected={now} />
      </Container>

      {/* Schedule big, late in minutes*/}
      <Container className={classes.lightContainer}>
        <BusSchedule line={line} stop={stop} destination={destination} expected={late} />
      </Container>

      {/* Schedule big, soon in minutes*/}
      <Container className={classes.lightContainer}>
        <BusSchedule line={line} stop={stop} destination={destination} expected={soon} />
      </Container>

      {/* Map overlay big, now*/}
      <Container className={classes.darkContainer}>
        <BusSchedule line={line} stop={stop} destination={destination} expected={now} dark />
      </Container>

      {/* Map overlay big, late in minutes*/}
      <Container className={classes.darkContainer}>
        <BusSchedule line={line} stop={stop} destination={destination} expected={late} dark />
      </Container>

      {/* Map overlay big, soon in minutes*/}
      <Container className={classes.darkContainer}>
        <BusSchedule line={line} stop={stop} destination={destination} expected={soon} dark />
      </Container>
    </>
  )
}
export default Bus
