import React, { createContext } from 'react'
import PropTypes from 'prop-types'

export const Context = createContext({})

export const Provider = props => {
  const { config, children } = props

  // pass the value in provider and return
  return <Context.Provider value={config}>{children}</Context.Provider>
}

export const { Consumer } = Context

Provider.propTypes = {
  config: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
