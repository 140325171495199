import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  dark: {
    color: theme.palette.common.white
  },
  lineDestination: {
    lineHeight: 1,
    fontSize: '2rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    paddingBottom: '.5rem',
    paddingTop: '.3rem',
    [`@media (max-width:1079px)`]: {
      fontSize: '1.5rem'
    }
  },
  stop: {
    lineHeight: 1,
    fontSize: '1.5rem',
    fontWeight: 200
    //textTransform: 'uppercase'
  },
  schedule: {
    textAlign: 'right'
  },
  time: {
    lineHeight: 1,
    fontSize: '3rem',
    textTransform: 'uppercase',
    fontWeight: 900,
    [`@media (max-width:1079px)`]: {
      fontSize: '2.6rem'
    }
  },
  timeFlashing: {
    lineHeight: 1,
    fontSize: '3rem',
    textTransform: 'uppercase',
    fontWeight: 900,
    color: 'red'
  },
  units: {
    lineHeight: 1,
    fontSize: '1.4rem',
    textTransform: 'uppercase',
    fontWeight: 900,
    [`@media (max-width:1079px)`]: {
      fontSize: '1.2rem'
    }
  },
  message: {
    fontSize: '1.3rem',
    lineHeight: 1.3,
    textTransform: 'uppercase',
    fontWeight: 900,
    paddingTop: theme.spacing(0.5),
    [`@media (max-width:1079px)`]: {
      fontSize: '1.2rem',
      lineHeight: 1.41
    }
  },
  progress: {
    color: props => props.progressColor
  },
  flashing: {
    color: props => props.flashingColor
  }
}))

const THRESHOLDS = {
  PROGRESS: 7 * 60,
  NEAR: 30,
  LEAVING: 15
}

export { THRESHOLDS }

function BusScheduleTime(props) {
  const { expected } = props
  const classes = useStyles(props)

  const minutes = Math.max(0, Math.floor((expected - Date.now()) / (1000 * 60)))
  const seconds = Math.ceil((expected - Date.now()) / 1000)

  return seconds > THRESHOLDS.NEAR ? (
    <>
      {seconds > THRESHOLDS.PROGRESS ? (
        <div className={classes.progress}>
          <Typography variant="body1" className={classes.time}>
            {minutes}
          </Typography>
          <Typography variant="body2" className={classes.units}>
            min
          </Typography>
        </div>
      ) : (
        <div className={classes.flashing}>
          {seconds > 60 ? (
            <>
              <Typography variant="body1" className={classes.time}>
                {minutes}
              </Typography>
              <Typography variant="body2" className={classes.units}>
                min
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" className={classes.time}>
                {seconds}
              </Typography>
              <Typography variant="body2" className={classes.units}>
                sec
              </Typography>
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <>
      {seconds > -THRESHOLDS.LEAVING ? (
        <div className={Math.abs(seconds % 2) > 0 ? classes.progress : classes.flashing}>
          <Typography variant="body1" className={classes.message}>
            Soon
          </Typography>
        </div>
      ) : (
        <div>
          {seconds > -2 * THRESHOLDS.LEAVING && (
            <Typography variant="body1" className={classes.message}>
              Left
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

BusScheduleTime.propTypes = {
  expected: PropTypes.number.isRequired
}

export { BusScheduleTime }

export default function BusSchedule(props) {
  const { line, destination, stop, expected, dark } = props
  const classes = useStyles()

  return (
    <Grid container spacing={1} className={dark ? classes.dark : null}>
      <Grid item xs={10}>
        <Typography variant="h5" className={classes.lineDestination}>
          {line} {destination}
        </Typography>
        <Typography variant="subtitle1" className={classes.stop}>
          {stop}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.schedule}>
        <BusScheduleTime expected={expected} />
      </Grid>
    </Grid>
  )
}

BusSchedule.propTypes = {
  expected: PropTypes.number.isRequired,
  line: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  stop: PropTypes.string.isRequired,
  dark: PropTypes.bool
}

BusSchedule.defaultProps = {
  dark: false
}
