import React from 'react'
import './App.css'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

// amplitude-js no está preparada para SSR, lo extraemos a un Hook y cargamos el provider condicionalmente https://github.com/amplitude/Amplitude-JavaScript/issues/110
// import amplitude from 'amplitude-js'
import { AmplitudeProvider } from '@amplitude/react-amplitude'
import useAmplitude from './useAmplitude'

import { Home, Sensor, Station, Map, Bus, Tutorial, Data } from '../Pages'

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_KEY

function InstrumentedApp(props) {
  const amplitude = useAmplitude()

  return amplitude ? (
    <AmplitudeProvider amplitudeInstance={amplitude} apiKey={amplitudeApiKey}>
      {props.children}
    </AmplitudeProvider>
  ) : (
    props.children
  )
}

const styles = theme => ({
  '@global': {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      // fontSize: 32,
      [`@media (orientation: landscape)`]: {
        fontSize: 16
      }
    }
  }
})

function App() {
  return (
    <>
      <InstrumentedApp>
        <Router>
          <Switch>
            <Route path="/data">
              <Data />
            </Route>
            <Route path="/tutorial">
              <Tutorial />
            </Route>
            <Route path="/bus">
              <Bus />
            </Route>
            <Route path="/sensor">
              <Sensor />
            </Route>
            <Route path="/station">
              <Station />
            </Route>
            <Route path="/map">
              <Map />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </InstrumentedApp>
    </>
  )
}

export default withStyles(styles)(App)
