import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import FlashOnIcon from '@material-ui/icons/FlashOn'

import Fade from '@material-ui/core/Fade'

import { makeStyles } from '@material-ui/core/styles'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import Map from '../Map/ExperimentalMap'
import SensorWidget, { WasteData, CO2Data, LightData, usePowerData, useLightData } from '../Sensor'
//import SocialDialog from '../Social/SocialDialog'

import { ConfigContext } from '../Config'

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  container: {
    height: '100%',
    padding: theme.spacing(2)
  },
  mapContainer: {
    height: '90vh',
    [`@media (max-width:1079px)`]: {
      height: '85vh'
    },
    [`@media (orientation: landscape)`]: {
      height: '75vh'
    }
  },
  sensorsContainer: {
    height: '10vh',
    [`@media (max-width:1079px)`]: {
      height: '15vh'
    },
    [`@media (orientation: landscape)`]: {
      height: '25vh'
    },
    background: config => config.palette.primary
  },
  socialContainer: {
    height: '20vh',
    [`@media (orientation: landscape)`]: {
      height: '25vh'
    }
  }
}))

function Home() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  const [powerReadings] = usePowerData()
  const [lightReadings] = useLightData()
  return (
    <>
      <Amplitude
        eventProperties={{
          scope: ['page'],
          title: 'City wallboard',
          url: '/'
        }}
      >
        <LogOnMount eventType="Page viewed" />
        <Grid container spacing={0} className={classes.mapContainer}>
          <Grid item xs={12}>
            <Map />
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.sensorsContainer}>
          <Fade in style={{ transitionDelay: `200ms` }}>
            <Grid item xs={3}>
              <SensorWidget
                title={WasteData.title}
                variation={WasteData.variation}
                value={WasteData.value}
                measure={WasteData.measure}
                icon={WasteData.icon}
              />
            </Grid>
          </Fade>
          <Fade in style={{ transitionDelay: `400ms` }}>
            <Grid item xs={3}>
              <SensorWidget
                title="Light"
                measure="w"
                variation={
                  lightReadings.data[lightReadings.data.length - 1].y -
                  lightReadings.data[lightReadings.data.length - 2].y
                }
                value={lightReadings.current}
                icon={<FlashOnIcon />}
                readings={lightReadings}
              />
            </Grid>
          </Fade>
          <Fade in style={{ transitionDelay: `600ms` }}>
            <Grid item xs={3}>
              <SensorWidget
                title={CO2Data.title}
                measure={CO2Data.measure}
                variation={CO2Data.variation}
                value={CO2Data.value}
                icon={CO2Data.icon}
              />
            </Grid>
          </Fade>
          <Fade in style={{ transitionDelay: `800ms` }}>
            <Grid item xs={3}>
              <SensorWidget
                title="Power"
                measure="w"
                variation={
                  powerReadings.data[powerReadings.data.length - 1].y -
                  powerReadings.data[powerReadings.data.length - 2].y
                }
                value={powerReadings.current}
                icon={<FlashOnIcon />}
                readings={powerReadings}
              />
            </Grid>
          </Fade>
        </Grid>
        {/*
      <Grid container spacing={0} className={classes.socialContainer}>
        <Grid item xs={4}>
          <Container className={classes.container}>
            <Typography variant="body1" gutterBottom className={classes.title}>
              Linkedin
            </Typography>
            <SocialDialog />
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container className={classes.container}>
            <Typography variant="body1" gutterBottom className={classes.title}>
              Twitter
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container className={classes.container}>
            <Typography variant="body1" gutterBottom className={classes.title}>
              Instagram
            </Typography>
          </Container>
        </Grid>
      </Grid>
      */}
      </Amplitude>
    </>
  )
}

export default Home
