import React from 'react'
import PropTypes from 'prop-types'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  row: {
    lineHeight: '5rem'
  },
  container: {
    marginBottom: theme.spacing(4)
  },
  indicatorColumn: {
    alignSelf: 'center',
    minWidth: '2rem'
  },
  indicator: {
    height: props => (props.small ? '.95rem' : '1.5rem')
  },
  variation: {
    position: 'relative',
    top: theme.spacing(1)
  },
  measure: {
    alignSelf: 'flex-end',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`
  },
  icon: {
    alignSelf: 'flex-end',
    padding: `${theme.spacing(1.25)}px ${theme.spacing(0.5)}px`
  },
  title: {
    // textTransform: 'uppercase',
    fontWeight: '800'
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap'
  }
}))

const IncrementIcon = withStyles(() => ({
  root: {
    color: '#a4eb64'
  }
}))(KeyboardArrowUpIcon)

const DecrementIcon = withStyles(() => ({
  root: {
    color: '#ff5e00'
  }
}))(KeyboardArrowDownIcon)

function SensorReading(props) {
  const { title, value, variation } = props
  const classes = useStyles()
  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} container alignItems="flex-start">
        <Grid item>
          <Typography variant="h2">{value}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={classes.indicatorColumn}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.indicator}>
            {variation > 0 && <IncrementIcon fontSize="default" />}
          </Grid>
          <Grid item className={classes.indicator}>
            {variation < 0 && <DecrementIcon fontSize="default" />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default SensorReading

SensorReading.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  measure: PropTypes.string,
  icon: PropTypes.element
}

SensorReading.defaultProps = {
  value: null,
  variation: 0,
  measure: null,
  icon: null
}
