import React, { useEffect } from 'react'

import { useLightData } from '../Data'

export default function Data() {
  // Dialog status
  const [readings] = useLightData()

  useEffect(() => {
    console.log(readings)
  }, [readings])

  return (
    <>
      <pre>{JSON.stringify(readings.data, null, 2)}</pre>
      <pre>{JSON.stringify(readings.prediction, null, 2)}</pre>
    </>
  )
}
