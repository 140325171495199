import React from 'react'

import Button from '@material-ui/core/Button'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import IconButton from '@material-ui/core/IconButton'

import OnboardingDialog from '../Onboarding/OnboardingDialog'
import {
  // TitleImageTextCard,
  TitleIllustrationTextCard,
  IllustrationSubtitleTextCard
} from '../Onboarding/Cards'

import { getSteps } from '../Onboarding/BusOnboarding'

export default function TextMobileStepper() {
  // Dialog status
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Stats
  const stats = {
    stations: 11,
    exchanges: 20,
    lines: 4,
    routes: 6,
    schedules: 14,
    vehicles: 11,
    unique: 4
  }

  // Cards
  const busSteps = getSteps(stats).map(el => {
    switch (el.type) {
      case 'TitleIllustrationTextCard':
        return (
          <TitleIllustrationTextCard key={el.label} label={el.label} svg={el.svg} text={el.text} />
        )
      case 'IllustrationSubtitleTextCard':
        return (
          <IllustrationSubtitleTextCard
            key={el.label}
            label={el.label}
            svg={el.svg}
            text={el.text}
          />
        )
      default:
        return (
          <TitleIllustrationTextCard key={el.label} label={el.label} svg={el.svg} text={el.text} />
        )
    }
  })

  return (
    <>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        See more
      </Button>

      <IconButton color="primary" onClick={handleClickOpen}>
        <ContactSupportIcon />
      </IconButton>
      <OnboardingDialog open={open} close={handleClose} steps={busSteps} stats={stats} />
    </>
  )
}
