import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  progressBar: {
    width: '36px',
    height: theme.spacing(0.25),
    borderRadius: theme.spacing(0.25),
    zIndex: '100',
    marginLeft: props => (props.right ? 'auto' : 'unset')
  }
}))

const LightLinearProgress = withStyles(theme => ({
  colorPrimary: {
    backgroundColor: 'rgba(255,255,255,0.2)'
  },
  barColorPrimary: {
    backgroundColor: theme.palette.common.white
  }
}))(LinearProgress)

const DarkLinearProgress = withStyles(theme => ({
  colorPrimary: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  barColorPrimary: {
    backgroundColor: theme.palette.common.black
  }
}))(LinearProgress)

export default function ProgressBar(props) {
  const { ttl, timestamp, dark } = props
  const classes = useStyles(props)

  // Progress timer
  const [completed, setCompleted] = useState(0)

  useEffect(() => {
    setCompleted(0)

    function progress() {
      const step = (100 * 0.5) / ttl
      // console.log("step: " + step);
      setCompleted(oldCompleted => {
        if (oldCompleted === 100) {
          return 100
        }
        // console.log("progress: " + (oldCompleted + step));
        return Math.min(oldCompleted + step, 100)
      })
    }

    const timer = setInterval(progress, 500)
    return () => {
      clearInterval(timer)
    }
  }, [ttl, timestamp])

  return dark ? (
    <DarkLinearProgress variant="determinate" value={completed} className={classes.progressBar} />
  ) : (
    <LightLinearProgress variant="determinate" value={completed} className={classes.progressBar} />
  )
}

ProgressBar.propTypes = {
  ttl: PropTypes.number.isRequired,
  dark: PropTypes.bool,
  right: PropTypes.bool,
  timestamp: PropTypes.number
}

ProgressBar.defaultProps = {
  dark: false,
  right: false,
  timestamp: null
}
