import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import Container from '@material-ui/core/Container'

import { makeStyles } from '@material-ui/core/styles'

import { ConfigContext } from '../Config'

import ProgressBar from '../Progress'
import Metric from '../Metric'
import SensorDrawer from './SensorDrawer'

const useStyles = makeStyles(theme => ({
  container: config => ({
    // background: '#e20074 linear-gradient(135deg, #e28800 0%, #e20074 40%)',
    background: `${config.palette.primary} linear-gradient(135deg, ${config.palette.accent} 0%, ${config.palette.primary} 40%)`,
    height: '100%',
    padding: theme.spacing(2),
    color: theme.palette.common.white
  }),
  title: {},
  kpi: {
    fontWeight: 800
  },
  metric: {
    fontSize: '2rem'
  }
}))

export default function SensorWidget(props) {
  const config = useContext(ConfigContext)

  const { title, value, variation, icon, measure, readings } = props
  const classes = useStyles(config)

  // Control station drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  function handleClose() {
    setIsDrawerOpen(false)
  }

  return (
    <>
      <Container
        className={classes.container}
        onClick={() => {
          setIsDrawerOpen(true)
        }}
      >
        <ProgressBar
          ttl={readings ? readings.ttl : 25}
          timestamp={readings ? readings.timestamp : null}
        />
        <Metric
          title={title}
          value={value}
          variation={variation}
          measure={measure} /*icon={icon}*/
        />
      </Container>
      <SensorDrawer
        open={isDrawerOpen}
        handleClose={handleClose}
        title={title}
        value={value}
        variation={variation}
        measure={measure}
        icon={icon}
        readings={readings}
      />
    </>
  )
}

SensorWidget.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  measure: PropTypes.string,
  icon: PropTypes.element,
  readings: PropTypes.shape({
    data: PropTypes.array,
    prediction: PropTypes.array,
    confidence: PropTypes.array,
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sensors: PropTypes.array,
    domain: PropTypes.array,
    ttl: PropTypes.number,
    timestamp: PropTypes.number
  })
}

SensorWidget.defaultProps = {
  measure: null,
  icon: null,
  readings: null
}
