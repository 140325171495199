import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import SvgIcon from '@material-ui/core/SvgIcon'

import Fade from '@material-ui/core/Fade'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import { BusScheduleTime } from './BusSchedule'

import { ConfigContext } from '../Config'

function BarcelonaMetroIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="0" x2="0" y1="0" y2="60">
        <stop offset="0" stopColor="#2c3e50" />
        <stop offset="1" stopColor="#bdc3c7" />
      </linearGradient>
      <path
        d="M19.646,20.323h7.115l3.277,11.672l3.2-11.598h7.326v18.781h-5.549v-6.974l0.358-4.55l-3.205,11.381h-4.269l-3.272-11.881
	l0.358,5.195v6.686h-5.339V20.323z M30.035,8.941L2.5,29.787l27.464,21.273L57.5,30.216L30.035,8.941z"
        fill="url(#a)"
      />
    </SvgIcon>
  )
}

function BarcelonaBusIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="0" x2="0" y1="0" y2="60">
        <stop offset="0" stopColor="#2c3e50" />
        <stop offset="1" stopColor="#bdc3c7" />
      </linearGradient>
      <path
        d="M43.542,35.752c0-3.445-1.863-6.252-4.871-7.187c2.217-1.194,3.722-3.403,3.722-6.126c0-6.337-5.346-7.697-10.217-7.614
	v6.125c1.465,0.043,2.851,0.81,2.851,2.935c0,2.084-1.625,2.849-2.851,2.893v5.103h0.038c2.219,0,3.962,0.893,3.962,3.573
	c0,2.51-1.626,3.401-3.962,3.401h-0.038v6.336h0.354C38.592,45.191,43.542,42.983,43.542,35.752 M28.926,14.826h-9.979v30.365h9.979
	v-6.336h-2.612v-6.974h2.612v-5.103h-2.612V20.95h2.612V14.826z M57.5,29.998c0,15.19-12.314,27.501-27.499,27.501
	C14.812,57.499,2.5,45.188,2.5,29.998c0-15.187,12.312-27.497,27.501-27.497C45.186,2.501,57.5,14.811,57.5,29.998"
        fill="url(#b)"
      />
    </SvgIcon>
  )
}

function GenericExchangeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="0" x2="0" y1="0" y2="512">
        <stop offset="0" stopColor="#2c3e50" />
        <stop offset="1" stopColor="#bdc3c7" />
      </linearGradient>
      <g>
        <path
          fill="url(#c)"
          d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480
				C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"
        />
        <path
          fill="url(#c)"
          d="M315.36,100.64l-22.72,22.72l36.8,36.64H160c-17.673,0-32,14.327-32,32v48h32v-48h169.44l-36.64,36.64l22.56,22.56l64-64
				c6.204-6.241,6.204-16.319,0-22.56L315.36,100.64z"
        />
        <path
          fill="url(#c)"
          d="M352,320H182.56l36.64-36.64l-22.56-22.56l-64,64c-6.204,6.241-6.204,16.319,0,22.56l64,64l22.56-22.56L182.56,352H352
				c17.673,0,32-14.327,32-32v-48h-32V320z"
        />
      </g>
    </SvgIcon>
  )
}

const useStyles = makeStyles(theme => ({
  list: {
    width: '70vw',
    height: '100%',
    [`@media (max-width:1079px)`]: {
      width: '85vw'
    }
  },
  fullList: {
    width: 'auto'
  },
  container: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: theme.spacing(4)
  },
  bikeGrid: {
    marginTop: theme.spacing(4),
    flexGrow: 1
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  station: {
    marginBottom: theme.spacing(12),
    textTransform: 'uppercase',
    [`@media (max-width:1079px)`]: {
      marginBottom: theme.spacing(8)
    }
  },
  value: {},
  exchangeList: {
    marginBottom: theme.spacing(8),
    padding: 0
  },
  family: {
    textTransform: 'uppercase',
    fontWeight: '800',
    paddingLeft: theme.spacing(1),
    lineHeight: 1.1
  },
  line: {
    textTransform: 'uppercase',
    fontWeight: '300',
    paddingLeft: theme.spacing(1),
    fontSize: '3rem'
  },
  description: {
    fontWeight: '700',
    paddingLeft: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: 1.15,
    marginBottom: theme.spacing(1.5)
  },
  exchange: {
    fontWeight: '300',
    paddingLeft: theme.spacing(1),
    lineHeight: 1.15
  },
  properties: {
    padding: theme.spacing(4),
    paddingLeft: 0,
    marginBottom: theme.spacing(4),
    [`@media (max-width:1079px)`]: {
      marginBottom: 0,
      paddingTop: 0
    }
  },
  bikes: {
    color: '#e20074',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  icon: {
    paddingTop: theme.spacing(3.75),
    paddingRight: theme.spacing(1),
    textAlign: 'center' // center
  },
  docks: {
    color: '#333',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  malfunctions: {
    color: '#ffcc00',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  metaContainer: {
    textAlign: 'right'
  },
  meta: {
    color: '#ccc',
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  approachingVehicles: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    paddingLeft: 0
  },
  approachingVehicle: {
    padding: 0,
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: '4px solid #fafafa'
  },
  approach: { fontSize: '3rem', fontWeight: 100, marginBottom: theme.spacing(6) },
  close: {
    textAlign: 'right'
  }
}))

export default function BusStationDrawer(props) {
  const { open, handleClose, exchanges, paths, object } = props

  const config = useContext(ConfigContext)
  const classes = useStyles(config)

  const stopExchanges =
    Object.keys(object).length > 0
      ? exchanges.features
          .filter(el => el.properties.CODI_PARADA === object.properties.CODI_PARADA)
          .sort((a, b) =>
            `${a.properties.ID_OPERADOR} ${a.properties.NOM_FAMILIA} ${a.properties.CODI_LINIA}`.localeCompare(
              `${b.properties.ID_OPERADOR} ${b.properties.NOM_FAMILIA} ${b.properties.CODI_LINIA}`,
              undefined,
              { numeric: true, sensitivity: 'base' }
            )
          )
      : []

  /*
  const findNextVehicleFor = lid =>
    vehicles.find(el => el.CODI_PARADA === object.properties.CODI_PARADA && el.CODI_LINIA === lid)
              */

  const findNextVehicleFor = lid =>
    /*vehicles.find(
      el =>
        Object.keys(el.schedule).includes(object.properties.CODI_PARADA.toString()) &&
        el.properties.CODI_LINIA === lid
    )*/
    paths.find(
      el =>
        el.schedule.find(s => s.station === object.properties.CODI_PARADA) &&
        el.properties.CODI_LINIA === lid
    )

  const sideList = (
    <div className={classes.list} role="presentation" onClick={handleClose} onKeyDown={handleClose}>
      {object.properties && (
        <Amplitude
          eventProperties={inheritedProps => ({
            ...inheritedProps,
            scope: [...inheritedProps.scope, 'drawer'],
            drawer: 'Bus station',
            stationId: object.properties.CODI_PARADA,
            stationName: object.properties.NOM_PARADA
          })}
        >
          <LogOnMount eventType="Bus station drawer accessed" />
          <Container className={classes.container}>
            <Typography variant="subtitle1" className={classes.title}>
              Station {object.properties.CODI_PARADA}
            </Typography>
            <Typography variant="h1" className={classes.station}>
              {object.properties.NOM_PARADA}
            </Typography>

            {exchanges.features.length > 0 && (
              <Fade in style={{ transitionDelay: `300ms` }}>
                <Container className={classes.exchangeList}>
                  <Grid container>
                    {stopExchanges.map((x, i) => (
                      <Grid
                        key={x.id}
                        item
                        xs={6}
                        container
                        className={classes.properties}
                        justify="flex-start"
                      >
                        <Fade in style={{ transitionDelay: `${300 + 100 * (i + 1)}ms` }}>
                          <>
                            <Grid item xs={3} className={classes.icon}>
                              {x.properties.ID_OPERADOR === 1 && (
                                <BarcelonaMetroIcon style={{ width: '48', height: '48' }} />
                              )}
                              {x.properties.ID_OPERADOR === 2 && (
                                <BarcelonaBusIcon style={{ width: '48', height: '48' }} />
                              )}
                              {x.properties.ID_OPERADOR > 2 && (
                                <GenericExchangeIcon style={{ width: '48', height: '48' }} />
                              )}

                              {findNextVehicleFor(x.properties.CODI_LINIA) !== undefined && (
                                <BusScheduleTime
                                  expected={
                                    findNextVehicleFor(x.properties.CODI_LINIA).schedule.find(
                                      el => el.station === object.properties.CODI_PARADA
                                    ).timestamp
                                  }
                                  flashingColor={config.palette.accent}
                                  progressColor={config.palette.primary}
                                />
                              )}
                            </Grid>
                            <Grid item xs={9} className={classes.grow}>
                              <Typography variant="body1" className={classes.family}>
                                {x.properties.NOM_FAMILIA}
                              </Typography>
                              <Typography variant="subtitle1" className={classes.line}>
                                {x.properties.NOM_LINIA}
                              </Typography>
                              <Typography variant="subtitle2" className={classes.description}>
                                {x.properties.DESC_LINIA}
                              </Typography>
                              {x.properties.DESC_CORRESP !== x.properties.DESC_LINIA && (
                                <Typography variant="subtitle2" className={classes.exchange}>
                                  {x.properties.DESC_CORRESP}
                                </Typography>
                              )}
                            </Grid>
                          </>
                        </Fade>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              </Fade>
            )}

            {/*
            {vehicles.length > 0 && (
              <Fade in style={{ transitionDelay: `500ms` }}>
                <Container className={classes.approachingVehicles}>
                  <Typography variant="h4" className={classes.approach}>
                    Incoming:
                  </Typography>
                  {vehicles
                    .sort((a, b) => a.expected - b.expected)
                    .filter(el => el.CODI_PARADA === object.properties.CODI_PARADA)
                    .map((v, i) => (
                      <>
                        <Fade in style={{ transitionDelay: `${500 + 100 * (i + 1)}ms` }}>
                          <Container className={classes.approachingVehicle} key={v.id}>
                            <BusSchedule
                              line={getRouteData(v.ID_RECORREGUT).properties.NOM_LINIA}
                              stop={getRouteData(v.ID_RECORREGUT).properties.DESC_LINIA}
                              destination={getRouteData(v.ID_RECORREGUT).properties.DESTI_SENTIT}
                              expected={v.expected}
                            />
                          </Container>
                        </Fade>
                      </>
                    ))}
                </Container>
              </Fade>
            )}
                    */}
            {/*
            <Typography variant="h6">Object data</Typography>
            <pre>{JSON.stringify(object, null, 2)}</pre>
            <Typography variant="h6">Exchange data</Typography>
            <pre>
              {JSON.stringify(
                exchanges.features.filter(
                  el => el.properties.CODI_PARADA === object.properties.CODI_PARADA
                ),
                null,
                2
              )}
            </pre>
            <Typography variant="h6">Vehicle data</Typography>
            <pre>
              {JSON.stringify(
                vehicles.filter(el => el.CODI_PARADA === object.properties.CODI_PARADA),
                null,
                2
              )}
            </pre>
              */}
          </Container>
        </Amplitude>
      )}
    </div>
  )

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        {sideList}
      </Drawer>
    </div>
  )
}

BusStationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  exchanges: PropTypes.shape({}).isRequired,
  paths: PropTypes.arrayOf(PropTypes.object).isRequired,
  object: PropTypes.shape({
    properties: PropTypes.object,
    geometry: PropTypes.object,
    geometry_name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired
}
