import React, { useState, useEffect } from 'react'

import FlashOnIcon from '@material-ui/icons/FlashOn'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import DeleteIcon from '@material-ui/icons/Delete'

import { useLightData } from '../Data'

const WasteData = {
  title: 'Waste',
  variation: 0.7,
  value: 43,
  measure: '%',
  icon: <DeleteIcon />
}

const CO2Data = {
  title: 'CO2',
  variation: 0,
  value: 62,
  measure: 'ppm',
  icon: <BubbleChartIcon />
}

const LightData = {
  title: 'Light',
  variation: -2,
  value: 12,
  icon: <EmojiObjectsIcon />
}

const usePowerData = () => {
  const [hasExpired, setHasExpired] = useState(true)

  const initialData = [
    { x: 0, y: 732 },
    { x: 1, y: 724 },
    { x: 2, y: 724 },
    { x: 3, y: 728 },
    { x: 4, y: 731 },
    { x: 5, y: 728 },
    { x: 6, y: 727 },
    { x: 7, y: 722 },
    { x: 8, y: 698 },
    { x: 9, y: 732 }
  ]

  const initialPrediction = [{ x: 9, y: 732 }, { x: 10, y: 726 }, { x: 11, y: 726 }]

  const initialConfidence = [
    { x: 9, y: 730, y0: 734 },
    { x: 10, y: 722, y0: 730 },
    { x: 11, y: 718, y0: 734 }
  ]

  const initialSensors = [
    { title: '1st floor', value: 108, variation: 3, measure: 'watts', icon: <FlashOnIcon /> },
    { title: '2nd floor', value: 106, variation: -7, measure: 'watts', icon: <FlashOnIcon /> },
    { title: '3rd floor', value: 109, variation: 1, measure: 'watts', icon: <FlashOnIcon /> },
    { title: '4th floor', value: 104, variation: 0, measure: 'watts', icon: <FlashOnIcon /> },
    { title: '5th floor', value: 113, variation: -2, measure: 'watts', icon: <FlashOnIcon /> },
    { title: '6th floor', value: 115, variation: 4, measure: 'watts', icon: <FlashOnIcon /> },
    { title: '7th floor', value: 65, variation: 3, measure: 'watts', icon: <FlashOnIcon /> },
    { title: 'Basement', value: 12, variation: 4, measure: 'watts', icon: <FlashOnIcon /> }
  ]

  const [readings, setReadings] = useState({
    data: initialData,
    prediction: initialPrediction,
    confidence: initialConfidence,
    current: initialData[initialData.length - 1].y,
    sensors: initialSensors,
    domain: [690, 740],
    ttl: 10,
    timestamp: Date.now()
  })

  // Returns a random integer between min (included) and max (included)
  // Using Math.round() will give you a non-uniform distribution!
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // When expires, calc again
  useEffect(() => {
    const updateData = () => {
      // console.log('calc new reading')
      const values = readings

      // remove oldest reading
      values.data.shift()

      // move remaining readings
      for (let i = 0, len = values.data.length; i < len; i += 1) {
        values.data[i].x = i
      }

      // generate random new reading
      let newReading = values.data[values.data.length - 1].y + getRandomInt(-12, 12)
      if (newReading > readings.domain[1] || newReading < readings.domain[0]) {
        newReading = (readings.domain[0] + readings.domain[1]) / 2
      }
      values.data.push({ x: 9, y: newReading })
      values.current = newReading

      // compute prediction and confidence
      let newPrediction = values.data[values.data.length - 1].y + getRandomInt(-3, 3)
      if (newPrediction > readings.domain[1] || newPrediction < readings.domain[0]) {
        newPrediction = values.data[values.data.length - 1].y
      }
      values.prediction = [
        { x: 9, y: newReading },
        { x: 10, y: newPrediction },
        { x: 11, y: newPrediction }
      ]
      values.confidence = [
        {
          x: 9,
          y: Math.max(newReading - getRandomInt(2, 3), readings.domain[0]),
          y0: Math.min(newReading + getRandomInt(2, 3), readings.domain[1])
        },
        {
          x: 10,
          y: Math.max(newPrediction - getRandomInt(4, 5), readings.domain[0]),
          y0: Math.min(newPrediction + getRandomInt(4, 5), readings.domain[1])
        },
        {
          x: 11,
          y: Math.max(newPrediction - getRandomInt(6, 10), readings.domain[0]),
          y0: Math.min(newPrediction + getRandomInt(6, 10), readings.domain[1])
        }
      ]

      // update sensors
      for (let i = 0, len = values.sensors.length; i < len; i += 1) {
        const change = getRandomInt(-2, 2)
        values.sensors[i].value += change
        values.sensors[i].variation = change
      }

      setReadings(values)

      // set expiration and start update timer
      setHasExpired(false)
      const timer = setTimeout(() => {
        setHasExpired(true)
      }, 5000)
      return () => clearTimeout(timer)
    }

    if (hasExpired) {
      updateData()
    }
  }, [hasExpired, readings])

  return [readings]
}

export { WasteData, CO2Data, LightData, usePowerData, useLightData }
