import React from 'react'

import Container from '@material-ui/core/Container'

import FlashOnIcon from '@material-ui/icons/FlashOn'

import { makeStyles } from '@material-ui/core/styles'

import ProgressBar from '../Progress'
import Metric from '../Metric'

const useStyles = makeStyles(theme => ({
  smallContainer: {
    background: '#e20074 linear-gradient(135deg, #e28800 0%, rgba(226,0,116,1) 40%)',
    height: '100%',
    width: '25vw',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    margin: theme.spacing(2)
  },
  bigContainer: {
    width: '50vw',
    padding: theme.spacing(2),
    border: '1px solid black',
    margin: theme.spacing(2)
  }
}))

function Sensor() {
  const classes = useStyles()
  return (
    <>
      <Container className={classes.bigContainer}>
        <ProgressBar ttl={25} dark />
        <Metric
          title={`Big`}
          value={`746`}
          variation={`29.7`}
          measure={'Watts'}
          icon={<FlashOnIcon />}
        />
      </Container>

      <Container className={classes.bigContainer}>
        <ProgressBar ttl={25} dark right />
        <Metric
          title={`Big`}
          value={`746`}
          variation={`-28.2`}
          measure={'Watts'}
          icon={<FlashOnIcon />}
          right
        />
      </Container>

      <Container className={classes.smallContainer}>
        <ProgressBar ttl={25} />
        <Metric
          title={`Small`}
          value={`746`}
          variation={`3`}
          measure={'Watts'}
          icon={<FlashOnIcon />}
          small
        />
      </Container>

      <Container className={classes.smallContainer}>
        <ProgressBar ttl={25} right />
        <Metric
          title={`Small`}
          value={`746`}
          variation={`-28.2`}
          measure={'Watts'}
          icon={<FlashOnIcon />}
          small
          right
        />
      </Container>
    </>
  )
}
export default Sensor
