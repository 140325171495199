import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FlashOnIcon from '@material-ui/icons/FlashOn'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import Fade from '@material-ui/core/Fade'

// charts
import '../../../node_modules/react-vis/dist/style.css'
import {
  FlexibleXYPlot,
  LineMarkSeries,
  LineSeries,
  AreaSeries,
  Hint,
  GradientDefs,
  HorizontalGridLines,
  MarkSeries
} from 'react-vis'

import { ConfigContext } from '../Config'

import Metric from '../Metric'
import SensorReading from './SensorReading'

const useStyles = makeStyles(theme => ({
  list: {
    width: '75vw'
  },
  fullList: {
    width: 'auto',
    height: '95vh'
  },
  container: config => ({
    height: '100%',
    padding: theme.spacing(2),
    // background: '#e20074 linear-gradient(135deg, #e28800 0%, #e20074 30%)',
    background: `${config.palette.primary} linear-gradient(135deg, ${config.palette.accent} 0%, ${config.palette.primary} 40%)`,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column'
  }),
  metric: {
    marginBottom: theme.spacing(16)
  },
  readingsContainer: {
    marginTop: theme.spacing(4),
    flexGrow: 1,
    padding: 0
  },
  plotContainer: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(6),
    // flexGrow: 1
    height: '30%'
  },
  hintTitle: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  hintValue: {
    textTransform: 'uppercase',
    fontWeight: '100'
  },
  '@keyframes dash': {
    to: {
      strokeDashoffset: '-20'
    }
  },
  dashAnimated: {
    animation: '$dash .75s infinite linear',
    strokeLinecap: 'square'
  },
  close: {
    textAlign: 'right'
  },
  grid: {
    marginTop: theme.spacing(4)
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  value: {
    fontWeight: '800'
  },
  category: {
    textTransform: 'uppercase',
    fontWeight: '800',
    paddingLeft: theme.spacing(0.5),
    paddingBottom: theme.spacing(1.5)
  },
  bikes: config => ({
    color: config.palette.primary,
    paddingRight: theme.spacing(4)
  }),
  docks: {
    color: '#333',
    paddingRight: theme.spacing(4)
  },
  malfunctions: {
    color: '#ffcc00',
    paddingRight: theme.spacing(4)
  },
  meta: {
    position: 'absolute',
    bottom: theme.spacing(2),
    color: '#ccc'
  }
}))

export default function SensorDrawer(props) {
  const { open, handleClose, title, value, variation, icon, measure, readings } = props
  const config = useContext(ConfigContext)
  const classes = useStyles(config)

  const [selection, setSelection] = useState(null)

  const initialData = [
    { x: 0, y: 732 },
    { x: 1, y: 724 },
    { x: 2, y: 724 },
    { x: 3, y: 728 },
    { x: 4, y: 731 },
    { x: 5, y: 728 },
    { x: 6, y: 727 },
    { x: 7, y: 722 },
    { x: 8, y: 698 },
    { x: 9, y: 732 }
  ]

  const sensors = [
    {
      id: 0,
      title: '1st floor',
      value: 108,
      variation: 3,
      measure: 'watts',
      icon: <FlashOnIcon />
    },
    {
      id: 1,
      title: '2nd floor',
      value: 106,
      variation: -7,
      measure: 'watts',
      icon: <FlashOnIcon />
    },
    {
      id: 2,
      title: '3rd floor',
      value: 109,
      variation: 1,
      measure: 'watts',
      icon: <FlashOnIcon />
    },
    {
      id: 3,
      title: '4th floor',
      value: 104,
      variation: 0,
      measure: 'watts',
      icon: <FlashOnIcon />
    },
    {
      id: 4,
      title: '5th floor',
      value: 113,
      variation: -2,
      measure: 'watts',
      icon: <FlashOnIcon />
    },
    {
      id: 5,
      title: '6th floor',
      value: 115,
      variation: 4,
      measure: 'watts',
      icon: <FlashOnIcon />
    },
    { id: 6, title: '7th floor', value: 65, variation: 3, measure: 'watts', icon: <FlashOnIcon /> }
  ]

  const initialPrediction = [{ x: 9, y: 732 }, { x: 10, y: 726 }, { x: 11, y: 726 }]

  const initialConfidence = [
    { x: 9, y: 730, y0: 734 },
    { x: 10, y: 722, y0: 730 },
    { x: 11, y: 718, y0: 734 }
  ]

  return (
    <div>
      <Drawer anchor="bottom" open={open} onClose={handleClose}>
        <div className={classes.fullList} role="presentation">
          <Amplitude
            eventProperties={inheritedProps => ({
              ...inheritedProps,
              scope: [...inheritedProps.scope, 'drawer'],
              drawer: 'Sensor',
              sensorTitle: title,
              sensorMeasure: measure
            })}
          >
            <LogOnMount eventType="Sensor drawer accessed" />
            <Container className={classes.container} maxWidth={false}>
              <Grid container className={classes.metric}>
                <Grid item xs={4}>
                  <Metric
                    title={title}
                    value={value}
                    variation={variation}
                    measure={measure}
                    icon={icon}
                  />
                </Grid>
              </Grid>
              <Container className={classes.readingsContainer} maxWidth={false}>
                {readings ? (
                  <Grid container className={classes.readings} spacing={1}>
                    {readings.sensors.map((sensor, i) => (
                      <Fade
                        key={`reading-${title}-${sensor.id}`}
                        in
                        style={{ transitionDelay: `${200 + 50 * i}ms` }}
                      >
                        <Grid item xs={3}>
                          <SensorReading
                            title={sensor.title}
                            value={sensor.value}
                            variation={sensor.variation}
                            measure={sensor.measure}
                            icon={sensor.icon}
                          />
                        </Grid>
                      </Fade>
                    ))}
                  </Grid>
                ) : (
                  <Grid container className={classes.readings} spacing={1}>
                    {sensors.map((sensor, i) => (
                      <Fade
                        key={`reading-${title}-${sensor.id}`}
                        in
                        style={{ transitionDelay: `${200 + 50 * i}ms` }}
                      >
                        <Grid item xs={3}>
                          <SensorReading
                            title={sensor.title}
                            value={sensor.value}
                            variation={sensor.variation}
                            measure={sensor.measure}
                            icon={sensor.icon}
                          />
                        </Grid>
                      </Fade>
                    ))}
                  </Grid>
                )}
              </Container>

              <Fade in style={{ transitionDelay: `300ms` }}>
                <Container className={classes.plotContainer} maxWidth={false}>
                  <FlexibleXYPlot
                    stroke="white"
                    margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
                    onMouseLeave={() => setSelection(null)}
                    yDomain={readings ? readings.domain : null}
                  >
                    <HorizontalGridLines style={{ stroke: '#0000001a' }} />
                    {selection ? (
                      <LineSeries
                        data={[
                          { x: selection.x, y: selection.y },
                          { x: selection.x, y: readings ? readings.domain[0] : 698 }
                        ]}
                        stroke="#0000001a"
                        opacity={1}
                        strokeWidth={6}
                      />
                    ) : null}
                    <GradientDefs>
                      <linearGradient id="PredictionGradient" x1="0" x2="1" y1="0" y2="0">
                        <stop offset="0%" stopColor="#000" stopOpacity={0.1} />
                        <stop offset="750%" stopColor="#000" stopOpacity={0.4} />
                      </linearGradient>
                    </GradientDefs>
                    <AreaSeries
                      color="url(#PredictionGradient)"
                      data={readings ? readings.confidence : initialConfidence}
                      curve="curveStep"
                      style={{ strokeWidth: 0 }}
                    />
                    <LineSeries
                      data={readings ? readings.prediction : initialPrediction}
                      curve="curveStep"
                      strokeDasharray="10 10"
                      style={{ stroke: '#000', strokeWidth: '4px', opacity: '.5' }}
                      className={classes.dashAnimated}
                    />
                    <LineMarkSeries
                      data={readings ? readings.data : initialData}
                      curve="curveStep"
                      fill="white"
                      size={5}
                      markStyle={{ stroke: config.palette.primary, strokeWidth: '4px' }}
                      lineStyle={{ strokeWidth: '4px' }}
                      onNearestXY={point => setSelection(point)}
                    />

                    {selection ? (
                      <Hint
                        value={selection}
                        align={{
                          horizontal: Hint.ALIGN.AUTO,
                          vertical: Hint.ALIGN.BOTTOM_EDGE // Hint.ALIGN.BOTTOM_EDGE
                        }}
                        style={{ content: { fontSize: 18 }, margin: 8 }}
                      >
                        <div className="rv-hint__content">
                          <Typography variant="body2" className={classes.hintTitle}>
                            Watts
                          </Typography>
                          <Typography variant="h2" className={classes.hintValue}>
                            {`${selection.y}`}
                          </Typography>
                        </div>
                      </Hint>
                    ) : null}
                    {selection ? (
                      <MarkSeries
                        data={[
                          { x: selection.x, y: selection.y },
                          { x: selection.x, y: selection.y }
                        ]}
                        stroke="white"
                        fill="black"
                        size={5}
                        style={{ strokeWidth: '5px' }}
                      />
                    ) : null}
                  </FlexibleXYPlot>
                </Container>
              </Fade>
              <Grid container className={classes.close}>
                <Grid item xs={12}>
                  <IconButton color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Container>
          </Amplitude>
        </div>
      </Drawer>
    </div>
  )
}

SensorDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  measure: PropTypes.string,
  icon: PropTypes.element,
  readings: PropTypes.shape({
    data: PropTypes.array,
    prediction: PropTypes.array,
    confidence: PropTypes.array,
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sensors: PropTypes.array,
    domain: PropTypes.array
  })
}

SensorDrawer.defaultProps = {
  measure: null,
  icon: null,
  readings: null
}
