import React, { useState, useEffect } from 'react'
import axios from 'axios'
import throttledAxios from '../Map/ThrottledAxios'

import LightIcon from '@material-ui/icons/FlashOn'

const REFRESHRATE = 5 // in seconds

const ZONEID = 5
const TYPES = [
  3311, // LIGHT
  27036, // LIGHT_DALI
  27011 // LIGHT_GENERIC
]

// Hook to get light data
export default function useLightData(props) {
  const url = 'https://tsystem-iberia.pdxeng.ch:8000/cms/api/v1.0'

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [hasExpired, setHasExpired] = useState(true)

  // Initial data
  const initialData = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
    { x: 9, y: 0 }
  ]

  const initialPrediction = [{ x: 9, y: 0 }, { x: 10, y: 0 }, { x: 11, y: 0 }]

  const initialConfidence = [{ x: 9, y: 0, y0: 0 }, { x: 10, y: 0, y0: 0 }, { x: 11, y: 0, y0: 0 }]

  const sensorTemplate = {
    id: null,
    title: null,
    value: 0,
    variation: 0,
    measure: 'watts',
    icon: <LightIcon />
  }

  // Data
  const [updates, setUpdates] = useState([])
  const [readings, setReadings] = useState({
    data: initialData,
    prediction: initialPrediction,
    confidence: initialConfidence,
    current: initialData[initialData.length - 1].y,
    sensors: [],
    domain: [0, 0],
    ttl: REFRESHRATE,
    timestamp: Date.now()
  })

  // Returns a random integer between min (included) and max (included)
  // Using Math.round() will give you a non-uniform distribution!
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // Auth
  const [token, setToken] = useState(null)
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const payload = {
          username: process.env.REACT_APP_PARADOX_LIGHT_USERNAME,
          password: process.env.REACT_APP_PARADOX_LIGHT_PASSWORD,
          cms_uid: 'pdxeng'
        }
        const result = await axios.post(`${url}/token`, payload)
        // process fetched data and update info object
        if (result.data.response_code === 200) {
          setToken(result.data.token)
          console.log('light token acquired')
        }
      } catch (error) {}
    }
    fetchToken()
  }, [])

  // Get static data
  const [info, setInfo] = useState({
    devices: [],
    types: []
  })

  useEffect(() => {
    const fetchTypes = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(`${url}/inventory/categories/device_types`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        // process fetched data and update info object
        if (result.data.response_code === 200) {
          const types = result.data.device_types
          setInfo(prevInfo => ({ devices: prevInfo.devices, types }))
          console.log('light types set')
        }
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
    }

    if (token && info.types.length === 0) {
      fetchTypes()
    }
  }, [info.types, token])

  useEffect(() => {
    const fetchDevices = async () => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(`${url}/zones/${ZONEID}/devices_minimal_data`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        // process fetched data and update info object
        if (result.data.response_code === 200) {
          const { devices } = result.data

          // update sensors
          const sensors = []
          devices
            .filter(el => TYPES.includes(el.device_type_id))
            .forEach(el => {
              const sensor = { ...sensorTemplate, id: el.device_uid, title: el.device_label }
              console.log(sensor)

              sensors.push(sensor)
            })
          console.log(sensors)
          sensors.sort((a, b) => a.title.localeCompare(b.title))
          setReadings(prev => ({ ...prev, sensors }))

          setInfo(prevInfo => ({ devices, types: prevInfo.types }))
          console.log('light devices set')
        }
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
    }

    if (token && info.devices.length === 0) {
      fetchDevices()
    }
  }, [info.devices, token, sensorTemplate])

  // Set timer
  useEffect(() => {
    console.log('Light data timer set')
    const timer = setTimeout(() => {
      setHasExpired(true)
      console.log('Light data expired!')
    }, readings.ttl * 1000)
    return () => clearTimeout(timer)
  }, [readings, readings.timestamp])

  // When timer expires and info data is available, fetch measurements for devices
  useEffect(() => {
    const fetchMeasurements = async uid => {
      const values = {
        status: 0,
        has_light_dimming: false,
        light_dimming_status: 0,
        active_power: 0,
        updated: false
      }
      try {
        const result = await axios(`${url}/data/last/devices/${uid}/objects`, {
          headers: { Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache' }
        })
        // process fetched data and update readings object
        if (result.data.response_code === 200) {
          if (result.data.objects[0].resources.length === 0) {
            // we got an empty response (don't know why)
          } else {
            const summaryObject = result.data.objects.find(el => el.object_id === 3311)
            if (summaryObject !== undefined) {
              values.light_dimming_status = summaryObject.resources.find(
                el => el.name === 'light_dimming_status'
              ).value
            }
            const metricsObject = result.data.objects
              .sort((a, b) => a.timestamp - b.timestamp)
              .find(el => [27011, 27036].includes(el.object_id))
            if (metricsObject !== undefined) {
              values.active_power = metricsObject.resources.find(
                el => el.name === 'active_power'
              ).value
              values.updated = true
            }
          }
          setUpdates(prev => [...prev, { uid, measures: values }])
        }
      } catch (error) {}

      return null
    }

    if (token && hasExpired && info.devices.length > 0 && info.types.length > 0) {
      // get measurements for all devices
      info.devices
        .filter(el => TYPES.includes(el.device_type_id))
        .forEach(device => {
          fetchMeasurements(device.device_uid)
        })
    }
  }, [hasExpired, info, token])

  // When all measuremets resolved, update readings
  useEffect(() => {
    console.log(
      `found ${updates.length} updates for ${
        info.devices.filter(el => TYPES.includes(el.device_type_id)).length
      } devices`
    )
    if (
      updates.length === info.devices.filter(el => TYPES.includes(el.device_type_id)).length &&
      info.devices.length > 0
    ) {
      console.log('updating readings')

      // past readings
      const values = { ...readings }

      // remove oldest reading
      values.data.shift()

      // move remaining readings
      for (let i = 0, len = values.data.length; i < len; i += 1) {
        values.data[i].x = i
      }

      // update sensors
      updates.forEach(update => {
        const sensor = { ...values.sensors.find(el => el.id === update.uid) }
        if (sensor !== undefined && update.measures.updated) {
          const change = Math.round(update.measures.active_power) - sensor.value
          console.log(`updating values for sensor ${sensor.id}, power changed ${change}`)
          sensor.value = Math.round(update.measures.active_power)
          sensor.variation = change
          sensor.properties = {
            status: update.status,
            has_light_dimming: update.has_light_dimming,
            light_dimming_status: update.light_dimming_status
          }
          values.sensors = [sensor, ...values.sensors]
            .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
            .sort((a, b) => a.title.localeCompare(b.title))
        }
      })

      // with the updated measurements from all sensors, calc new aggregated value + prediction
      // a) aggregated value
      const newReading = values.sensors.map(item => item.value).reduce((prev, next) => prev + next)
      values.data.push({ x: 9, y: newReading })
      values.current = newReading

      // b) update domain
      values.domain[0] = 0
      values.domain[1] = Math.ceil(Math.max(...values.data.map(el => el.y)) * 1.2)

      // c) prediction and confidence
      let newPrediction = values.data[values.data.length - 1].y + getRandomInt(-3, 3)
      if (newPrediction > values.domain[1] || newPrediction < values.domain[0]) {
        newPrediction = values.data[values.data.length - 1].y
      }
      values.prediction = [
        { x: 9, y: newReading },
        { x: 10, y: newPrediction },
        { x: 11, y: newPrediction }
      ]
      values.confidence = [
        {
          x: 9,
          y: Math.max(newReading - getRandomInt(2, 3), values.domain[0]),
          y0: Math.min(newReading + getRandomInt(2, 3), values.domain[1])
        },
        {
          x: 10,
          y: Math.max(newPrediction - getRandomInt(4, 5), values.domain[0]),
          y0: Math.min(newPrediction + getRandomInt(4, 5), values.domain[1])
        },
        {
          x: 11,
          y: Math.max(newPrediction - getRandomInt(6, 10), values.domain[0]),
          y0: Math.min(newPrediction + getRandomInt(6, 10), values.domain[1])
        }
      ]

      // d) update ttl and timestamp
      values.ttl = REFRESHRATE
      values.timestamp = Date.now()

      // reset expiration
      setHasExpired(false)

      // update readings object and clear updates
      console.log(values)
      setReadings(values)
      setUpdates([])
      console.log('new light readings set')
    }
  }, [updates, info.devices, readings])

  return [readings]
}
