import React from 'react'

import StationDrawer from '../Map/StationDrawer'

const data = {
  open: true,
  id: 170,
  bikes: [
    {
      station_id: 1,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954361,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 2,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954471,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 3,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954478,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 4,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954256,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 5,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954502,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 6,
      num_bikes_available: 12,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954405,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 7,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954433,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 8,
      num_bikes_available: 22,
      num_bikes_available_types: {
        mechanical: 22,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954424,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 9,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 1
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954492,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 11,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954449,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 12,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954387,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 13,
      num_bikes_available: 50,
      num_bikes_available_types: {
        mechanical: 50,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954347,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 14,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954502,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 15,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954346,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 17,
      num_bikes_available: 34,
      num_bikes_available_types: {
        mechanical: 34,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954415,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 18,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954334,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 19,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 1
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954376,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 20,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954454,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 21,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954451,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 22,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954252,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 23,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954431,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 24,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 13,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954389,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 25,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954395,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 26,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 1
      },
      num_docks_available: 13,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954346,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 27,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954254,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 28,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954255,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 29,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 2
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954507,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 30,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954494,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 31,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954474,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 32,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954337,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 33,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954462,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 34,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954268,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 35,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 8,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954499,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 36,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954258,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 37,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954479,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 39,
      num_bikes_available: 42,
      num_bikes_available_types: {
        mechanical: 42,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954476,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 40,
      num_bikes_available: 12,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954268,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 41,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954458,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 42,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 2
      },
      num_docks_available: 8,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954460,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 44,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954307,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 45,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954460,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 46,
      num_bikes_available: 49,
      num_bikes_available_types: {
        mechanical: 49,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954399,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 47,
      num_bikes_available: 34,
      num_bikes_available_types: {
        mechanical: 33,
        ebike: 1
      },
      num_docks_available: 13,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954374,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 48,
      num_bikes_available: 18,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954499,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 49,
      num_bikes_available: 21,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 3
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954465,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 50,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954301,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 51,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954513,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 53,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954495,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 54,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 1
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954313,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 55,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954343,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 56,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954425,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 57,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954408,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 58,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954322,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 59,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 1
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954462,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 60,
      num_bikes_available: 22,
      num_bikes_available_types: {
        mechanical: 22,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954489,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 61,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954351,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 62,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954408,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 63,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954514,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 64,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954339,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 65,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954338,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 66,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954434,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 67,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954283,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 68,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954376,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 69,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954286,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 70,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 1
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954517,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 71,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954375,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 72,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954390,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 73,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954272,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 74,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 13,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954405,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 75,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954304,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 76,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954486,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 78,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954483,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 79,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 2
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954490,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 80,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954436,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 81,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954320,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 82,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 33,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954282,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 83,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954480,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 84,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954317,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 85,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954292,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 86,
      num_bikes_available: 12,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954324,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 87,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954377,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 88,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 2
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954256,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 89,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954475,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 90,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954446,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 92,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954273,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 93,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954428,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 94,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954360,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 95,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 32,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954414,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 96,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954413,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 97,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954336,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 98,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954388,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 99,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954471,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 100,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954336,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 101,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954408,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 102,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 1
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954442,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 103,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 0,
      is_renting: 0,
      is_returning: 0,
      last_reported: 1571439318,
      is_charging_station: true,
      status: 'PLANNED'
    },
    {
      station_id: 104,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 1
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954259,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 105,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954467,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 106,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954263,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 107,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954354,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 108,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954311,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 109,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954285,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 110,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954295,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 111,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954479,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 112,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954358,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 113,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954437,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 114,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954280,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 115,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954418,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 116,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954376,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 117,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954283,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 118,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954383,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 119,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954351,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 120,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954297,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 121,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954469,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 122,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954373,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 123,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954307,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 124,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 1
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954434,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 125,
      num_bikes_available: 21,
      num_bikes_available_types: {
        mechanical: 21,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954475,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 126,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 22,
        ebike: 1
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954366,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 127,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 2
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954475,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 128,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954456,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 129,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954279,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 130,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 1
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954494,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 131,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954451,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 132,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 1
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954351,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 133,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954480,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 134,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954372,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 135,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954395,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 136,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954296,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 137,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954501,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 138,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954382,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 139,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954351,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 140,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 1
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954277,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 141,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 1
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954403,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 142,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954292,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 143,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 22,
        ebike: 2
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954272,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 144,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954518,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 145,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 8,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954397,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 146,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 8,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954490,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 147,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954409,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 148,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954299,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 149,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954496,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 150,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954445,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 151,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954276,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 152,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954290,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 153,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954510,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 154,
      num_bikes_available: 25,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 0
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954362,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 155,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954310,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 156,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954353,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 157,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 8,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954432,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 158,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 9,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954281,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 159,
      num_bikes_available: 30,
      num_bikes_available_types: {
        mechanical: 29,
        ebike: 1
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954470,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 160,
      num_bikes_available: 32,
      num_bikes_available_types: {
        mechanical: 31,
        ebike: 1
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954422,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 161,
      num_bikes_available: 27,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 1
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954388,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 162,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954391,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 163,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 1
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954344,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 164,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 1
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954352,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 165,
      num_bikes_available: 22,
      num_bikes_available_types: {
        mechanical: 22,
        ebike: 0
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954501,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 166,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 1
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954402,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 167,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954348,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 168,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 3
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954350,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 170,
      num_bikes_available: 38,
      num_bikes_available_types: {
        mechanical: 36,
        ebike: 2
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954344,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 171,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 1
      },
      num_docks_available: 38,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954470,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 173,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954314,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 174,
      num_bikes_available: 12,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954333,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 175,
      num_bikes_available: 25,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954496,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 176,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954378,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 177,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954262,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 178,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954474,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 179,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954400,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 180,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 47,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954290,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 182,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954516,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 183,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954348,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 184,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954268,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 185,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954348,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 186,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954466,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 187,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 1
      },
      num_docks_available: 9,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954440,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 188,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954501,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 189,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954470,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 190,
      num_bikes_available: 27,
      num_bikes_available_types: {
        mechanical: 27,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954401,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 191,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954293,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 192,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954286,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 193,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954456,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 194,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954386,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 195,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954425,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 196,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 1
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954376,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 197,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954280,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 198,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954304,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 199,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954297,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 200,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954271,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 201,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 8,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954267,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 202,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954371,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 203,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954350,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 204,
      num_bikes_available: 28,
      num_bikes_available_types: {
        mechanical: 28,
        ebike: 0
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954450,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 205,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 2
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954268,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 206,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 32,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954336,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 207,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 1
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954405,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 208,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954439,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 209,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 1
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954306,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 210,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954366,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 211,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 13,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954471,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 212,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954417,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 213,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954403,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 214,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954410,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 215,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954429,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 216,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954491,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 217,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954275,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 218,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 8,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954467,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 219,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954312,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 220,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 3
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954448,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 221,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954440,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 222,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954365,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 223,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 34,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954374,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 224,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954294,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 225,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954490,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 226,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954305,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 227,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954368,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 228,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954380,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 229,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 28,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954431,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 230,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954313,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 231,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954428,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 232,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954474,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 233,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954281,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 234,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954509,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 235,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954336,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 236,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954452,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 237,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954261,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 238,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 1
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954403,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 239,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 1
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954468,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 240,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 1
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954263,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 241,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 32,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954354,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 242,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954433,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 243,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 10,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954479,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 244,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 1
      },
      num_docks_available: 8,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954282,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 246,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954507,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 247,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 28,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954339,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 248,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 10,
        ebike: 1
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954339,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 249,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954416,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 250,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 8,
        ebike: 2
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954270,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 251,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954262,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 252,
      num_bikes_available: 12,
      num_bikes_available_types: {
        mechanical: 10,
        ebike: 2
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954433,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 253,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954348,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 254,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954309,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 255,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954347,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 256,
      num_bikes_available: 21,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 1
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954427,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 257,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954439,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 258,
      num_bikes_available: 27,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 1
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954479,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 259,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954311,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 260,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954381,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 261,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 32,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954340,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 262,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954480,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 263,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 1
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954319,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 264,
      num_bikes_available: 30,
      num_bikes_available_types: {
        mechanical: 29,
        ebike: 1
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954267,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 265,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954449,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 266,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954331,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 267,
      num_bikes_available: 21,
      num_bikes_available_types: {
        mechanical: 21,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954426,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 268,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954399,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 269,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954308,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 270,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954516,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 271,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 1
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954367,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 272,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954426,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 273,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954270,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 274,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 1
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954294,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 275,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954259,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 276,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954497,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 277,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954449,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 278,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954387,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 279,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954490,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 280,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954289,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 281,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954258,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 282,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954437,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 283,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954446,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 284,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954492,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 285,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954471,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 286,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954444,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 287,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954441,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 288,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954439,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 289,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954410,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 290,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954284,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 291,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954344,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 292,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954332,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 293,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954444,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 294,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954509,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 295,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954309,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 296,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 33,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954509,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 297,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 1
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954367,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 298,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954428,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 299,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954485,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 300,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954465,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 301,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954468,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 302,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 23
      },
      num_docks_available: 9,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954432,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 303,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954494,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 304,
      num_bikes_available: 18,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 3
      },
      num_docks_available: 9,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954303,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 305,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954261,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 306,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954329,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 307,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 6,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954437,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 308,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 1
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954305,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 309,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954429,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 310,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954434,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 311,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 0,
      is_renting: 0,
      is_returning: 0,
      last_reported: 1568425426,
      is_charging_station: true,
      status: 'PLANNED'
    },
    {
      station_id: 312,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 34,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954421,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 313,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 1
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954429,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 314,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954482,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 315,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954263,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 316,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954325,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 317,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954494,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 318,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954284,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 319,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 28,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954483,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 320,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 2
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954354,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 321,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954330,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 322,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 3
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954456,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 323,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 1
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954274,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 324,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 2
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954348,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 325,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954266,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 326,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 32,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954468,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 327,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954479,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 328,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954470,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 329,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954489,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 331,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954481,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 332,
      num_bikes_available: 6,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 2
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954331,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 333,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 2
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954259,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 334,
      num_bikes_available: 8,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 5
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954278,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 335,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954475,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 336,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954324,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 337,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954442,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 338,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 5
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954450,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 339,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 1
      },
      num_docks_available: 12,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954277,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 340,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 4,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954273,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 341,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954307,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 342,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 1
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954388,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 343,
      num_bikes_available: 25,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954387,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 344,
      num_bikes_available: 27,
      num_bikes_available_types: {
        mechanical: 27,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954512,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 345,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954394,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 346,
      num_bikes_available: 27,
      num_bikes_available_types: {
        mechanical: 27,
        ebike: 0
      },
      num_docks_available: 9,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954514,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 347,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 9,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954503,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 348,
      num_bikes_available: 32,
      num_bikes_available_types: {
        mechanical: 32,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954498,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 349,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954349,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 350,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954476,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 351,
      num_bikes_available: 31,
      num_bikes_available_types: {
        mechanical: 31,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954431,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 352,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 22,
        ebike: 1
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954429,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 353,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 30,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954333,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 354,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954372,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 356,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954387,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 357,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954311,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 358,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 19,
        ebike: 0
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954469,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 359,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954289,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 360,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 10,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954413,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 361,
      num_bikes_available: 32,
      num_bikes_available_types: {
        mechanical: 32,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954361,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 362,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 1
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954376,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 363,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 12,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954484,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 364,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954468,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 365,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954294,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 367,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 32,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954289,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 368,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954299,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 369,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954280,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 370,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 7,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954357,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 371,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 10,
        ebike: 0
      },
      num_docks_available: 21,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954442,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 372,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954433,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 373,
      num_bikes_available: 10,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 1
      },
      num_docks_available: 20,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954279,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 374,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 31,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954498,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 375,
      num_bikes_available: 19,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 1
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954299,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 376,
      num_bikes_available: 12,
      num_bikes_available_types: {
        mechanical: 12,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954370,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 377,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 2
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954320,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 378,
      num_bikes_available: 18,
      num_bikes_available_types: {
        mechanical: 18,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954390,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 380,
      num_bikes_available: 21,
      num_bikes_available_types: {
        mechanical: 21,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954478,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 381,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954386,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 382,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954416,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 384,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 24,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954442,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 385,
      num_bikes_available: 7,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 2
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954494,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 386,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 10,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954488,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 387,
      num_bikes_available: 9,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 0
      },
      num_docks_available: 17,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954400,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 388,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 1
      },
      num_docks_available: 22,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954300,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 389,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954368,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 390,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 14,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954483,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 391,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954107,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 392,
      num_bikes_available: 13,
      num_bikes_available_types: {
        mechanical: 13,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954397,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 393,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 1
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954358,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 394,
      num_bikes_available: 14,
      num_bikes_available_types: {
        mechanical: 14,
        ebike: 0
      },
      num_docks_available: 11,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954358,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 395,
      num_bikes_available: 30,
      num_bikes_available_types: {
        mechanical: 27,
        ebike: 3
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954323,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 396,
      num_bikes_available: 25,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 0
      },
      num_docks_available: 7,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954267,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 397,
      num_bikes_available: 23,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954425,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 398,
      num_bikes_available: 27,
      num_bikes_available_types: {
        mechanical: 27,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954448,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 400,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 23,
        ebike: 3
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954335,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 401,
      num_bikes_available: 21,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 1
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954366,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 402,
      num_bikes_available: 26,
      num_bikes_available_types: {
        mechanical: 26,
        ebike: 0
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954508,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 404,
      num_bikes_available: 17,
      num_bikes_available_types: {
        mechanical: 17,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954485,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 405,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 25,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954333,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 406,
      num_bikes_available: 28,
      num_bikes_available_types: {
        mechanical: 28,
        ebike: 0
      },
      num_docks_available: 5,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954420,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 408,
      num_bikes_available: 36,
      num_bikes_available_types: {
        mechanical: 36,
        ebike: 0
      },
      num_docks_available: 3,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954268,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 409,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 9,
        ebike: 2
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954320,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 410,
      num_bikes_available: 31,
      num_bikes_available_types: {
        mechanical: 31,
        ebike: 0
      },
      num_docks_available: 0,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954422,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 412,
      num_bikes_available: 25,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 1
      },
      num_docks_available: 1,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954429,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 413,
      num_bikes_available: 16,
      num_bikes_available_types: {
        mechanical: 16,
        ebike: 0
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954336,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 414,
      num_bikes_available: 25,
      num_bikes_available_types: {
        mechanical: 25,
        ebike: 0
      },
      num_docks_available: 6,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954424,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 415,
      num_bikes_available: 2,
      num_bikes_available_types: {
        mechanical: 2,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954428,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 416,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 26,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954293,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 418,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 11,
        ebike: 0
      },
      num_docks_available: 16,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954502,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 419,
      num_bikes_available: 15,
      num_bikes_available_types: {
        mechanical: 15,
        ebike: 0
      },
      num_docks_available: 18,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954343,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 420,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954291,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 421,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 29,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954362,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 423,
      num_bikes_available: 5,
      num_bikes_available_types: {
        mechanical: 5,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954288,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 424,
      num_bikes_available: 24,
      num_bikes_available_types: {
        mechanical: 24,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954458,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 425,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 27,
      is_installed: 0,
      is_renting: 0,
      is_returning: 0,
      last_reported: 1565592081,
      is_charging_station: true,
      status: 'PLANNED'
    },
    {
      station_id: 426,
      num_bikes_available: 4,
      num_bikes_available_types: {
        mechanical: 4,
        ebike: 0
      },
      num_docks_available: 28,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954462,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 427,
      num_bikes_available: 1,
      num_bikes_available_types: {
        mechanical: 1,
        ebike: 0
      },
      num_docks_available: 19,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954261,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 428,
      num_bikes_available: 11,
      num_bikes_available_types: {
        mechanical: 10,
        ebike: 1
      },
      num_docks_available: 15,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954473,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 492,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954338,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 494,
      num_bikes_available: 20,
      num_bikes_available_types: {
        mechanical: 20,
        ebike: 0
      },
      num_docks_available: 2,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954455,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 495,
      num_bikes_available: 0,
      num_bikes_available_types: {
        mechanical: 0,
        ebike: 0
      },
      num_docks_available: 23,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954300,
      is_charging_station: true,
      status: 'IN_SERVICE'
    },
    {
      station_id: 496,
      num_bikes_available: 3,
      num_bikes_available_types: {
        mechanical: 3,
        ebike: 0
      },
      num_docks_available: 12,
      is_installed: 1,
      is_renting: 1,
      is_returning: 1,
      last_reported: 1572954459,
      is_charging_station: true,
      status: 'IN_SERVICE'
    }
  ],
  stations: [
    {
      station_id: 1,
      name: 'C/ GRAN VIA CORTS CATALANES, 760',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.397952,
      lon: 2.180042,
      altitude: 21,
      address: 'C/ GRAN VIA CORTS CATALANES, 760',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 2,
      name: 'C/ ROGER DE FLOR, 126',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39553,
      lon: 2.17706,
      altitude: 21,
      address: 'C/ ROGER DE FLOR, 126',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 3,
      name: 'C/ NÀPOLS, 82',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.394055,
      lon: 2.181299,
      altitude: 22,
      address: 'C/ NÀPOLS, 82',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 4,
      name: 'C/ RIBES, 13',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39348,
      lon: 2.181555,
      altitude: 21,
      address: 'C/ RIBES, 13',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 5,
      name: 'PG. LLUIS COMPANYS, 11 (ARC TRIOMF)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391075,
      lon: 2.180223,
      altitude: 16,
      address: 'PG. LLUIS COMPANYS, 11 (ARC TRIOMF)',
      post_code: '08908',
      capacity: 39,
      nearby_distance: 1000
    },
    {
      station_id: 6,
      name: 'PG. LLUIS COMPANYS, 18  (ARC TRIOMF)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391349,
      lon: 2.18061,
      altitude: 16,
      address: 'PG. LLUIS COMPANYS, 18  (ARC TRIOMF)',
      post_code: '08908',
      capacity: 39,
      nearby_distance: 1000
    },
    {
      station_id: 7,
      name: 'PG. PUJADES,  1 (JUTJATS)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388856,
      lon: 2.183251,
      altitude: 16,
      address: 'PG. PUJADES,  1 (JUTJATS)',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 8,
      name: 'PG. PUJADES, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389088,
      lon: 2.183568,
      altitude: 11,
      address: 'PG. PUJADES, 2',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 9,
      name: "AV. MARQUÉS DE L'ARGENTERA,13",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.384532,
      lon: 2.184921,
      altitude: 11,
      address: "AV. MARQUÉS DE L'ARGENTERA,13",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 11,
      name: 'PG. MARITIM, 11 (DAVANT PL. BRUGADA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381689,
      lon: 2.193914,
      altitude: 1,
      address: 'PG. MARITIM, 11 (DAVANT PL. BRUGADA)',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 12,
      name: 'PG. MARITIM, 23 (HOSPITAL DEL MAR)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.384538,
      lon: 2.195679,
      altitude: 0.1,
      address: 'PG. MARITIM, 23 (HOSPITAL DEL MAR)',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 13,
      name: 'CARRER DE LA MARINA, 25-33',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387985,
      lon: 2.19578,
      altitude: 8,
      address: 'CARRER DE LA MARINA, 25-33',
      post_code: '08908',
      capacity: 54,
      nearby_distance: 1000
    },
    {
      station_id: 14,
      name: "AV. MARQUÉS DE  L'ARGENTERA, 15",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.384675,
      lon: 2.185039,
      altitude: 11,
      address: "AV. MARQUÉS DE  L'ARGENTERA, 15",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 15,
      name: 'C/ GIRONA, 68',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.394812,
      lon: 2.171189,
      altitude: 40,
      address: 'C/ GIRONA, 68',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 17,
      name: 'AV. MERIDIANA, 47 (ANNEXA A LA 16)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398237,
      lon: 2.186711,
      altitude: 18,
      address: 'AV. MERIDIANA, 47 (ANNEXA A LA 16)',
      post_code: '08908',
      capacity: 42,
      nearby_distance: 1000
    },
    {
      station_id: 18,
      name: 'C/ ROSSELLÓ, 453',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.406086,
      lon: 2.174167,
      altitude: 49,
      address: 'C/ ROSSELLÓ, 453',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 19,
      name: 'C/ ROSSELLÓ, 354',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403282,
      lon: 2.170726,
      altitude: 49,
      address: 'C/ ROSSELLÓ, 354',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 20,
      name: 'C/ CARTAGENA, 308',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.410166,
      lon: 2.175759,
      altitude: 59,
      address: 'C/ CARTAGENA, 308',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 21,
      name: 'C/ SANT ANTONI MARIA CLARET, 214',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.410897,
      lon: 2.17402,
      altitude: 59,
      address: 'C/ SANT ANTONI MARIA CLARET, 214',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 22,
      name: 'C/ SARDENYA, 292',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.401684,
      lon: 2.17568,
      altitude: 28,
      address: 'C/ SARDENYA, 292',
      post_code: '08908',
      capacity: 19,
      nearby_distance: 1000
    },
    {
      station_id: 23,
      name: 'C/ BRUC, 45',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.392526,
      lon: 2.171812,
      altitude: 42,
      address: 'C/ BRUC, 45',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 24,
      name: 'C/ MARINA, 199',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.400659,
      lon: 2.179111,
      altitude: 30,
      address: 'C/ MARINA, 199',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 25,
      name: 'C/ BRUC, 102',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395179,
      lon: 2.168294,
      altitude: 42,
      address: 'C/ BRUC, 102',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 26,
      name: 'C/ DOS DE MAIG, 230-232',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.407035,
      lon: 2.181981,
      altitude: 28,
      address: 'C/ DOS DE MAIG, 230-232',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 27,
      name: 'C/ PROVENÇA, 322',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396897,
      lon: 2.164373,
      altitude: 49,
      address: 'C/ PROVENÇA, 322',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 28,
      name: 'C/ SARDENYA, 362',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.405422,
      lon: 2.17064,
      altitude: 45,
      address: 'C/ SARDENYA, 362',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 29,
      name: 'C/ PROVENÇA, 388-390',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.401132,
      lon: 2.170028,
      altitude: 50,
      address: 'C/ PROVENÇA, 388-390',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 30,
      name: 'AV. DIAGONAL, 231 AMB PADILLA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402046,
      lon: 2.182286,
      altitude: 28,
      address: 'AV. DIAGONAL, 231 AMB PADILLA',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 31,
      name: 'PL. DEL MAR',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37481,
      lon: 2.18895,
      altitude: 1,
      address: 'PL. DEL MAR',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 32,
      name: 'LA BARCELONETA (CN BARCELONETA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.373698,
      lon: 2.188927,
      altitude: 1,
      address: 'LA BARCELONETA (CN BARCELONETA)',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 33,
      name: 'C/ PONTEVEDRA / JUDICI',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.376862,
      lon: 2.190773,
      altitude: 1,
      address: 'C/ PONTEVEDRA / JUDICI',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 34,
      name: 'C/ SANT PERE MÉS ALT, 4',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387074,
      lon: 2.175247,
      altitude: 16,
      address: 'C/ SANT PERE MÉS ALT, 4',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 35,
      name: 'C/ SANT RAMON DE PENYAFORT, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.413592,
      lon: 2.221153,
      altitude: 1,
      address: 'C/ SANT RAMON DE PENYAFORT, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 36,
      name: 'AV. DE LA CATEDRAL, 6',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385151,
      lon: 2.176804,
      altitude: 16,
      address: 'AV. DE LA CATEDRAL, 6',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 37,
      name: 'PL. ANTONIO LÓPEZ (CORREUS, VIA LAIETANA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381226,
      lon: 2.181888,
      altitude: 5,
      address: 'PL. ANTONIO LÓPEZ (CORREUS, VIA LAIETANA)',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 39,
      name: 'PL.PAU VILA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381046,
      lon: 2.186576,
      altitude: 1,
      address: 'PL. PAU VILA',
      post_code: '08908',
      capacity: 44,
      nearby_distance: 1000
    },
    {
      station_id: 40,
      name: 'C/ DOCTOR AIGUADER, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.382335,
      lon: 2.187093,
      altitude: 1,
      address: 'C/ DOCTOR AIGUADER, 2',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 41,
      name: 'PL. POETA BOSCÀ/ATLÀNTIDA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.379326,
      lon: 2.189906,
      altitude: 1,
      address: 'PL. POETA BOSCÀ/ATLÀNTIDA',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 42,
      name: 'C/ CIUTAT DE GRANADA, 168 / AV. DIAGONAL',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.404511,
      lon: 2.189881,
      altitude: 18,
      address: 'C/ CIUTAT DE GRANADA, 168 / AV. DIAGONAL',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 44,
      name: 'AV. MERIDIANA, 66 ( COSTAT METRO GLORIES',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402285,
      lon: 2.187111,
      altitude: 18,
      address: 'AV. MERIDIANA, 66 ( COSTAT METRO GLORIES)',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 45,
      name: 'C/ SARDENYA, 66',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391429,
      lon: 2.189312,
      altitude: 18,
      address: 'C/ SARDENYA, 66',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 46,
      name: 'C/ RAMON TRIAS FARGAS, 19',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388359,
      lon: 2.193004,
      altitude: 1,
      address: 'C/ RAMON TRIAS FARGAS, 19',
      post_code: '08908',
      capacity: 54,
      nearby_distance: 1000
    },
    {
      station_id: 47,
      name: 'C/ RAMON TRIAS FARGAS, 21',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389831,
      lon: 2.191147,
      altitude: 1,
      address: 'C/ RAMON TRIAS FARGAS, 21',
      post_code: '08908',
      capacity: 47,
      nearby_distance: 1000
    },
    {
      station_id: 48,
      name: 'AV. MERIDIANA, 40',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396085,
      lon: 2.187073,
      altitude: 18,
      address: 'AV. MERIDIANA, 40',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 49,
      name: 'C/ ROSA SENSAT, 20',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39106,
      lon: 2.196457,
      altitude: 1,
      address: 'C/ ROSA SENSAT, 20',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 50,
      name: 'AV. PARAL.LEL, 54',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375,
      lon: 2.17035,
      altitude: 1,
      address: 'AV. PARAL.LEL, 54',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 51,
      name: 'PL. VICENÇ MARTORELL',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.384054,
      lon: 2.169019,
      altitude: 32,
      address: 'PL. VICENÇ MARTORELL',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 53,
      name: 'PL. CARLES PI I SUNYER',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385227,
      lon: 2.173878,
      altitude: 32,
      address: 'PL. CARLES PI I SUNYER',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 54,
      name: 'C/ SANT OLEGUER, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.377635,
      lon: 2.170586,
      altitude: 24,
      address: 'C/ SANT OLEGUER, 2',
      post_code: '08908',
      capacity: 19,
      nearby_distance: 1000
    },
    {
      station_id: 55,
      name: 'LA RAMBLA, 80',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381154,
      lon: 2.173497,
      altitude: 24,
      address: 'LA RAMBLA, 80',
      post_code: '08908',
      capacity: 19,
      nearby_distance: 1000
    },
    {
      station_id: 56,
      name: 'PORTAL DE SANTA MADRONA, 2-4',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.377011,
      lon: 2.175834,
      altitude: 5,
      address: 'PORTAL DE SANTA MADRONA, 2-4',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 57,
      name: 'RAMBLA, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.376758,
      lon: 2.177093,
      altitude: 5,
      address: 'RAMBLA, 2',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 58,
      name: 'PLAÇA DELS ÀNGELS ( MACBA )',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.382822,
      lon: 2.167154,
      altitude: 24,
      address: 'PLAÇA DELS ÀNGELS ( MACBA )',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 59,
      name: 'PLAÇA DELS ÀNGELS (MACBA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.382781,
      lon: 2.167203,
      altitude: 24,
      address: '59 - PLAÇA DELS ÀNGELS ( MACBA )',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 60,
      name: 'RAMBLA CATALUNYA, 47',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390349,
      lon: 2.16424,
      altitude: 41,
      address: 'RAMBLA CATALUNYA, 47',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 61,
      name: 'RAMBLA CATALUNYA, 42',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390143,
      lon: 2.164946,
      altitude: 41,
      address: 'RAMBLA CATALUNYA, 42',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 62,
      name: 'PL. CATALUNYA, 5',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387142,
      lon: 2.168858,
      altitude: 32,
      address: 'PL. CATALUNYA, 5',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 63,
      name: 'PL. CATALUNYA, 7',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38645,
      lon: 2.169463,
      altitude: 32,
      address: 'PL. CATALUNYA, 7',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 64,
      name: 'PL. CATALUNYA, 10-11 (RAMBLA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387469,
      lon: 2.169048,
      altitude: 32,
      address: 'PL. CATALUNYA, 10-11 (RAMBLA)',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 65,
      name: 'PL. CATALUNYA, 10-11 (PG. DE GRACIA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387678,
      lon: 2.169587,
      altitude: 32,
      address: 'PL. CATALUNYA, 10-11 (PG. DE GRACIA)',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 66,
      name: 'GRAN VIA DE LES CORTS CATALANES, 609',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389097,
      lon: 2.167933,
      altitude: 32,
      address: 'GRAN VIA DE LES CORTS CATALANES, 609',
      post_code: '08908',
      capacity: 15,
      nearby_distance: 1000
    },
    {
      station_id: 67,
      name: 'C/ ROCAFORT, 214 / ROSSELLÓ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385012,
      lon: 2.145843,
      altitude: 51,
      address: 'C/ ROCAFORT, 214 / ROSSELLÓ',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 68,
      name: 'RAMBLA CATALUNYA, 133 /  CÒRSEGA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395364,
      lon: 2.157356,
      altitude: 59,
      address: 'RAMBLA CATALUNYA, 133 /  CÒRSEGA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 69,
      name: 'CARRER DE LA MARINA,25-33',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38797,
      lon: 2.195801,
      altitude: 8,
      address: 'CARRER DE LA MARINA,25-33',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 70,
      name: 'C/ COMTE URGELL, 23',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.380347,
      lon: 2.160695,
      altitude: 35,
      address: 'C/ COMTE URGELL, 23',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 71,
      name: 'C/ FLORIDABLANCA, 145',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381931,
      lon: 2.162969,
      altitude: 35,
      address: 'C/ FLORIDABLANCA, 145',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 72,
      name: 'C/ PROVENÇA, 215',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.392716,
      lon: 2.158811,
      altitude: 55,
      address: 'C/ PROVENÇA, 215',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 73,
      name: 'C/ ENRIC GRANADOS, 93',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.392278,
      lon: 2.156183,
      altitude: 59,
      address: 'C/ ENRIC GRANADOS, 93',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 74,
      name: 'AV. JOSEP TARRADELLAS, 133',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390062,
      lon: 2.143272,
      altitude: 51,
      address: 'AV. JOSEP TARRADELLAS, 133',
      post_code: '08908',
      capacity: 15,
      nearby_distance: 1000
    },
    {
      station_id: 75,
      name: 'AV. JOSEP TARRADELLAS, 58',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385081,
      lon: 2.142868,
      altitude: 51,
      address: 'AV. JOSEP TARRADELLAS, 58',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 76,
      name: 'C/ CÒRSEGA, 216',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391751,
      lon: 2.153123,
      altitude: 45,
      address: 'C/ CÒRSEGA, 216',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 78,
      name: 'PL. UNIVERSITAT /  ARIBAU',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3856691,
      lon: 2.1634139,
      altitude: 41,
      address: 'PL. UNIVERSITAT /  ARIBAU',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 79,
      name: 'PL.  UNIVERSITAT',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3854435,
      lon: 2.1634297,
      altitude: 41,
      address: 'PL. UNIVERSITAT',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 80,
      name: 'C/ ENRIC  GRANADOS, 35',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390008,
      lon: 2.1599551,
      altitude: 41,
      address: 'C/ ENRIC  GRANADOS, 35',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 81,
      name: 'C/ VILAMARÍ,  61',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3790058,
      lon: 2.1490856,
      altitude: 44,
      address: 'C/ VILAMARÍ,  61',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 82,
      name: 'C/ ROCAFORT, 72-74',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3787972,
      lon: 2.1533895,
      altitude: 40,
      address: 'C/ ROCAFORT, 72-74',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 83,
      name: 'C/ COMTE BORRELL, 177',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3840885,
      lon: 2.1537733,
      altitude: 45,
      address: 'C/ COMTE BORRELL, 177',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 84,
      name: "C/ DEL COMTE D'URGELL, 75 B",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3831216,
      lon: 2.1572314,
      altitude: 35,
      address: "C/ DEL COMTE D'URGELL, 75 B",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 85,
      name: 'AV. PARAL·LEL, 146 BIS',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3750672,
      lon: 2.159005,
      altitude: 35,
      address: 'AV. PARAL·LEL, 146 BIS',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 86,
      name: 'C/ VILADOMAT,  2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3752683,
      lon: 2.1630981,
      altitude: 35,
      address: 'C/ VILADOMAT,  2',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 87,
      name: 'C/ MALLORCA, 41-43',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3832053,
      lon: 2.1486715,
      altitude: 44,
      address: 'C/ MALLORCA, 41-43',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 88,
      name: 'C/ LONDRES, 101-103',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3935986,
      lon: 2.1506554,
      altitude: 59,
      address: 'C/ LONDRES, 101-103',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 89,
      name: 'C/ ROSSELLÓ,  101',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388154,
      lon: 2.1503428,
      altitude: 45,
      address: 'C/ ROSSELLÓ,  101',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 90,
      name: 'C/ ROSSELLÓ, 108-110',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3884249,
      lon: 2.1507832,
      altitude: 45,
      address: 'C/ ROSSELLÓ, 108-110',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 92,
      name: 'C / PROVENÇA, 241',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3937852,
      lon: 2.1602704,
      altitude: 50,
      address: 'C / PROVENÇA, 241',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 93,
      name: 'GRAN VIA DE LES CORTS CATALANES, 375',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3755595,
      lon: 2.1499725,
      altitude: 40,
      address: 'GRAN VIA DE LES CORTS CATALANES, 375-385',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 94,
      name: 'GRAN VIA DE LES CORTS CATALANES,375',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3755704,
      lon: 2.1500681,
      altitude: 40,
      address: 'GRAN  VIA DE LES CORTS CATALANES, 375-385',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 95,
      name: 'C/ TARRAGONA, 103-115',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3766488,
      lon: 2.1476883,
      altitude: 44,
      address: 'C/ TARRAGONA, 103-115',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 96,
      name: 'GRAN VIA DE LES CORTS CATALANES,  361',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3742316,
      lon: 2.1483861,
      altitude: 61,
      address: 'GRAN VIA DE LES CORTS CATALANES, 361',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 97,
      name: 'C/ TARRAGONA, 141',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3782369,
      lon: 2.145503,
      altitude: 44,
      address: 'C/ TARRAGONA, 141',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 98,
      name: 'C/ VIRIAT, 43',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3803803,
      lon: 2.1409726,
      altitude: 44,
      address: 'C/ VIRIAT, 43',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 99,
      name: 'C/ VIRIAT,  53',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.380632,
      lon: 2.141548,
      altitude: 44,
      address: 'C/ VIRIAT,  53',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 100,
      name: 'C/ TARRAGONA,  159-173',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3791661,
      lon: 2.1440132,
      altitude: 44,
      address: 'C/ TARRAGONA,  159-173',
      post_code: '08908',
      capacity: 22,
      nearby_distance: 1000
    },
    {
      station_id: 101,
      name: 'AV. DIAGONAL,  602',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3927878,
      lon: 2.1434059,
      altitude: 69,
      address: 'AV. DIAGONAL,  602',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 102,
      name: 'AV. DIAGONAL,  612',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3922519,
      lon: 2.1420435,
      altitude: 69,
      address: 'AV. DIAGONAL,  612',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 104,
      name: 'C/ VALÈNCIA,  621',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4108567,
      lon: 2.1872941,
      altitude: 17,
      address: 'C/ VALÈNCIA, 621',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 105,
      name: 'PL. URQUINAONA, 9-10',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389197,
      lon: 2.173555,
      altitude: 32,
      address: 'PL. URQUINAONA, 9-10',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 106,
      name: 'PL  JOANIC - C / BRUNIQUER, 59',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4056,
      lon: 2.1623,
      altitude: 59,
      address: 'PL  JOANIC - C / BRUNIQUER, 59',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 107,
      name: 'TRAV. DE GRÀCIA, 92 / VIA AUGUSTA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3985611,
      lon: 2.1530513,
      altitude: 59,
      address: 'TRAV. DE GRÀCIA, 92 / VIA AUGUSTA',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 108,
      name: 'C / INDÚSTRIA, 10',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4024328,
      lon: 2.1648503,
      altitude: 59,
      address: 'C / INDÚSTRIA, 10',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 109,
      name: 'C/ LONDRES,  53',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390936,
      lon: 2.1474257,
      altitude: 51,
      address: 'C/ LONDRES,  53',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 110,
      name: 'AV. ROMA,  136',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3857053,
      lon: 2.1547191,
      altitude: 43,
      address: 'AV. ROMA,  136',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 111,
      name: 'C/ CALÀBRIA,  137',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3813141,
      lon: 2.1527471,
      altitude: 41,
      address: 'C/ CALÀBRIA, 137',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 112,
      name: 'C/ FLORIDABLANCA,  49',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3776061,
      lon: 2.1572895,
      altitude: 40,
      address: 'C/ FLORIDABLANCA, 49',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 113,
      name: 'RONDA DE SANT PAU , 51',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3774096,
      lon: 2.1648261,
      altitude: 30,
      address: 'RONDA DE SANT PAU, 51',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 114,
      name: 'PL. JEAN GENET,  1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3768334,
      lon: 2.1737389,
      altitude: 24,
      address: 'PL. JEAN GENET,  1',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 115,
      name: "AV. MARQUÉS DE L'ARGENTERA,  3",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3837312,
      lon: 2.1841477,
      altitude: 1,
      address: "AV. MARQUÉS DE L'ARGENTERA,  3",
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 116,
      name: 'C/ DEL DR. AIGUADER, 72 / PG. SALVAT',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3839396,
      lon: 2.1916731,
      altitude: 11,
      address: 'C/ DEL DR. AIGUADER, 72 / PG. SALVAT PAPASSEIT',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 117,
      name: 'C/ ROSA SENSAT, 12',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3907885,
      lon: 2.1969509,
      altitude: 1,
      address: 'C/ ROSA SENSAT, 12',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 118,
      name: 'C/ PUJADES,  3',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3919909,
      lon: 2.1879553,
      altitude: 18,
      address: 'C/ PUJADES,  3',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 119,
      name: 'C/ SARDENYA,  178',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3966226,
      lon: 2.1823944,
      altitude: 21,
      address: 'C/ SARDENYA,  178',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 120,
      name: 'C/ LEPANT,  278',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4045867,
      lon: 2.1765086,
      altitude: 28,
      address: 'C/ LEPANT,  278',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 121,
      name: 'C/ CASTILLEJOS, 258',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4061885,
      lon: 2.1785208,
      altitude: 28,
      address: 'C/ CASTILLEJOS, 258',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 122,
      name: 'C/ NÀPOLS, 344',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4053379,
      lon: 2.1660973,
      altitude: 59,
      address: 'C/ NÀPOLS, 344',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 123,
      name: 'C/ GIRONA, 142',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398114,
      lon: 2.166828,
      altitude: 45,
      address: 'C/ GIRONA, 142',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 124,
      name: 'C/ NOVA  BOCANA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3702482637018,
      lon: 2.1878125984221697,
      altitude: 5,
      address: 'C/ NOVA  BOCANA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 125,
      name: 'PG. MARíTIM, 31 B (ANNEXA A LA  12)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3844693,
      lon: 2.1955147,
      altitude: 1,
      address: 'PG. MARíTIM, 31 B (ANNEXA A LA  12)',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 126,
      name: 'PG. DE COLOM /VIA LAIETANA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3806785,
      lon: 2.1817748,
      altitude: 5,
      address: 'PG. DE COLOM /VIA LAIETANA',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 127,
      name: 'C/ ARAGÓ, 659 / NAVAS DE TOLOSA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4118718,
      lon: 2.1911494,
      altitude: 17,
      address: 'C/ ARAGÓ, 659 /NAVAS DE TOLOSA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 128,
      name: 'RAMBLA DE GUIPÚSCOA, 43/FLUVIÀ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4154133,
      lon: 2.1964181,
      altitude: 13,
      address: 'RAMBLA DE GUIPÚSCOA, 43/FLUVIÀ',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 129,
      name: 'C/ MANSO, 46',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3770515,
      lon: 2.1611378,
      altitude: 35,
      address: 'C/ MANSO, 46',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 130,
      name: 'RAMBLA DE GUIPÚSCOA, 103 / CANTÀBRIA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4198281,
      lon: 2.201574,
      altitude: 9,
      address: 'RAMBLA DE GUIPÚSCOA, 103 /CANTÀBRIA',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 131,
      name: "RAMBLA  DE GUIPÚSCOA, 158/ CA N'OLIVA",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.422785,
      lon: 2.20594,
      altitude: 9,
      address: "RAMBLA  DE GUIPÚSCOA, 158/ CA N'OLIVA",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 132,
      name: 'PL. VALENTÍ ALMIRALL',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4084554,
      lon: 2.1919802,
      altitude: 13,
      address: 'PL.VALENTÍ ALMIRALL',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 133,
      name: 'GRAN VIA DE LES CORTS CATALANES,  902',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4076602,
      lon: 2.1924881,
      altitude: 16,
      address: 'GRAN VIA DE LES CORTS CATALANES, 902',
      post_code: '08908',
      capacity: 16,
      nearby_distance: 1000
    },
    {
      station_id: 134,
      name: 'C/ BAC DE RODA, 157',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4115934,
      lon: 2.1987619,
      altitude: 10,
      address: 'C/ BAC DE RODA, 157',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 135,
      name: 'GRAN VIA DE LES CORTS CATALANES,  981',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4121316,
      lon: 2.1977579,
      altitude: 13,
      address: 'GRAN VIA DE LES CORTS CATALANES,  981',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 136,
      name: 'GRAN VIA DE LES CORTS CATALANES,  1062',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4141813,
      lon: 2.2015894,
      altitude: 9,
      address: 'GRAN VIA DE LES CORTS CATALANES,  1062',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 137,
      name: 'GRAN VIA DE LES CORTS CATALANES , 1041',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4144656,
      lon: 2.2014244,
      altitude: 9,
      address: 'GRAN VIA DE LES CORTS CATALANES, 1041',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 138,
      name: 'GRAN VIA DE LES CORTS CATALANES,  1118',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.416832,
      lon: 2.2053065,
      altitude: 9,
      address: 'GRAN VIA DE LES CORTS CATALANES, 1118',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 139,
      name: 'GRAN VIA DE LES CORTS CATALANES,  1131',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4179241,
      lon: 2.2061937,
      altitude: 9,
      address: 'GRAN VIA DE LES CORTS CATALANES,  1131',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 140,
      name: 'C/ VILADOMAT,  122',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3806648,
      lon: 2.15625,
      altitude: 41,
      address: 'C/ VILADOMAT, 122',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 141,
      name: 'GRAN VIA DE LES CORTS CATALANES ,  940',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4090158,
      lon: 2.1946377,
      altitude: 13,
      address: 'GRAN VIA DE LES CORTS CATALANES ,  940',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 142,
      name: 'C/ SANCHO DE ÁVILA,  104 - C/ BADAJOZ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4005692,
      lon: 2.1923425,
      altitude: 16,
      address: 'C/ SANCHO DE ÁVILA,  104 - C/ BADAJOZ',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 143,
      name: 'C/ SANCHO DE ÁVILA, 170 / LLACUNA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4030725,
      lon: 2.1959541,
      altitude: 16,
      address: 'C/ SANCHO DE ÁVILA, 170 / LLACUNA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 144,
      name: 'C/ CASTELLA,  28 / DIAGONAL',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4057871,
      lon: 2.1975542,
      altitude: 16,
      address: 'C/ CASTELLA,  28 / DIAGONAL',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 145,
      name: 'C/ PERE IV,  301 / FLUVIÀ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4099066,
      lon: 2.2028356,
      altitude: 9,
      address: 'C/ PERE IV,  301 / FLUVIÀ',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 146,
      name: 'C/ PERE IV, 488',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.415144,
      lon: 2.207206,
      altitude: 9,
      address: 'C/ PERE IV, 488',
      post_code: '08908',
      capacity: 15,
      nearby_distance: 1000
    },
    {
      station_id: 147,
      name: 'RAMBLA PRIM, 79',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.416018,
      lon: 2.212658,
      altitude: 5,
      address: 'RAMBLA PRIM, 79',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 148,
      name: 'RONDA SANT PAU, 79',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.378486,
      lon: 2.163404,
      altitude: 12,
      address: 'RONDA SANT PAU, 79',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 149,
      name: 'C/ PUJADES, 57B',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395905,
      lon: 2.192958,
      altitude: 11,
      address: '149 - C/ PUJADES, 57B',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 150,
      name: 'C/ ESPRONCEDA, 124',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.406549,
      lon: 2.203112,
      altitude: 10,
      address: 'C/ ESPRONCEDA, 124',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 151,
      name: 'C/ PALLARS, 182',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40065,
      lon: 2.19719,
      altitude: 6,
      address: 'C/ PALLARS, 182',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 152,
      name: 'C/ PUJADES, 121',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39925,
      lon: 2.197421,
      altitude: 11,
      address: 'C/ PUJADES, 121',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 153,
      name: 'C/ PUJADES, 173/RAMBLA DEL POBLE NOU',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.401778,
      lon: 2.200769,
      altitude: 10,
      address: 'C/ PUJADES, 173/RAMBLA DEL POBLE NOU',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 154,
      name: 'C/ PUJADES, 191',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402454,
      lon: 2.201656,
      altitude: 10,
      address: 'C/ PUJADES, 191',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 155,
      name: 'C/ PUJADES, 311/ FLUVIÀ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.406977,
      lon: 2.207602,
      altitude: 10,
      address: 'C/ PUJADES, 311/ FLUVIÀ',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 156,
      name: 'C/ SELVA DE MAR, 46',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.409017,
      lon: 2.20881,
      altitude: 5,
      address: 'C/ SELVA DE MAR, 46',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 157,
      name: 'C/ LLULL, 465',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.412942,
      lon: 2.217994,
      altitude: 0.1,
      address: 'C/ LLULL, 465',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 158,
      name: 'RAMBLA DE PRIM, 19',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.411741,
      lon: 2.218546,
      altitude: 1,
      address: 'RAMBLA DE PRIM, 19',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 159,
      name: 'AV. DIAGONAL, 26',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.410882,
      lon: 2.216703,
      altitude: 5,
      address: 'AV. DIAGONAL, 26',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 160,
      name: "AV. D'EDUARD MARISTANY, 1 /FORUM",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.411026,
      lon: 2.219377,
      altitude: 1,
      address: "AV. D'EDUARD MARISTANY, 1 /FORUM",
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 161,
      name: 'C/ RAMON TURRÓ, 91',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395009,
      lon: 2.196308,
      altitude: 11,
      address: 'C/ RAMON TURRÓ, 91',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 162,
      name: 'C/ RAMON TURRÓ, 292',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403908,
      lon: 2.208436,
      altitude: 0.1,
      address: 'C/ RAMON TURRÓ, 292',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 163,
      name: 'AV. ICÀRIA, 202',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3942,
      lon: 2.20077,
      altitude: 11,
      address: 'AV. ICÀRIA, 202',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 164,
      name: 'C/ INDEPENDÈNCIA, 379',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.411901,
      lon: 2.177895,
      altitude: 33,
      address: 'C/ INDEPENDÈNCIA, 379',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 165,
      name: 'C/ DEL DOCTOR TRUETA, 222',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.399196,
      lon: 2.204253,
      altitude: 0.1,
      address: 'C/ DEL DOCTOR TRUETA, 222',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 166,
      name: 'C/ BILBAO, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.400533,
      lon: 2.206539,
      altitude: 0,
      address: 'C/ BILBAO, 2',
      post_code: '08005',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 167,
      name: 'C/ BAC DE RODA, 11',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402561,
      lon: 2.210538,
      altitude: 0.1,
      address: 'C/ BAC DE RODA, 11',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 168,
      name: 'SELVA DE MAR / PG. DEL TAULAT',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.405358,
      lon: 2.213677,
      altitude: 0.1,
      address: 'SELVA DE MAR / PG. DEL TAULAT',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 170,
      name: 'AV.LITORAL, 40 (ANNEXA A LA 169)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389732,
      lon: 2.199934,
      altitude: 1,
      address: 'AV.LITORAL, 40 (ANNEXA A LA 169)',
      post_code: '08908',
      capacity: 54,
      nearby_distance: 1000
    },
    {
      station_id: 171,
      name: 'PG. MARÍTIM DE LA NOVA ICÀRIA, 83',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391986,
      lon: 2.203699,
      altitude: 0.1,
      address: 'PG. MARÍTIM DE LA NOVA ICÀRIA, 83',
      post_code: '08908',
      capacity: 54,
      nearby_distance: 1000
    },
    {
      station_id: 173,
      name: 'AV.LITORAL, 84',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398027,
      lon: 2.208982,
      altitude: 0.1,
      address: 'AV.LITORAL, 84',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 174,
      name: 'PG. DE GARCIA FÀRIA, 21/ESPRONCEDA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40061,
      lon: 2.210461,
      altitude: 0.1,
      address: 'PG. DE GARCIA FÀRIA, 21/ESPRONCEDA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 175,
      name: 'C/ LLULL, 309',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40666,
      lon: 2.20956,
      altitude: 10,
      address: 'C/ LLULL, 309',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 176,
      name: 'PG. DE GARCIA FÀRIA, 37/JOSEP FERRATER',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402541,
      lon: 2.212641,
      altitude: 0.1,
      address: 'PG. DE GARCIA FÀRIA, 37/JOSEP FERRATER',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 177,
      name: 'C/ ROSSELLÓ, 557',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.411089,
      lon: 2.181119,
      altitude: 0.1,
      address: 'C/ ROSSELLÓ, 557',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 178,
      name: 'PG. DE GARCIA FÀRIA, 85',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.405389,
      lon: 2.216212,
      altitude: 0.1,
      address: 'PG. DE GARCIA FÀRIA, 85',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 179,
      name: 'PG. ZONA FRANCA, 244',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.363511,
      lon: 2.13677,
      altitude: 48,
      address: 'PG. ZONA FRANCA, 244',
      post_code: '08908',
      capacity: 15,
      nearby_distance: 1000
    },
    {
      station_id: 180,
      name: 'GRAN VIA DE LES CORTS CATALANES/MOSSÈN',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.36778,
      lon: 2.13926,
      altitude: 50,
      address: 'GRAN VIA DE LES CORTS CATALANES/MOSSÈN AMADEU OLLER',
      post_code: '08908',
      capacity: 51,
      nearby_distance: 1000
    },
    {
      station_id: 182,
      name: 'GRAN VIA DE LES CORTS CATALANES, 273',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.371455,
      lon: 2.143882,
      altitude: 61,
      address: 'GRAN VIA DE LES CORTS CATALANES, 273',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 183,
      name: 'C/ GAVÀ, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.372338,
      lon: 2.141875,
      altitude: 61,
      address: 'C/ GAVÀ, 1',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 184,
      name: 'C/ QUETZAL, 22-24',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.367504,
      lon: 2.134088,
      altitude: 50,
      address: 'C/ QUETZAL, 22-24',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 185,
      name: 'C/GAVÀ, 81',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37046,
      lon: 2.138994,
      altitude: 50,
      address: 'C/GAVÀ, 81',
      post_code: '08908',
      capacity: 17,
      nearby_distance: 1000
    },
    {
      station_id: 186,
      name: 'C/CONSELL DE CENT, 6',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375571,
      lon: 2.143812,
      altitude: 0,
      address: 'C/CONSELL DE CENT, 6',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 187,
      name: 'C/ DE SANT PAU, 89 - 97',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.376858,
      lon: 2.169811,
      altitude: 24,
      address: 'C/ DE SANT PAU, 89 - 97',
      post_code: '08908',
      capacity: 19,
      nearby_distance: 1000
    },
    {
      station_id: 188,
      name: 'PG. SANT ANTONI /PL. SANTS',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375802,
      lon: 2.135973,
      altitude: 45,
      address: 'PG. SANT ANTONI /PL. SANTS',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 189,
      name: 'C/ BRUC, 130',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396918,
      lon: 2.166163,
      altitude: 50,
      address: 'C/ BRUC, 130',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 190,
      name: 'AV. LITORAL, 72',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39606,
      lon: 2.207803,
      altitude: 0.1,
      address: 'AV. LITORAL, 72',
      post_code: '08908',
      capacity: 29,
      nearby_distance: 1000
    },
    {
      station_id: 191,
      name: 'C/ ROCAFORT, 167',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.382564,
      lon: 2.149094,
      altitude: 44,
      address: 'C/ ROCAFORT, 167',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 192,
      name: 'C/ JOAN GÜELL, 50',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.378629,
      lon: 2.133467,
      altitude: 40,
      address: 'C/ JOAN GÜELL, 50',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 193,
      name: 'C/ FÍGOLS, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381311,
      lon: 2.128912,
      altitude: 46,
      address: 'C/ FÍGOLS, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 194,
      name: 'C/ JOAN GÜELL, 98',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381013,
      lon: 2.132319,
      altitude: 40,
      address: 'C/ JOAN GÜELL, 98',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 195,
      name: 'C/ VALLESPIR, 130',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381888,
      lon: 2.135433,
      altitude: 45,
      address: 'C/ VALLESPIR, 130',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 196,
      name: 'C/ BERLÍN, 38',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.383241,
      lon: 2.139363,
      altitude: 30,
      address: 'C/ BERLÍN, 38',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 197,
      name: 'C/ GELABERT, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38712,
      lon: 2.141039,
      altitude: 57,
      address: 'C/ GELABERT, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 198,
      name: 'C/ VALLESPIR, 194',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38451,
      lon: 2.133617,
      altitude: 58,
      address: 'C/ VALLESPIR, 194',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 199,
      name: 'C/ MEJÍA LEQUERICA, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38182,
      lon: 2.126933,
      altitude: 46,
      address: 'C/ MEJÍA LEQUERICA, 2',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 200,
      name: 'C/ CAN BRUIXA, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.383756,
      lon: 2.131273,
      altitude: 60,
      address: 'C/ CAN BRUIXA, 1',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 201,
      name: 'C/ NUMÀNCIA, 136',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387787,
      lon: 2.134341,
      altitude: 58,
      address: 'C/ NUMÀNCIA, 136',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 202,
      name: 'C/ DE LES CORTS, 20',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385331,
      lon: 2.128737,
      altitude: 60,
      address: 'C/ DE LES CORTS, 20',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 203,
      name: 'AV. DIAGONAL, 668',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388742,
      lon: 2.128503,
      altitude: 60,
      address: 'AV. DIAGONAL, 668',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 204,
      name: 'AV. DIAGONAL, 664',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388011,
      lon: 2.125593,
      altitude: 60,
      address: 'AV. DIAGONAL, 664',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 205,
      name: 'C/ EUROPA, 25',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387465,
      lon: 2.130838,
      altitude: 60,
      address: 'C/ EUROPA, 25',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 206,
      name: 'AV. DIAGONAL, 650',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389845,
      lon: 2.13284,
      altitude: 60,
      address: 'AV. DIAGONAL, 650',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 207,
      name: 'AV. DIAGONAL, 634',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390828,
      lon: 2.136665,
      altitude: 57,
      address: 'AV. DIAGONAL, 634',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 208,
      name: 'AV. DIAGONAL, 630',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391451,
      lon: 2.139116,
      altitude: 57,
      address: 'AV. DIAGONAL, 630',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 209,
      name: 'C/ DIPUTACIÓ, 200',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385755,
      lon: 2.161004,
      altitude: 41,
      address: 'C/ DIPUTACIÓ, 200',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 210,
      name: 'C/ VILARDELL, 18',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37453,
      lon: 2.142356,
      altitude: 50,
      address: 'C/ VILARDELL, 18',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 211,
      name: 'C/ SANCHO DE ÁVILA, 60-64',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398772,
      lon: 2.190167,
      altitude: 18,
      address: 'C/ SANCHO DE ÁVILA, 60-64',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 212,
      name: 'AV. SARRIÀ, 163',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39239,
      lon: 2.130945,
      altitude: 82,
      address: 'AV. SARRIÀ, 163',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 213,
      name: 'C/ SANTA FE DE NOU MÈXIC, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.393783,
      lon: 2.135078,
      altitude: 79,
      address: 'C/ SANTA FE DE NOU MÈXIC, 2',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 214,
      name: 'C/ JOSÉ DE  AGULLÓ, 9',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395251,
      lon: 2.133541,
      altitude: 85,
      address: 'C/ JOSÉ DE  AGULLÓ, 9',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 215,
      name: 'C/ GANDUXER, 29',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39412,
      lon: 2.138146,
      altitude: 79,
      address: 'C/ GANDUXER, 29',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 216,
      name: 'C/ MADRAZO, 131',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396809,
      lon: 2.144534,
      altitude: 69,
      address: 'C/ MADRAZO, 131',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 217,
      name: 'C/ RECTOR UBACH, 24',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398463,
      lon: 2.143924,
      altitude: 69,
      address: 'C/ RECTOR UBACH, 24',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 218,
      name: 'C/ CONSELL DE CENT, 566',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.404114,
      lon: 2.183203,
      altitude: 28,
      address: 'C/ CONSELL DE CENT, 566',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 219,
      name: 'C/ LAFORJA, 74-76',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.397698,
      lon: 2.147358,
      altitude: 69,
      address: 'C/ LAFORJA, 74-76',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 220,
      name: 'C/ TUSET, 19',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396229,
      lon: 2.151482,
      altitude: 59,
      address: 'C/ TUSET, 19',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 221,
      name: 'GRAN DE GRÀCIA, 155 (METRO FONTANA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402535,
      lon: 2.152519,
      altitude: 71,
      address: 'GRAN DE GRÀCIA, 155 (METRO FONTANA)',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 222,
      name: 'C/ DEL CANÓ, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40124,
      lon: 2.157483,
      altitude: 71,
      address: 'C/ DEL CANÓ, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 223,
      name: 'C/ DE BONAVISTA, 14',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398311,
      lon: 2.159865,
      altitude: 50,
      address: 'C/ DE BONAVISTA, 14',
      post_code: '08908',
      capacity: 36,
      nearby_distance: 1000
    },
    {
      station_id: 224,
      name: 'C / GIRONA, 176',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.399945,
      lon: 2.164389,
      altitude: 55,
      address: 'C / GIRONA, 176',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 225,
      name: 'C/ MALLORCA, 84',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.384956,
      lon: 2.150814,
      altitude: 44,
      address: 'C/ MALLORCA, 84',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 226,
      name: 'C/ MONTMANY, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403465,
      lon: 2.161096,
      altitude: 59,
      address: 'C/ MONTMANY, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 227,
      name: 'C/ DEL TORRENT DE LES FLORS, 102',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.407837,
      lon: 2.158678,
      altitude: 59,
      address: 'C/ DEL TORRENT DE LES FLORS, 102',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 228,
      name: 'PL. DEL NORD, 5',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40694,
      lon: 2.155794,
      altitude: 71,
      address: 'PL. DEL NORD, 5',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 229,
      name: 'C/ DE LA SANTACREU, 2 (PL.DE LA VIRREIN',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.405107,
      lon: 2.156874,
      altitude: 71,
      address: 'C/ DE LA SANTACREU, 2 (PL.DE LA VIRREINA)',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 230,
      name: 'C/ DE NIL FABRA, 16-20',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.405986,
      lon: 2.151633,
      altitude: 71,
      address: 'C/ DE NIL FABRA, 16-20',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 231,
      name: 'C/ PI I MARGALL, 38',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.407202,
      lon: 2.163476,
      altitude: 82,
      address: 'C/ PI I MARGALL, 38',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 232,
      name: 'C/ VILÀ I VILÀ, 45',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.373509,
      lon: 2.171002,
      altitude: 1,
      address: 'C/ VILÀ I VILÀ, 45',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 233,
      name: 'C/ NOU DE LA RAMBLA, 164',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.371965,
      lon: 2.166871,
      altitude: 1,
      address: 'C/ NOU DE LA RAMBLA, 164',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 234,
      name: 'PG. DE L´EXPOSICIÓ, 30 /BLASCO GARAY',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.371515,
      lon: 2.162166,
      altitude: 44,
      address: 'PG. DE L´EXPOSICIÓ, 30 /BLASCO GARAY',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 235,
      name: 'AV. PARAL.LEL, 98',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375065,
      lon: 2.165839,
      altitude: 40,
      address: 'AV. PARAL.LEL, 98',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 236,
      name: 'AV. PARAL.LEL, 194',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375127,
      lon: 2.152769,
      altitude: 47,
      address: 'AV. PARAL.LEL, 194',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 237,
      name: 'C/ RIUS I TAULET, 4',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.372891,
      lon: 2.154471,
      altitude: 67,
      address: 'C/ RIUS I TAULET, 4',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 238,
      name: 'C/ ESPRONCEDA, 298',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.415684,
      lon: 2.190986,
      altitude: 17,
      address: 'C/ ESPRONCEDA, 298',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 239,
      name: 'C/ INDÚSTRIA, 329',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.417323,
      lon: 2.184534,
      altitude: 18,
      address: 'C/ INDÚSTRIA, 329',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 240,
      name: 'C/ JOSEP ESTIVILL, 32',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.417763,
      lon: 2.187558,
      altitude: 18,
      address: 'C/ JOSEP ESTIVILL, 32',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 241,
      name: 'C/ TEODOR LLORENTE, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.419337,
      lon: 2.180482,
      altitude: 40,
      address: 'C/ TEODOR LLORENTE, 2',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 242,
      name: 'C/ RAMON ALBÓ, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.424451,
      lon: 2.177284,
      altitude: 37,
      address: 'C/ RAMON ALBÓ, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 243,
      name: 'C/ ALEXANDRE GALÍ, 1-5',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.423939,
      lon: 2.181298,
      altitude: 37,
      address: 'C/ ALEXANDRE GALÍ, 1-5',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 244,
      name: 'C/ FELIP II, 214',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.426896,
      lon: 2.178511,
      altitude: 47,
      address: 'C/ FELIP II, 214',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 246,
      name: 'C/ GARCILASO, 77',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.422844,
      lon: 2.186637,
      altitude: 18,
      address: 'C/ GARCILASO, 77',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 247,
      name: 'C/ FELIPE II, 112',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.421992,
      lon: 2.184806,
      altitude: 19,
      address: 'C/ FELIPE II, 112',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 248,
      name: 'C/ PALÈNCIA, 31',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.418208,
      lon: 2.190374,
      altitude: 18,
      address: 'C/ PALÈNCIA, 31',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 249,
      name: 'C/ AÇORES,1-3',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.425216,
      lon: 2.188997,
      altitude: 18,
      address: 'C/ AÇORES,1-3',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 250,
      name: 'C/ PORTUGAL, 3',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.425905,
      lon: 2.19115,
      altitude: 24,
      address: 'C/ PORTUGAL, 3',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 251,
      name: 'C/ CARDENAL TEDESCHINI, 13',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.425405,
      lon: 2.185226,
      altitude: 27,
      address: 'C/ CARDENAL TEDESCHINI, 13',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 252,
      name: "RAMBLA DE L'ONZE DE SETEMBRE, 31",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.429953,
      lon: 2.193185,
      altitude: 18,
      address: "RAMBLA DE L'ONZE DE SETEMBRE, 31",
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 253,
      name: 'C/ ONZE DE SETEMBRE, 37-39',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.429977,
      lon: 2.191821,
      altitude: 18,
      address: 'C/ ONZE DE SETEMBRE, 37-39',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 254,
      name: "RAMBLA DE L'ONZE DE SETEMBRE, 69",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.429985,
      lon: 2.190163,
      altitude: 25,
      address: "RAMBLA DE L'ONZE DE SETEMBRE, 69",
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 255,
      name: 'C/ IRLANDA, 11-21',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.431183,
      lon: 2.185852,
      altitude: 27,
      address: 'C/ IRLANDA, 11-21',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 256,
      name: 'C/ MALATS, 28-30',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.43608,
      lon: 2.189542,
      altitude: 25,
      address: 'C/ MALATS, 28-30',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 257,
      name: 'C/ SANT ADRIÀ, 2-8',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.433897,
      lon: 2.189614,
      altitude: 0,
      address: 'C/ SANT ADRIÀ, 2-8',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 258,
      name: 'C/ FERNANDO PESSOA, 41',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.442167,
      lon: 2.193032,
      altitude: 15,
      address: 'C/ FERNANDO PESSOA, 41',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 259,
      name: 'C/ DE BARTRINA, 14',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.439109,
      lon: 2.185818,
      altitude: 25,
      address: 'C/ DE BARTRINA, 14',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 260,
      name: 'CARRER DEL CINCA, 7',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4356947,
      lon: 2.1920248,
      altitude: 0,
      address: 'CARRER DEL CINCA, 7',
      post_code: '08030',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 261,
      name: 'C/ VILLARROEL, 39',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.382206,
      lon: 2.160644,
      altitude: 35,
      address: 'C/ VILLARROEL, 39',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 262,
      name: 'C/ ROCAFORT, 103',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.379821,
      lon: 2.152496,
      altitude: 40,
      address: 'C/ ROCAFORT, 103',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 263,
      name: 'PG. TORRAS I BAGES, 29',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.437814,
      lon: 2.191039,
      altitude: 27,
      address: 'PG. TORRAS I BAGES, 29',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 264,
      name: 'C/REPÚBLICA DOMINICANA,25(CENTRE COMERC',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.439949,
      lon: 2.197033,
      altitude: 19,
      address: 'C/REPÚBLICA DOMINICANA,25(CENTRE COMERCIAL)',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 265,
      name: 'C/ CASANOVAS, 67',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385467,
      lon: 2.158532,
      altitude: 40,
      address: 'C/ CASANOVAS, 67',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 266,
      name: 'C/ CONCEPCIÓ ARENAL, 176',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.426841,
      lon: 2.184238,
      altitude: 37,
      address: 'C/ CONCEPCIÓ ARENAL, 176',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 267,
      name: 'PG. TORRAS I BAGES, 129',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.443395,
      lon: 2.190623,
      altitude: 24,
      address: 'PG. TORRAS I BAGES, 129',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 268,
      name: 'C/ FERNANDO PESSOA, 72',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.445705,
      lon: 2.192892,
      altitude: 23,
      address: 'C/ FERNANDO PESSOA, 72',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 269,
      name: 'VIA BARCINO, 121',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.448128,
      lon: 2.192826,
      altitude: 23,
      address: 'VIA BARCINO, 121',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 270,
      name: 'CRTA. DE RIBES, 77 (TRINITAT VELLA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.448628,
      lon: 2.18976,
      altitude: 24,
      address: '270 - CRTA. DE RIBES, 77 (TRINITAT VELLA)',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 271,
      name: 'VIA BARCINO, 69',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.450634,
      lon: 2.192335,
      altitude: 18,
      address: 'VIA BARCINO, 69',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 272,
      name: 'C/ CONCEPCIÓ ARENAL, 281',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.432559,
      lon: 2.184264,
      altitude: 27,
      address: 'C/ CONCEPCIÓ ARENAL, 281',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 273,
      name: 'AV. MERIDIANA/PG. FABRA I PUIG (SANT AN',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.430404,
      lon: 2.183382,
      altitude: 27,
      address: 'AV. MERIDIANA/PG. FABRA I PUIG (SANT ANDREU)',
      post_code: '08908',
      capacity: 36,
      nearby_distance: 1000
    },
    {
      station_id: 274,
      name: 'RAMBLA FABRA I PUIG, 67',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.430092,
      lon: 2.184898,
      altitude: 27,
      address: 'RAMBLA FABRA I PUIG, 67',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 275,
      name: 'AV. RIO DE JANEIRO, 3',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.430693,
      lon: 2.182225,
      altitude: 27,
      address: 'AV. RIO DE JANEIRO, 3',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 276,
      name: 'PLAÇA ALFONS X EL SAVI / RONDA DEL GUIN',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.412163,
      lon: 2.165275,
      altitude: 87,
      address: 'PLAÇA ALFONS X EL SAVI / RONDA DEL GUINARDO',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 277,
      name: 'TRAVESSERA DE GRÀCIA, 328',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.408284,
      lon: 2.16905,
      altitude: 59,
      address: 'TRAVESSERA DE GRÀCIA, 328',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 278,
      name: 'TRAVESSERA DE GRÀCIA, 368',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.409815,
      lon: 2.171621,
      altitude: 55,
      address: 'TRAVESSERA DE GRÀCIA, 368',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 279,
      name: 'C/ MAS CASANOVAS, 137',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.415951,
      lon: 2.174564,
      altitude: 70,
      address: 'C/ MAS CASANOVAS, 137',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 280,
      name: 'C/ SANT ANTONI Mª CLARET, 290-296',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.413986,
      lon: 2.177842,
      altitude: 37,
      address: 'C/ SANT ANTONI Mª CLARET, 290-296',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 281,
      name: "C/ D'ESCORNALBOU, 51",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.418088,
      lon: 2.176313,
      altitude: 45,
      address: "C/ D'ESCORNALBOU, 51",
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 282,
      name: 'PG. FONT D´EN- FARGAS, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.427561,
      lon: 2.166022,
      altitude: 88,
      address: 'PG. FONT D´EN- FARGAS, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 283,
      name: 'C/ FULTON, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.42968,
      lon: 2.16157,
      altitude: 0,
      address: 'C/ FULTON, 1',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 284,
      name: 'AV. DIAGONAL, 652',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389503,
      lon: 2.131454,
      altitude: 60,
      address: 'AV. DIAGONAL, 652',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 285,
      name: 'C/ MALATS, 98-100',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.436708,
      lon: 2.186059,
      altitude: 25,
      address: 'C/ MALATS, 98-100',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 286,
      name: 'C/ BOLÍVIA, 76',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403103,
      lon: 2.191428,
      altitude: 18,
      address: 'C/ BOLÍVIA, 76',
      post_code: '08908',
      capacity: 16,
      nearby_distance: 1000
    },
    {
      station_id: 287,
      name: 'GRAN VIA DE LES CORTS CATALANES, 632',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389171,
      lon: 2.168113,
      altitude: 32,
      address: 'GRAN VIA DE LES CORTS CATALANES, 632',
      post_code: '08908',
      capacity: 16,
      nearby_distance: 1000
    },
    {
      station_id: 288,
      name: 'PL. VIRREI AMAT',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.429636,
      lon: 2.174487,
      altitude: 63,
      address: 'PL. VIRREI AMAT',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 289,
      name: 'C/ MÚRCIA, 64',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.416828,
      lon: 2.191022,
      altitude: 18,
      address: 'C/ MÚRCIA, 64',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 290,
      name: "PL. DELS JARDINS D'ALFÀBIA, 1",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.437094,
      lon: 2.174047,
      altitude: 84,
      address: "PL. DELS JARDINS D'ALFÀBIA, 1",
      cross_street: 'Pg. Verdum',
      post_code: '08908',
      capacity: 17,
      nearby_distance: 1000
    },
    {
      station_id: 291,
      name: 'C/ CUBELLES, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.425897,
      lon: 2.17505,
      altitude: 55,
      address: 'C/ CUBELLES, 2',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 292,
      name: 'C/ AMILCAR, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.430036,
      lon: 2.171974,
      altitude: 63,
      address: 'C/ AMILCAR, 1',
      post_code: '08908',
      capacity: 15,
      nearby_distance: 1000
    },
    {
      station_id: 293,
      name: 'C/ GRANOLLERS, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.428388,
      lon: 2.163101,
      altitude: 95,
      address: 'C/ GRANOLLERS, 1',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 294,
      name: 'SEU DEL DISTRICTE (NOU BARRIS)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.436373,
      lon: 2.170678,
      altitude: 61,
      address: 'SEU DEL DISTRICTE (NOU BARRIS)',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 295,
      name: 'C/ SANT ISCLE, 60',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.43345,
      lon: 2.1715,
      altitude: 61,
      address: 'C/ SANT ISCLE, 60',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 296,
      name: 'C/ DE ROSSELLÓ I PORCEL, 1/AV.MERIDIANA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.436505,
      lon: 2.183877,
      altitude: 25,
      address: 'C/ DE ROSSELLÓ I PORCEL, 1/AV.MERIDIANA',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 297,
      name: 'C/ TURÓ BLAU, 1-3',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.438864,
      lon: 2.176822,
      altitude: 45,
      address: 'C/ TURÓ BLAU, 1-3',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 298,
      name: 'C/ ANDREU NIN, 22',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.43463,
      lon: 2.181747,
      altitude: 45,
      address: 'C/ ANDREU NIN, 22',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 299,
      name: "C/ DE L'ESCULTOR ORDÓÑEZ, 55",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.433965,
      lon: 2.175199,
      altitude: 45,
      address: "C/ DE L'ESCULTOR ORDÓÑEZ, 55",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 300,
      name: 'C/ DE MALADETA, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.431593,
      lon: 2.176909,
      altitude: 47,
      address: 'C/ DE MALADETA, 1',
      post_code: '08908',
      capacity: 18,
      nearby_distance: 1000
    },
    {
      station_id: 301,
      name: 'C/ MARIE CURIE, 8-14',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.437053,
      lon: 2.169644,
      altitude: 61,
      address: 'C/ MARIE CURIE, 8-14',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 302,
      name: 'C/ CAVALLERS, 41',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39065,
      lon: 2.111615,
      altitude: 73,
      address: 'C/ CAVALLERS, 41',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 303,
      name: 'C/ CAVALLERS, 67',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.393341,
      lon: 2.115076,
      altitude: 101,
      address: 'C/ CAVALLERS, 67',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 304,
      name: 'PG. MANUEL GIRONA, 7',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390208,
      lon: 2.121029,
      altitude: 63,
      address: 'PG. MANUEL GIRONA, 7',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 305,
      name: 'AV. DIAGONAL, 680',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387498,
      lon: 2.123637,
      altitude: 63,
      address: 'AV. DIAGONAL, 680',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 306,
      name: 'C/ DOCTOR SALVADOR CARDENAL, 1-5',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385465,
      lon: 2.122912,
      altitude: 63,
      address: 'C/ DOCTOR SALVADOR CARDENAL, 1-5',
      post_code: '08908',
      capacity: 31,
      nearby_distance: 1000
    },
    {
      station_id: 307,
      name: 'C/ PINTOR RIBALTA / AV. XILE',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.379135,
      lon: 2.113505,
      altitude: 50,
      address: 'C/ PINTOR RIBALTA / AV. XILE',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 308,
      name: 'C/ CARDENAL REIG, 11',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.376788,
      lon: 2.113864,
      altitude: 50,
      address: 'C/ CARDENAL REIG, 11',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 309,
      name: 'C/ SANT RAMON NONAT,  26',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37674,
      lon: 2.116973,
      altitude: 47,
      address: 'C/ SANT RAMON NONAT,  26',
      post_code: '08908',
      capacity: 22,
      nearby_distance: 1000
    },
    {
      station_id: 310,
      name: 'C/ JOSEP SAMITIER / JOAN XXIII',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381223,
      lon: 2.119077,
      altitude: 47,
      address: 'C/ JOSEP SAMITIER / JOAN XXIII',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 312,
      name: 'C/ ARIZALA, 77',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.379046,
      lon: 2.123253,
      altitude: 50,
      address: 'C/ ARIZALA, 77',
      post_code: '08908',
      capacity: 36,
      nearby_distance: 1000
    },
    {
      station_id: 313,
      name: 'C/ FELIU DE CASANOVA, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375366,
      lon: 2.129847,
      altitude: 42,
      address: 'C/ FELIU DE CASANOVA, 1',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 314,
      name: 'RAMBLA DEL BRASIL, 44',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37836,
      lon: 2.129737,
      altitude: 46,
      address: 'RAMBLA DEL BRASIL, 44',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 315,
      name: 'C/ DEL GUINARDÓ, 32-38',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.415617,
      lon: 2.181966,
      altitude: 33,
      address: 'C/ DEL GUINARDÓ, 32-38',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 316,
      name: 'C/ CANTÀBRIA, 55',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.4222743,
      lon: 2.1984029,
      altitude: 13,
      address: 'C/ CANTÀBRIA, 55',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 317,
      name: 'RAMBLA DE PRIM, 256',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.425566,
      lon: 2.200693,
      altitude: 12,
      address: 'RAMBLA DE PRIM, 256',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 318,
      name: 'C/ CARTAGENA, 368',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.413406,
      lon: 2.171331,
      altitude: 59,
      address: 'C/ CARTAGENA, 368',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 319,
      name: 'C/ SAGUÉS, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.393713,
      lon: 2.145584,
      altitude: 65,
      address: 'C/ SAGUÉS, 1',
      post_code: '08908',
      capacity: 31,
      nearby_distance: 1000
    },
    {
      station_id: 320,
      name: 'VÍA AUGUSTA, 109',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.401116,
      lon: 2.147953,
      altitude: 80,
      address: 'VÍA AUGUSTA, 109',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 321,
      name: 'C/ SANT HERMENEGILD, 30',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403054,
      lon: 2.144549,
      altitude: 80,
      address: 'C/ SANT HERMENEGILD, 30',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 322,
      name: 'C/ SANTALÓ, 165',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.400977,
      lon: 2.13907,
      altitude: 106,
      address: 'C/ SANTALÓ, 165',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 323,
      name: 'C/ VALLMAJOR, 13',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.397996,
      lon: 2.138717,
      altitude: 79,
      address: 'C/ VALLMAJOR, 13',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 324,
      name: 'C/ REINA VICTORIA, 31',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396938,
      lon: 2.136258,
      altitude: 79,
      address: 'C/ REINA VICTORIA, 31',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 325,
      name: 'C/ ALT DE GIRONELLA, 13',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39493,
      lon: 2.130325,
      altitude: 82,
      address: 'C/ ALT DE GIRONELLA, 13',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 326,
      name: 'C/ BALMES, 409',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.407384,
      lon: 2.1383,
      altitude: 110,
      address: 'C/ BALMES, 409',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 327,
      name: 'C/ REUS, 23',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.405,
      lon: 2.13453,
      altitude: 106,
      address: 'C/ REUS, 23',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 328,
      name: 'C/ ARTESA DE SEGRE, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40293,
      lon: 2.13427,
      altitude: 106,
      address: 'C/ ARTESA DE SEGRE, 2',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 329,
      name: 'C/ DE LES ESCOLES PIES, 99',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402879,
      lon: 2.128541,
      altitude: 130,
      address: 'C/ DE LES ESCOLES PIES, 99',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 331,
      name: 'C/ CASTELLNOU, 65',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39706,
      lon: 2.12801,
      altitude: 82,
      address: 'C/ CASTELLNOU, 65',
      post_code: '08908',
      capacity: 31,
      nearby_distance: 1000
    },
    {
      station_id: 332,
      name: 'C/ DOCTOR ROUX, 86',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.399822,
      lon: 2.12809,
      altitude: 82,
      address: 'C/ DOCTOR ROUX, 86',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 333,
      name: 'PASSATGE DE SENILLOSA, 3-5',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395632,
      lon: 2.125106,
      altitude: 82,
      address: 'PASSATGE DE SENILLOSA, 3-5',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 334,
      name: 'VIA AUGUSTA, 348',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.400722,
      lon: 2.123268,
      altitude: 138,
      address: 'VIA AUGUSTA, 348',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 335,
      name: 'C/ SANTA AMÈLIA, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.393639,
      lon: 2.123224,
      altitude: 96,
      address: 'C/ SANTA AMÈLIA, 2',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 336,
      name: 'C/ CAPONATA, 10',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395274,
      lon: 2.120973,
      altitude: 96,
      address: 'C/ CAPONATA, 10',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 337,
      name: 'C/ CARME KARR, 12-14',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398719,
      lon: 2.120422,
      altitude: 96,
      address: 'C/ CARME KARR, 12-14',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 338,
      name: 'AV. J. V. FOIX,  63',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.397572,
      lon: 2.119506,
      altitude: 96,
      address: 'AV. J. V. FOIX,  63',
      post_code: '08908',
      capacity: 36,
      nearby_distance: 1000
    },
    {
      station_id: 339,
      name: 'C/ RAMON TURRÓ, 246',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.401662,
      lon: 2.205277,
      altitude: 9,
      address: 'C/ RAMON TURRÓ, 246',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 340,
      name: 'C/ SANT ADRIÀ, 113',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.43619,
      lon: 2.204573,
      altitude: 28,
      address: 'C/ SANT ADRIÀ, 113',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 341,
      name: "PG. D'ENRIC SANCHIS, 33",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.433958,
      lon: 2.206166,
      altitude: 28,
      address: "PG. D'ENRIC SANCHIS, 33",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 342,
      name: 'C/ ROC BORONAT, 134',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403502,
      lon: 2.193702,
      altitude: 18,
      address: 'C/ ROC BORONAT, 134',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 343,
      name: 'CAMPANA DE LA MAQUINISTA (SAO PAULO I P',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.438886,
      lon: 2.199598,
      altitude: 19,
      address: 'CAMPANA DE LA MAQUINISTA (SAO PAULO I PL. DE',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 344,
      name: "C/ CIUTAT D'ASUNCIÓN, 73 / POTOSÍ",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.443127,
      lon: 2.199538,
      altitude: 23,
      address: "C/ CIUTAT D'ASUNCIÓN, 73 / POTOSÍ",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 345,
      name: 'PL. TERESA DE CLARAMUNT/C/ DELS FERROCA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.363093,
      lon: 2.139771,
      altitude: 48,
      address: 'PL. TERESA DE CLARAMUNT/C/ DELS FERROCARILS',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 346,
      name: 'C/ DE LA FONERIA, 33',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.360654,
      lon: 2.139132,
      altitude: 48,
      address: 'C/ DE LA FONERIA, 33',
      post_code: '08908',
      capacity: 36,
      nearby_distance: 1000
    },
    {
      station_id: 347,
      name: 'C/ ALTS FORNS, 77',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.359496,
      lon: 2.14182,
      altitude: 35,
      address: 'C/ ALTS FORNS, 77',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 348,
      name: 'JARDINS DE CAN FERRERO/PG.DE LA ZONA FR',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.357067,
      lon: 2.141563,
      altitude: 13,
      address: 'JARDINS DE CAN FERRERO/PG.DE LA ZONA FRANCA',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 349,
      name: "C/ DE L'ENERGIA, 2 / ALTS FORNS",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.357338,
      lon: 2.137172,
      altitude: 13,
      address: "C/ DE L'ENERGIA, 2 / ALTS FORNS",
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 350,
      name: 'C/ VILLARROEL, 208',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391833,
      lon: 2.14812,
      altitude: 69,
      address: 'C/ VILLARROEL, 208',
      post_code: '08908',
      capacity: 31,
      nearby_distance: 1000
    },
    {
      station_id: 351,
      name: 'C/ JANE ADDAMS, 26/ CRTA. DEL PRAT',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.362135,
      lon: 2.135637,
      altitude: 48,
      address: 'C/ JANE ADDAMS, 26/ CRTA. DEL PRAT',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 352,
      name: 'C/RADI,10/GRAN VIA DE LES CORTS CATALA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.36335,
      lon: 2.134144,
      altitude: 48,
      address: 'C/RADI,10/GRAN VIA DE LES CORTS CATALANES',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 353,
      name: 'C/ MUNNÉ  2-6',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375414,
      lon: 2.123191,
      altitude: 47,
      address: 'C/ MUNNÉ  2-6',
      post_code: '08908',
      capacity: 31,
      nearby_distance: 1000
    },
    {
      station_id: 354,
      name: 'RAMBLA DEL BRASIL, 5',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37592,
      lon: 2.13008,
      altitude: 46,
      address: 'RAMBLA DEL BRASIL, 5',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 356,
      name: 'C/ CAMÈLIES, 73',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.413425,
      lon: 2.163411,
      altitude: 115,
      address: 'C/ CAMÈLIES, 73',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 357,
      name: 'C/ CARDENER, 59',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.410615,
      lon: 2.157987,
      altitude: 0,
      address: 'C/ CARDENER, 59',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 358,
      name: 'C/ GOMBAU, 24',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387225,
      lon: 2.17927,
      altitude: 16,
      address: 'C/ GOMBAU, 24',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 359,
      name: 'C/ MÉNDEZ NÚÑEZ, 16',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390021,
      lon: 2.177572,
      altitude: 16,
      address: 'C/ MÉNDEZ NÚÑEZ, 16',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 360,
      name: 'C/ BAILÉN, 62',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395213,
      lon: 2.172984,
      altitude: 42,
      address: 'C/ BAILÉN, 62',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 361,
      name: 'PG. DE COLOM (LES RAMBLES)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.376292,
      lon: 2.178553,
      altitude: 5,
      address: 'PG. DE COLOM (LES RAMBLES)',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 362,
      name: 'C/ BAILÉN, 100',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.397006,
      lon: 2.17057,
      altitude: 42,
      address: 'C/ BAILÉN, 100',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 363,
      name: 'C/ BRUC, 20',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39076,
      lon: 2.1744,
      altitude: 32,
      address: 'C/ BRUC, 20',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 364,
      name: 'PG. DE GRÀCIA, 61',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.39324,
      lon: 2.16343,
      altitude: 50,
      address: 'PG. DE GRÀCIA, 61',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 365,
      name: 'C/ VILADOMAT, 244',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387499,
      lon: 2.14702,
      altitude: 51,
      address: 'C/ VILADOMAT, 244',
      post_code: '08908',
      capacity: 31,
      nearby_distance: 1000
    },
    {
      station_id: 367,
      name: 'C/ MARQUÈS DE SENTMENAT, 46',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385057,
      lon: 2.137331,
      altitude: 57,
      address: 'C/ MARQUÈS DE SENTMENAT, 46',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 368,
      name: 'C/ DIPUTACIÓ, 350',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.396815,
      lon: 2.175714,
      altitude: 21,
      address: 'C/ DIPUTACIÓ, 350',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 369,
      name: 'C/ CONSELL DE CENT, 482',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.400168,
      lon: 2.177948,
      altitude: 28,
      address: 'C/ CONSELL DE CENT, 482',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 370,
      name: 'C/ SARDENYA, 326',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.40377,
      lon: 2.172864,
      altitude: 49,
      address: 'C/ SARDENYA, 326',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 371,
      name: 'C/ DELS ENAMORATS, 49',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.404027,
      lon: 2.181264,
      altitude: 28,
      address: 'C/ DELS ENAMORATS, 49',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 372,
      name: 'C/ RIBES, 77',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.400167,
      lon: 2.184362,
      altitude: 18,
      address: 'C/ RIBES, 77',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 373,
      name: 'AV. PARAL.LEL, 132',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375073,
      lon: 2.161305,
      altitude: 35,
      address: 'AV. PARAL.LEL, 132',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 374,
      name: 'PG. DE GRÀCIA, 89',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395,
      lon: 2.16123,
      altitude: 50,
      address: 'PG. DE GRÀCIA, 89',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 375,
      name: 'WORLD TRADE CENTER',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.372154,
      lon: 2.180701,
      altitude: 1,
      address: 'WORLD TRADE CENTER',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 376,
      name: 'WORLD  TRADE CENTER',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.371663,
      lon: 2.180302,
      altitude: 1,
      address: 'WORLD  TRADE CENTER',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 377,
      name: 'PL. ICTÍNEO',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.37747,
      lon: 2.183737,
      altitude: 1,
      address: 'PL. ICTÍNEO',
      post_code: '08908',
      capacity: 29,
      nearby_distance: 1000
    },
    {
      station_id: 378,
      name: 'PL. JOAQUIM XIRAU I PALAU, 1',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.378516,
      lon: 2.176716,
      altitude: 5,
      address: 'PL. JOAQUIM XIRAU I PALAU, 1',
      post_code: '08908',
      capacity: 21,
      nearby_distance: 1000
    },
    {
      station_id: 380,
      name: 'C/ DURAN I BAS, 2',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.385463,
      lon: 2.174252,
      altitude: 32,
      address: 'C/ DURAN I BAS, 2',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 381,
      name: 'C/ AGUSTÍ DURAN I SANPERE, 10',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381686,
      lon: 2.167816,
      altitude: 24,
      address: 'C/ AGUSTÍ DURAN I SANPERE, 10',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 382,
      name: 'C/ LOPE DE VEGA, 79',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.403938,
      lon: 2.204188,
      altitude: 10,
      address: 'C/ LOPE DE VEGA, 79',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 384,
      name: 'C/ VILAMARÍ, 85',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.380708,
      lon: 2.146793,
      altitude: 44,
      address: 'C/ VILAMARÍ, 85',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 385,
      name: 'C/ CASANOVA, 119',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387978,
      lon: 2.155266,
      altitude: 45,
      address: 'C/ CASANOVA, 119',
      post_code: '08908',
      capacity: 30,
      nearby_distance: 1000
    },
    {
      station_id: 386,
      name: 'AV. PARAL.LEL 164',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375086,
      lon: 2.156848,
      altitude: 40,
      address: 'AV. PARAL.LEL 164',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 387,
      name: 'C/ NÀPOLS, 125',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.395796,
      lon: 2.178784,
      altitude: 21,
      address: 'C/ NÀPOLS, 125',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 388,
      name: 'C/ RIERA ALTA, 6',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.380593,
      lon: 2.167418,
      altitude: 24,
      address: 'C/ RIERA ALTA, 6',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 389,
      name: 'RECINTE PARC DE LA CIUTADELLA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38739,
      lon: 2.1875,
      altitude: 11,
      address: 'RECINTE PARC DE LA CIUTADELLA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 390,
      name: 'C/ COMERÇ, 36',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.386955,
      lon: 2.18195,
      altitude: 16,
      address: 'C/ COMERÇ, 36',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 391,
      name: 'C/ SAGRERA, 74',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.422873,
      lon: 2.191204,
      altitude: 18,
      address: 'C/ SAGRERA, 74',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 392,
      name: 'C/ RAMON TURRÓ, 4',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390595,
      lon: 2.190681,
      altitude: 11,
      address: 'C/ RAMON TURRÓ, 4',
      post_code: '08908',
      capacity: 19,
      nearby_distance: 1000
    },
    {
      station_id: 393,
      name: 'C/ LLACUNA, 86',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.402177,
      lon: 2.197653,
      altitude: 16,
      address: 'C/ LLACUNA, 86',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 394,
      name: 'C/ DIPUTACIÓ, 226',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.387151,
      lon: 2.162838,
      altitude: 41,
      address: 'C/ DIPUTACIÓ, 226',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 395,
      name: 'PL. CATALUNYA, 22',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.386009,
      lon: 2.170212,
      altitude: 32,
      address: 'PL. CATALUNYA, 22',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 396,
      name: 'C/ JOAN MIRÓ, 2-12',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389028,
      lon: 2.196844,
      altitude: 1,
      address: 'C/ JOAN MIRÓ, 2-12',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 397,
      name: 'AV. LITORAL (PG MARÍTIM DEL PORT OLÍMPI',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388894,
      lon: 2.199334,
      altitude: 1,
      address: 'AV. LITORAL (PG MARÍTIM DEL PORT OLÍMPIC)',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 398,
      name: 'PG. MARÍTIM DE LA BARCELONETA/ANDREA DÒ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381011,
      lon: 2.193502,
      altitude: 0.1,
      address: 'PG. MARÍTIM DE LA BARCELONETA/ANDREA DÒRIA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 400,
      name: 'MOLL ORIENTAL',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.36958,
      lon: 2.188017,
      altitude: 5,
      address: 'MOLL ORIENTAL',
      post_code: '08908',
      capacity: 28,
      nearby_distance: 1000
    },
    {
      station_id: 401,
      name: 'PL. ANTONIO LÓPEZ ( VIA LAIETANA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.381241,
      lon: 2.181838,
      altitude: 5,
      address: 'PL. ANTONIO LÓPEZ ( VIA LAIETANA)',
      post_code: '08908',
      capacity: 23,
      nearby_distance: 1000
    },
    {
      station_id: 402,
      name: 'PG. DE COLOM (VIA LAIETANA)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.380705,
      lon: 2.182415,
      altitude: 5,
      address: 'PG. DE COLOM (VIA LAIETANA)',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 404,
      name: 'C/ JUAN GRIS, 28',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.365374,
      lon: 2.133087,
      altitude: 16,
      address: 'C/ JUAN GRIS, 28',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 405,
      name: 'C/ COMTE BORRELL, 198',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.38551,
      lon: 2.152,
      altitude: 45,
      address: 'C/ COMTE BORRELL, 198',
      post_code: '08908',
      capacity: 26,
      nearby_distance: 1000
    },
    {
      station_id: 406,
      name: 'GRAN VIA DE LES CORTS CATALANES, 592',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.386512,
      lon: 2.164597,
      altitude: 41,
      address: 'GRAN VIA DE LES CORTS CATALANES, 592',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 408,
      name: 'C/ RAMON TRIAS FARGAS, 23',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.388988,
      lon: 2.192283,
      altitude: 1,
      address: 'C/ RAMON TRIAS FARGAS, 23',
      post_code: '08908',
      capacity: 39,
      nearby_distance: 1000
    },
    {
      station_id: 409,
      name: "C/ JOAN D'ÀUSTRIA, 31B",
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.392272,
      lon: 2.192556,
      altitude: 11,
      address: "C/ JOAN D'ÀUSTRIA, 31B",
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 410,
      name: 'PG.DE COLOM (LES RAMBLES)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.376433,
      lon: 2.17871,
      altitude: 5,
      address: 'PG.DE COLOM (LES RAMBLES)',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 412,
      name: 'PL. URQUINAONA, 3',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.389012,
      lon: 2.172667,
      altitude: 32,
      address: 'PL. URQUINAONA, 3',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 413,
      name: 'C/ BRUC, 66',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.393512,
      lon: 2.170718,
      altitude: 42,
      address: 'C/ BRUC, 66',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 414,
      name: 'C/ CASP, 67',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.393744,
      lon: 2.176451,
      altitude: 21,
      address: 'C/ CASP, 67',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 415,
      name: 'RAMBLA DEL RAVAL, 13',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.379374,
      lon: 2.168921,
      altitude: 24,
      address: 'RAMBLA DEL RAVAL, 13',
      post_code: '08908',
      capacity: 25,
      nearby_distance: 1000
    },
    {
      station_id: 416,
      name: 'RAMBLA DEL RAVAL, 20',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.378134,
      lon: 2.16965,
      altitude: 24,
      address: 'RAMBLA DEL RAVAL, 20',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 418,
      name: 'PG. DE LLUíS COMPANYS (ARC DE TRIOMF)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.390943,
      lon: 2.180251,
      altitude: 16,
      address: 'PG. DE LLUíS COMPANYS (ARC DE TRIOMF)',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 419,
      name: 'PG.DE LLUíS COMPANYS (ARC DE TRIOMF)',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.391299,
      lon: 2.180828,
      altitude: 16,
      address: 'PG.DE LLUíS COMPANYS (ARC DE TRIOMF)',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 420,
      name: 'GRAN VIA DE LES CORTS CATALANES, 361',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.374213,
      lon: 2.148082,
      altitude: 61,
      address: 'GRAN VIA DE LES CORTS CATALANES, 361',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 421,
      name: 'PL. DE JOAN PEIRÓ',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.378031,
      lon: 2.139124,
      altitude: 45,
      address: 'PL. DE JOAN PEIRÓ',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 423,
      name: 'C/ HONDURAS, 32-34',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.420201,
      lon: 2.189532,
      altitude: 18,
      address: 'C/ HONDURAS, 32-34',
      post_code: '08908',
      capacity: 32,
      nearby_distance: 1000
    },
    {
      station_id: 424,
      name: 'PG. MARÍTIM DE LA BARCELONETA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.379632,
      lon: 2.192662,
      altitude: 5,
      address: 'PG. MARÍTIM DE LA BARCELONETA',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 426,
      name: 'C/ DE RIBES, 59 B',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398305,
      lon: 2.183023,
      altitude: 21,
      address: 'C/ DE RIBES, 59 B',
      post_code: '08908',
      capacity: 33,
      nearby_distance: 1000
    },
    {
      station_id: 427,
      name: 'C/ DE SANT PAU, 119/ RONDA SANT PAU',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.375336,
      lon: 2.168007,
      altitude: 24,
      address: 'C/ DE SANT PAU, 119/ RONDA SANT PAU',
      post_code: '08908',
      capacity: 20,
      nearby_distance: 1000
    },
    {
      station_id: 428,
      name: 'C/ PUJADES, 103',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.398389,
      lon: 2.196261,
      altitude: 11,
      address: 'C/ PUJADES, 103',
      post_code: '08908',
      capacity: 27,
      nearby_distance: 1000
    },
    {
      station_id: 492,
      name: 'PL. TETUAN',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3944248,
      lon: 2.1751026,
      altitude: 21,
      address: 'PL. TETUAN',
      post_code: 1,
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 494,
      name: 'RAMBLA CATALUNYA/DIPUTACIO',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3896703,
      lon: 2.1654279,
      altitude: 41,
      address: 'RAMBLA CATALUNYA/DIPUTACIO',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 495,
      name: 'C/ DIPUTACIÓ - TARRAGONA',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.3767081,
      lon: 2.1483947,
      altitude: 45,
      address: 'C/ DIPUTACIÓ - TARRAGONA',
      post_code: '08908',
      capacity: 24,
      nearby_distance: 1000
    },
    {
      station_id: 496,
      name: 'C/ PROVENÇA,  445',
      physical_configuration: 'ELECTRICBIKESTATION',
      lat: 41.404839,
      lon: 2.17482,
      altitude: 21,
      address: 'C/ PROVENÇA, 445',
      post_code: 1,
      capacity: 18,
      nearby_distance: 1000
    }
  ]
}

export default function Station() {
  return (
    <StationDrawer
      open={true}
      handleClose={() => {}}
      bikes={data.bikes}
      stations={data.stations}
      id={data.id}
    />
  )
}
