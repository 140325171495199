import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#212121' // #212121
    }
  },
  typography: {
    fontFamily: 'TeleGroteskNext',
    useNextVariants: true,
    h1: {
      fontFamily: 'TeleGroteskNext',
      fontSize: '3.75rem',
      //lineHeight: "4"
      lineHeight: 1.33
    },
    h2: {
      fontFamily: 'TeleGroteskNext',
      fontSize: '3rem',
      //lineHeight: "3"
      lineHeight: 1.25
    },
    h3: {
      fontFamily: 'TeleGroteskNext',
      fontSize: '2.25rem',
      //lineHeight: "2.25"
      lineHeight: 1.25,
      fontWeight: 300
    },
    h4: {
      fontFamily: 'TeleGroteskNext',
      fontSize: '2rem',
      //lineHeight: "2"
      lineHeight: 1.2
    },
    h5: {
      fontFamily: 'TeleGroteskNext',
      fontSize: '1.875rem',
      //lineHeight: "1.875"
      lineHeight: 1.2
    },
    h6: {
      fontFamily: 'TeleGroteskNext',
      fontSize: '1.75rem',
      //lineHeight: "1.75"
      lineHeight: 1.2
    },
    button: {
      fontWeight: '700'
    },
    body1: {
      fontSize: '1.125rem',
      //lineHeight: "1.35"
      lineHeight: 1.4
    },
    body2: {
      fontSize: '1rem',
      //lineHeight: "1.125"
      lineHeight: 1.4
    },
    subtitle1: {
      fontSize: '1.5rem',
      //lineHeight: "1.75"
      lineHeight: 1.4
    },
    subtitle2: {
      fontSize: '1.3125rem',
      //lineHeight: "1.5625"
      lineHeight: 1.4
    },
    caption: {
      fontSize: '0.875rem',
      lineHeight: 1.125
    }
  },
  overrides: {
    MuiMenuItem: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        fontSize: '1.0625rem',
        lineHeight: '1.35',
        [breakpoints.up('sm')]: {
          fontSize: '1.1111rem'
        },
        [breakpoints.up('md')]: {
          fontSize: '1.1111rem'
        },
        [breakpoints.up('lg')]: {
          fontSize: '1.1111rem'
        }
      }
    }
  }
})

// export default responsiveFontSizes(theme)
export default theme
