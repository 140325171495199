import React from 'react'
import PropTypes from 'prop-types'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  // height of (indicator + variation + indicator) + marginBottom of (variation) = 0,875 * height of (typography element of value)
  row: {
    lineHeight: '5rem'
  },
  indicatorColumn: {
    alignSelf: 'flex-start',
    minWidth: '2rem'
  },
  indicator: {
    height: props => (props.small ? '.95rem' : '1.5rem')
  },
  variation: {
    height: '1.125rem',
    marginTop: '0.250rem'
  },
  variationTypography: {
    fontWeight: 700
  },
  measureLeft: {
    alignSelf: 'flex-end',
    marginBottom: props => (props.small ? '.375rem' : '.75rem'),
    paddingLeft: props => (props.small ? theme.spacing(0.5) : theme.spacing(1.5)),
    textTransform: 'uppercase',
    fontWeight: '700'
  },
  iconLeft: {
    alignSelf: 'flex-end',
    marginBottom: props => (props.small ? '.25rem' : '.75rem'),
    paddingLeft: props => (props.small ? theme.spacing(0) : theme.spacing(1))
  },
  measureRight: {
    alignSelf: 'flex-end',
    marginBottom: props => (props.small ? '.375rem' : '.75rem'),
    paddingRight: props => (props.small ? theme.spacing(0.5) : theme.spacing(1.5)),
    textTransform: 'uppercase',
    fontWeight: '700'
  },
  iconRight: {
    alignSelf: 'flex-end',
    marginBottom: props => (props.small ? '.25rem' : '.75rem'),
    paddingRight: props => (props.small ? theme.spacing(0) : theme.spacing(1))
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  value: {
    alignSelf: 'flex-end'
  },
  leftSpacer: {
    marginLeft: 'auto'
  },
  alignRight: {
    textAlign: 'right'
  }
}))

const IncrementIcon = withStyles(() => ({
  root: {
    color: '#a4eb64'
  }
}))(KeyboardArrowUpIcon)

const DecrementIcon = withStyles(() => ({
  root: {
    color: '#ff5e00'
  }
}))(KeyboardArrowDownIcon)

function LeftMetric(props) {
  const { title, value, variation, measure, icon, small } = props
  const classes = useStyles(props)
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant={small ? 'body1' : 'subtitle1'} className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems="flex-start">
        <Grid
          item
          xs={1}
          className={classes.indicatorColumn}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.indicator}>
            {variation > 0 && variation != null && (
              <IncrementIcon fontSize={small ? 'default' : 'large'} />
            )}
          </Grid>
          <Grid item className={classes.variation}>
            {variation !== 0 && variation !== null && (
              <Typography
                variant={small ? 'button' : 'body1'}
                className={classes.variationTypography}
              >
                {Math.abs(variation)}
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.indicator}>
            {variation < 0 && <DecrementIcon fontSize={small ? 'default' : 'large'} />}
          </Grid>
        </Grid>
        <Grid item className={classes.value}>
          <Typography variant={small ? 'h3' : 'h1'}>{value == null ? '  ' : value}</Typography>
        </Grid>
        {measure && (
          <Grid item className={classes.measureLeft}>
            <Typography variant={small ? 'caption' : 'button'}>{measure}</Typography>
          </Grid>
        )}
        {icon && (
          <Grid item className={classes.iconLeft}>
            {icon}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

LeftMetric.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  measure: PropTypes.string,
  icon: PropTypes.element,
  small: PropTypes.bool
}

LeftMetric.defaultProps = {
  value: null,
  variation: 0,
  measure: null,
  icon: null,
  small: false
}

function RightMetric(props) {
  const { title, value, variation, measure, icon, small } = props
  const classes = useStyles(props)
  return (
    <Grid container spacing={0} className={classes.alignRight}>
      <Grid item xs={12}>
        <Typography variant={small ? 'body1' : 'subtitle1'} className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} container alignItems="flex-start">
        <Grid item className={classes.leftSpacer} />
        {icon && (
          <Grid item className={classes.iconRight}>
            {icon}
          </Grid>
        )}
        {measure && (
          <Grid item className={classes.measureRight}>
            <Typography variant={small ? 'caption' : 'button'}>{measure}</Typography>
          </Grid>
        )}
        <Grid item className={classes.value}>
          <Typography variant={small ? 'h3' : 'h1'}>{value == null ? '  ' : value}</Typography>
        </Grid>

        <Grid
          item
          xs={1}
          className={classes.indicatorColumn}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.indicator}>
            {variation > 0 && variation != null && (
              <IncrementIcon fontSize={small ? 'default' : 'large'} />
            )}
          </Grid>
          <Grid item className={classes.variation}>
            {variation !== 0 && variation !== null && (
              <Typography
                variant={small ? 'button' : 'body1'}
                className={classes.variationTypography}
              >
                {Math.abs(variation)}
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.indicator}>
            {variation < 0 && <DecrementIcon fontSize={small ? 'default' : 'large'} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

RightMetric.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  measure: PropTypes.string,
  icon: PropTypes.element,
  small: PropTypes.bool
}

RightMetric.defaultProps = {
  value: null,
  variation: 0,
  measure: null,
  icon: null,
  small: false
}

function Metric(props) {
  const { title, value, variation, measure, icon, small, right } = props
  return right ? (
    <RightMetric
      title={title}
      value={value}
      variation={variation}
      measure={measure}
      icon={icon}
      small={small}
    />
  ) : (
    <LeftMetric
      title={title}
      value={value}
      variation={variation}
      measure={measure}
      icon={icon}
      small={small}
    />
  )
}

export default Metric
export { IncrementIcon, DecrementIcon }

Metric.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  measure: PropTypes.string,
  icon: PropTypes.element,
  small: PropTypes.bool,
  right: PropTypes.bool
}

Metric.defaultProps = {
  value: null,
  variation: 0,
  measure: null,
  icon: null,
  small: false,
  right: false
}
