import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Fade from '@material-ui/core/Fade'

import { ConfigContext } from '../Config'

const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 400,
    flexGrow: 1,
    height: `calc(100% - ${theme.spacing(12)}px)`,
    textAlign: 'center'
    //height: '100vh'
  },
  header: {},
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    margin: `${theme.spacing(2)}px auto`
  },
  itemContainer: {
    height: '100%'
  },
  itemHeader: {
    //height: '5vh',
    zIndex: 3
  },
  itemImg: {
    height: '50vh',
    paddingTop: '20vh',
    width: '100%'
  },
  itemText: {
    flexGrow: 1,
    zIndex: 2,
    paddingTop: theme.spacing(4)
  }
}))

export default function IllustrationSubtitleTextCard(props) {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  const { label, svg, text } = props
  return (
    <Container className={classes.root} maxWidth="md">
      <Fade in style={{ transitionDelay: `300ms` }}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          className={classes.itemContainer}
        >
          <Grid item className={classes.itemImg}>
            <Fade in style={{ transitionDelay: `150ms` }}>
              {svg}
            </Fade>
          </Grid>
          <Grid item className={classes.itemHeader}>
            <Fade in style={{ transitionDelay: `350ms` }}>
              <Typography variant="h1" className={classes.header}>
                {label}
              </Typography>
            </Fade>
          </Grid>
          <Grid item className={classes.itemText}>
            <Fade in style={{ transitionDelay: `550ms` }}>
              <Typography variant="subtitle1" className={classes.header}>
                {text}
              </Typography>
            </Fade>
          </Grid>
        </Grid>
      </Fade>
    </Container>
  )
}
