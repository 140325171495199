import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as InitialArtwork } from './Artwork/bus-onboarding-0.svg'
import { ReactComponent as ApiStepArtwork } from './Artwork/bus-onboarding-1-api.svg'
import { ReactComponent as StationsExchangesStepArtwork } from './Artwork/bus-onboarding-2-stations-exchanges.svg'
import { ReactComponent as LinesStepArtwork } from './Artwork/bus-onboarding-3-lines.svg'
import { ReactComponent as SchedulesStepArtwork } from './Artwork/bus-onboarding-4-schedules.svg'
import { ReactComponent as PositionsStepArtwork } from './Artwork/bus-onboarding-5-positions.svg'
import { ReactComponent as VehiclesStepArtwork } from './Artwork/bus-onboarding-6-vehicles.svg'
import { ReactComponent as TripsStepArtwork } from './Artwork/bus-onboarding-7-trips.svg'

import { ConfigContext } from '../Config'

const useStyles = makeStyles(theme => ({
  '@keyframes initial': {
    to: {
      opacity: 1
    }
  },
  illustration_initial: {
    '& .cls-3,.cls-4': {
      fill: config => config.palette.primary
    },
    '& .cls-11': {
      fill: config => config.palette.accent
    },
    '& #primary_shape': {
      opacity: 0,
      animation: '$initial 3s 1 ease-in-out forwards'
    },
    '& #primary_waves': {
      opacity: 0,
      animation: '$initial 1s 1 ease-in-out forwards',
      animationDelay: '1.6s'
    },
    '& #accent_dots': {
      opacity: 0,
      animation: '$initial 1s 1 ease-in-out forwards',
      animationDelay: '1.2s'
    }
  },
  '@keyframes api': {
    from: {
      strokeDashoffset: '0',
      stroke: 'black'
    },
    '15%': {
      opacity: 1,
      stroke: 'black'
    },
    '55%': {
      stroke: 'unset'
    },
    '60%': {
      strokeDashoffset: '-600'
    },
    '95%': {
      opacity: 1
    },
    to: {
      strokeDashoffset: '-200'
    }
  },
  illustration_api: {
    '& .cls-4': {
      opacity: 0,
      stroke: config => config.palette.primary,
      animation: '$api 10s 1 linear'
    },
    '& #Dots': {
      animationDelay: '.4s'
    },
    '& #Dots-2': {
      animationDelay: '3.9s'
    },
    '& #Dots-3': {
      animationDelay: '1.3s'
    },
    '& #Dots-4': {
      animationDelay: '2.6s'
    },
    '& #Dots-5': {
      animationDelay: '3.6s'
    },
    '& #Dots-6': {
      animationDelay: '4.9s'
    },
    '& #Dots-7': {
      animationDelay: '4.1s'
    },
    '& #Dots-8': {
      animationDelay: '5.33s'
    },
    '& #Dots-9': {
      animationDelay: '5.75s'
    },
    '& #Dots-10': {
      animationDelay: '6.2s'
    },
    '& #Dots-11': {
      animationDelay: '5.06s'
    }
  },
  '@keyframes exchanges': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },
  illustration_exchanges: {
    '& .cls-5': {
      opacity: 0,
      animation: '$exchanges .4s 1 linear forwards',
      animationDelay: '1s'
    },
    '& #Shadows image': {
      opacity: 0,
      animation: '$exchanges .3s 1 linear forwards',
      animationDelay: '1.6s'
    },
    '& .cls-6': {
      opacity: 0,
      fill: config => config.palette.primary,
      animation: '$exchanges .4s 1 linear forwards',
      animationDelay: '3s'
    },
    '& .cls-7': {
      opacity: 0,
      fill: config => config.palette.accent,
      animation: '$exchanges .6s 1 linear forwards',
      animationDelay: '4s'
    },
    '& .cls-5:nth-of-type(n+1)': {
      animationDelay: '1.1s'
    },
    '& .cls-5:nth-of-type(3n+1)': {
      animationDelay: '1.3s'
    },
    '& .cls-6:nth-of-type(2n+1)': {
      animationDelay: '3.2s'
    },
    '& .cls-6:nth-of-type(4n)': {
      animationDelay: '3.5s'
    },
    '& .cls-7:nth-of-type(3n)': {
      animationDelay: '4.6s'
    },
    '& .cls-7:nth-of-type(5n)': {
      animationDelay: '4.2s'
    }
  },
  '@keyframes lines': {
    '0%': {
      //strokeDashoffset: 400,
      opacity: 0
    },
    '60%': {
      opacity: 1
    },
    '70%': {
      strokeDashoffset: 6000,
      opacity: 1
    },
    '75%': {
      strokeDashoffset: 6000,
      opacity: 0
    },
    '100%': {
      //strokeDashoffset: 400,
      opacity: 0
    }
  },
  '@keyframes street': {
    '50%': {
      opacity: 0.3
    }
  },
  illustration_lines: {
    '& #Street image': {
      animation: '$street 5s infinite ease-in-out'
    },
    '& .cls-6': {
      stroke: 'grey',
      strokeDashoffset: 800,
      strokeLinecap: 'square',
      strokeDasharray: '800 3000',
      animation: '$lines 3s infinite linear'
    },
    '& .cls-7': {
      stroke: config => config.palette.accent,
      strokeLinecap: 'square'
    },
    '& #Bus-2': {
      strokeDashoffset: 800,
      strokeDasharray: '800 3000',
      animation: '$lines 3s infinite linear',
      animationDelay: '.5s'
    },
    '& #Bus-3': {
      strokeDashoffset: 800,
      strokeDasharray: '800 4000',
      animation: '$lines 3s infinite linear',
      animationDelay: '.3s'
    },
    '& #Bus-4': {
      strokeDashoffset: 800,
      strokeDasharray: '800 4000',
      animation: '$lines 3s infinite linear',
      animationDelay: '.25s'
    },
    '& #Bus-1': {
      strokeDashoffset: 800,
      strokeDasharray: '800 4000',
      animation: '$lines 3s infinite linear',
      animationDelay: '.75s'
    }
  },
  '@keyframes schedule': {
    '50%': {
      stroke: 'white'
    }
  },
  illustration_schedules: {
    '& #Street image': {
      animation: '$street 5s infinite ease-in-out'
    },
    '& .cls-10': {
      stroke: config => config.palette.primary,
      animation: '$schedule 1s infinite linear'
    },
    '& #Bus-1': {
      animationDelay: '.6s'
    },
    '& #Bus-2': {
      animationDelay: '1.35s'
    },
    '& #Bus-3': {
      animationDelay: '.2s'
    },
    '& #Bus-4': {
      animationDelay: '.9s'
    }
  },
  '@keyframes vehicle': {
    '0%': {
      //strokeDashoffset: 400,
      //opacity: 0
    },
    '5%': {
      opacity: 0.85
    },
    '100%': {
      strokeDashoffset: 0,
      opacity: 1
    }
  },
  illustration_positions: {
    '& #Street image': {
      animation: '$street 5s infinite ease-in-out'
    },
    '& #Vehicles_unfiltered line, #Vehicles_unfiltered path': {
      stroke: config => config.palette.accent,
      strokeDashoffset: -200,
      strokeDasharray: '25 4025',
      animation: '$vehicle 120s 1 linear forwards'
    }
  },
  '@keyframes grouping': {
    '0%': {
      opacity: 0
    },
    '25%': {
      opacity: 1
    },
    '50%': {
      opacity: 0
    },
    '100%': {
      opacity: 0
    }
  },
  illustration_vehicles: {
    '& #Street image': {
      animation: '$street 5s infinite ease-in-out'
    },
    '& #Vehicles_filtered_line line, #Vehicles_filtered_line path': {
      stroke: config => config.palette.primary,
      strokeDashoffset: -200,
      strokeDasharray: '25 4025',
      animation: '$vehicle 120s 1 linear forwards'
    },
    '& .cls-6': {
      opacity: 0,
      animation: '$grouping 3s infinite ease-in-out'
    },
    '& .cls-7': {
      opacity: 0,
      animation: '$grouping 3s infinite ease-in-out',
      animationDelay: '1.5s'
    },
    '& #Bus-1>.cls-9': {
      stroke: config => config.palette.accent
    },
    '& .gradient-primary-stop': {
      stopColor: config => config.palette.accent
    }
  },
  illustration_trips: {
    '& #Street image': {
      animation: '$street 5s infinite ease-in-out'
    },
    '& .cls-8': {
      stroke: config => config.palette.accent,
      strokeDashoffset: -200,
      strokeDasharray: '50 4000',
      animation: '$vehicle 120s 1 linear forwards'
    }
  }
}))

function StyledInitialArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <InitialArtwork width="100%" className={classes.illustration_initial} />
}

function StyledApiStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <ApiStepArtwork width="100%" className={classes.illustration_api} />
}

function StyledStationsExchangesStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <StationsExchangesStepArtwork width="100%" className={classes.illustration_exchanges} />
}

function StyledLinesStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <LinesStepArtwork width="100%" className={classes.illustration_lines} />
}

function StyledSchedulesStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <SchedulesStepArtwork width="100%" className={classes.illustration_schedules} />
}

function StyledPositionsStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <PositionsStepArtwork width="100%" className={classes.illustration_positions} />
}

function StyledVehiclesStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <VehiclesStepArtwork width="100%" className={classes.illustration_vehicles} />
}

function StyledTripsStepArtwork() {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  return <TripsStepArtwork width="100%" className={classes.illustration_trips} />
}

const steps = [
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Currently tracking 16 vehicles',
    text: 'Whoa, where did they come from?',
    svg: <StyledInitialArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'The starting point',
    text: `We start from a given list of bus stations. Using a static API from TMB we get each station's metadata, geoposition and all the objects representing possible route exchanges happening there.`,
    svg: <StyledApiStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Stations and exchanges',
    text: `We start from a given list of bus stations. Using a static API from TMB we get each station's metadata, geoposition and all the objects representing possible route exchanges happening there.`,
    svg: <StyledStationsExchangesStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Lines and routes',
    text: `Iterating over all collected exchanges we discover all possible lines.
              For each one its route metadata and track geopositions are fetched, keeping only bus ones:
              you can see them represented on screen as pulsing streets.
              Each route is processed, computing every point's travelled distance — that comes in handy later.`,
    svg: <StyledLinesStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Schedules',
    text: `We now take the dynamic API from TMB to query for bus schedules.
        For each station and line we collect the expected waiting time — literally that:  
        just how many seconds away from the station the next bus from that line is.`,
    svg: <StyledSchedulesStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Positions',
    text: `Based on the geoposition of the station, the time to arrival, 
        the average bus speed (which is 11.25 kmh as reported by TMB) and all the information we already have 
        from that route's track we previously processed, we guess a position for each scheduled arrival.
        For now, these are possible vehicles.`,
    svg: <StyledPositionsStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Vehicles',
    text: `But... wouldn't we be dealing with duplicates here? Oh, yes.
        That's why we put together all possible vehicles from the same line, ordered by the position within the route of the stations
        where we are expecting them to arrive. Then, we scroll each line top to bottom grouping and slicing 
        these possible vehicles into unique vehicles by comparing distances, expected times and velocities.`,
    svg: <StyledVehiclesStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
  },
  {
    type: 'IllustrationSubtitleTextCard',
    label: 'Trips',
    text: `To represent each vehicle we need a set of timestamped waypoints. 
        Merging the points of the route for that line and the positions of all stations it will have to pass we the waypoints.
        From the expected arrival at each station and considering loading and unloading time, we derive segment velocities — hopefully, 
        no speeding fines will happen — and get the timestamps. All set, and everything happened so fast!`,
    svg: <StyledTripsStepArtwork />,
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
  }
]

export default steps

export function getSteps(stats) {
  const { stations, exchanges, lines, routes, schedules, vehicles, unique } = stats
  return [
    {
      type: 'IllustrationSubtitleTextCard',
      label: `Currently tracking ${unique} vehicles`,
      text:
        'You may click on either the vehicles or the stations at the map to get more information. Want to know how we got them? Go to the next screen!',
      svg: <StyledInitialArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'The starting point',
      text: `We start from a given list of ${stations} bus stations. These are set beforehand, you can't choose them yet.`,
      svg: <StyledApiStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'Stations and exchanges',
      text: `Using a static API from TMB we get each station's metadata, geoposition and all the objects representing the ${exchanges} possible route exchanges happening there.`,
      svg: <StyledStationsExchangesStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'Lines and routes',
      text: `Iterating over all collected exchanges we discover all ${lines} possible lines.
              For each one its route metadata and track geopositions are fetched, keeping only bus ones:
              you can see them represented on screen as pulsing streets.
              Each one of the ${routes} routes is processed, computing every point's travelled distance — that comes in handy later.`,
      svg: <StyledLinesStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'Schedules',
      text: `We now take the dynamic API from TMB to query ${schedules} bus schedules.
        For each station and line we collect the expected waiting time — literally that:  
        just how many seconds away from the station the next bus from that line is.`,
      svg: <StyledSchedulesStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'Positions',
      text: `Based on the geoposition of the station, the time to arrival, 
        the average bus speed (which is 11.25 kmh as reported by TMB) and all the information we already have 
        from that route's track we previously processed, we guess a position for each scheduled arrival.
        For now, there are ${vehicles} possible vehicles.`,
      svg: <StyledPositionsStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'Vehicles',
      text: `But... wouldn't we be dealing with duplicates here? Oh, yes.
        That's why we put together all possible vehicles from the same line, ordered by the position within the route of the stations
        where we are expecting them to arrive. Then, we scroll each line top to bottom grouping and slicing 
        these possible vehicles into ${unique} unique vehicles by comparing distances, expected times and velocities.`,
      svg: <StyledVehiclesStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
    },
    {
      type: 'IllustrationSubtitleTextCard',
      label: 'Trips',
      text: `To represent each vehicle we need a set of timestamped waypoints. 
        Merging the points of the route for that line and the positions of all stations it will have to pass we the waypoints.
        From the expected arrival at each station and considering loading and unloading time, we derive segment velocities — hopefully, 
        no speeding fines will happen — and get the timestamps. All set, and everything happened so fast!`,
      svg: <StyledTripsStepArtwork />,
      imgPath:
        'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60'
    }
  ]
}
