import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Container from '@material-ui/core/Container'

import MobileStepper from '@material-ui/core/MobileStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import { ConfigContext } from '../Config'

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0
  },
  appBarTitle: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  toolbar: {
    padding: theme.spacing(2)
  },
  container: {
    height: '100%',
    padding: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(4)
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  // Steps
  stepper: {
    flexGrow: 1,
    marginRight: theme.spacing(3),
    justifyContent: 'center',
    background: 'transparent'
  },
  dot: {
    background: '#fafafa',
    margin: theme.spacing(0, 2)
  },
  dotActive: {
    background: config => config.palette.primary
  },
  buttonRight: {
    marginRight: theme.spacing(6)
  },
  buttonLeft: {
    marginLeft: theme.spacing(12)
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function OnboardingDialog(props) {
  const config = useContext(ConfigContext)
  const classes = useStyles(config)
  const { steps, open, close } = props

  // Steps
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = steps.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  // Dialog status
  const handleClose = () => {
    close()
    setActiveStep(0)
  }

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Amplitude
          eventProperties={inheritedProps => ({
            ...inheritedProps,
            scope: [...inheritedProps.scope, 'dialog'],
            dialog: 'Bus onboarding'
          })}
        >
          <LogOnMount eventType="Bus onboarding dialog accessed" />
        </Amplitude>
        <Container className={classes.container} maxWidth={false}>
          {steps[activeStep]}
        </Container>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              //edge="end"
              onClick={handleBack}
              disabled={activeStep === 0}
              aria-label="prev"
              className={classes.buttonLeft}
            >
              <KeyboardArrowLeft fontSize="large" />
            </IconButton>
            <MobileStepper
              steps={maxSteps}
              className={classes.stepper}
              position="static"
              variant="dots"
              activeStep={activeStep}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive
              }}
            />
            <IconButton
              color="inherit"
              //edge="start"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              aria-label="next"
              className={classes.buttonRight}
            >
              <KeyboardArrowRight fontSize="large" />
            </IconButton>
            <IconButton color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Dialog>
    </div>
  )
}
