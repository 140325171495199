import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import SvgIcon from '@material-ui/core/SvgIcon'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'

import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import Fade from '@material-ui/core/Fade'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import Moment from 'react-moment'

import { ConfigContext } from '../Config'

function BikeRentingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="256"
        x2="256"
        y1="-.0001"
        y2="511.2841"
      >
        <stop offset="0" stopColor="#2c3e50" />
        <stop offset="1" stopColor="#bdc3c7" />
      </linearGradient>
      <path
        d="m427 303.203125v-93.917969c0-33.085937-26.914062-60-60-60h-20v40h20c11.027344 0 20 8.972656 20 20v15h-19c-31.960938 0-61.207031 16.144532-78.238281 43.191406l-55.253907 87.761719-75.230468-115.953125h30.722656v-40h-83v32.917969l30.621094 47.195313-10.386719 24.238281c-7.25-1.566407-14.675781-2.351563-22.234375-2.351563-57.898438 0-105 47.101563-105 105 0 57.894532 47.101562 105 105 105 52.027344 0 95.332031-38.042968 103.578125-87.769531 22.8125-5.6875 42.75-19.921875 55.660156-40.425781l59.375-94.300782c9.660157-15.34375 26.253907-24.503906 44.386719-24.503906h19v38.917969c-48.363281 9.367187-85 52.023437-85 103.082031 0 57.894532 47.101562 105 105 105s105-47.105468 105-105c0-51.058594-36.636719-93.714844-85-103.082031zm-222.265625 79.636719c-5.878906 2.238281-12.203125 3.445312-18.734375 3.445312h-50.667969l28.496094-66.496094zm-99.734375 88.445312c-35.839844 0-65-29.160156-65-65 0-35.84375 29.160156-65 65-65 2.007812 0 4 .105469 5.976562.285156l-24.355468 56.828126c-1.039063 2.417968-1.621094 5.085937-1.621094 7.886718 0 11.042969 8.953125 20 20 20h61.839844c-8.457032 26.082032-32.976563 45-61.839844 45zm302 0c-35.839844 0-65-29.160156-65-65 0-28.863281 18.917969-53.382812 45-61.839844v61.839844c0 11.042969 8.953125 20 20 20s20-8.957031 20-20v-61.839844c26.082031 8.457032 45 32.976563 45 61.839844 0 35.839844-29.160156 65-65 65zm-212.246094-353.859375-28.285156-28.285156 89.144531-89.140625 89.140625 89.140625-28.285156 28.285156-41.375-41.375v122.234375h-40v-121.199218zm0 0"
        fill="url(#a)"
      />
    </SvgIcon>
  )
}

function BikeReturningIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="256"
        x2="256"
        y1="-.0001"
        y2="511.2841"
      >
        <stop offset="0" stopColor="#2c3e50" />
        <stop offset="1" stopColor="#bdc3c7" />
      </linearGradient>
      <path
        d="m512 0v400h-80v-40h40v-320h-196v320h40v40h-120v-40h40v-320h-196v320h40v40h-80v-400zm-315 180c0 33.085938-26.914062 60-60 60h-20v40h-40v-160h60c33.085938 0 60 26.914062 60 60zm-40 0c0-11.027344-8.972656-20-20-20h-20v40h20c11.027344 0 20-8.972656 20-20zm0 0"
        fill="url(#a)"
      />
    </SvgIcon>
  )
}

function BikeChargingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="256"
        x2="256"
        y1="-.0001"
        y2="511.2841"
      >
        <stop offset="0" stopColor="#2c3e50" />
        <stop offset="1" stopColor="#bdc3c7" />
      </linearGradient>
      <path
        d="m427 303.917969v-93.917969c0-33.085938-26.914062-60-60-60h-20v40h20c11.027344 0 20 8.972656 20 20v15h-19c-31.960938 0-61.207031 16.148438-78.238281 43.191406l-55.253907 87.761719-75.234374-115.953125h30.726562v-40h-83v32.921875l30.621094 47.191406-10.386719 24.238281c-7.25-1.5625-14.675781-2.351562-22.234375-2.351562-57.898438 0-105 47.101562-105 105s47.101562 105 105 105c52.027344 0 95.332031-38.039062 103.578125-87.769531 22.8125-5.6875 42.75-19.917969 55.660156-40.421875l59.375-94.300782c9.660157-15.347656 26.253907-24.507812 44.386719-24.507812h19v38.917969c-48.363281 9.367187-85 52.023437-85 103.082031 0 57.898438 47.101562 105 105 105s105-47.101562 105-105c0-51.058594-36.636719-93.714844-85-103.082031zm-222.265625 79.640625c-5.878906 2.234375-12.203125 3.441406-18.734375 3.441406h-50.667969l28.496094-66.492188zm-99.734375 88.441406c-35.839844 0-65-29.160156-65-65s29.160156-65 65-65c2.007812 0 4 .105469 5.976562.285156l-24.355468 56.824219c-1.039063 2.421875-1.621094 5.085937-1.621094 7.890625 0 11.046875 8.953125 20 20 20h61.839844c-8.457032 26.082031-32.976563 45-61.839844 45zm302 0c-35.839844 0-65-29.160156-65-65 0-28.863281 18.917969-53.382812 45-61.839844v61.839844c0 11.046875 8.953125 20 20 20s20-8.953125 20-20v-61.839844c26.082031 8.457032 45 32.976563 45 61.839844 0 35.839844-29.160156 65-65 65zm-140.351562-345h-79.648438l52-127h43.703125l-36.054687 87h81.953124l-53.601562 113-35 20v-38zm0 0"
        fill="url(#a)"
      />
    </SvgIcon>
  )
}

/*const AvailableElectricalBikeIcon = withStyles(() => ({
  root: {
    color: '#e20074'
  }
}))(OfflineBoltIcon)*/

/*const AvailableBikeIcon = withStyles(() => ({
  root: {
    color: '#e20074'
  }
}))(FiberManualRecordIcon)
*/

const AvailableBikeIcon = FiberManualRecordIcon
const AvailableElectricalBikeIcon = OfflineBoltIcon

const EmptyDockIcon = withStyles(() => ({
  root: {
    color: '#333'
  }
}))(FiberManualRecordIcon)

const DisabledDockIcon = withStyles(() => ({
  root: {
    color: '#ffcc00'
  }
}))(FiberManualRecordIcon)

const useStyles = makeStyles(theme => ({
  list: {
    width: '70vw',
    height: '100%',
    [`@media (max-width:1079px)`]: {
      width: '85vw'
    }
  },
  fullList: {
    width: 'auto'
  },
  container: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: theme.spacing(4)
  },
  bikeGrid: {
    marginTop: theme.spacing(4),
    flexGrow: 1
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  station: {
    marginBottom: theme.spacing(12),
    [`@media (max-width:1079px)`]: {
      marginBottom: theme.spacing(8)
    }
  },
  value: {},
  category: {
    textTransform: 'uppercase',
    fontWeight: '800',
    paddingLeft: theme.spacing(1),
    [`@media (max-width:1079px)`]: {
      fontSize: '1.2rem'
    }
  },
  properties: {
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  bikes: {
    color: config => config.palette.primary,
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  dots: {},
  number: {
    paddingTop: theme.spacing(1),
    textAlign: 'right'
  },
  docks: {
    color: '#333',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  malfunctions: {
    color: '#ffcc00',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  icon: {
    margin: theme.spacing(0.5),
    [`@media (max-width:1079px)`]: {
      margin: 0
    }
  },
  primaryIcon: {
    margin: theme.spacing(0.5),
    color: config => config.palette.primary,
    [`@media (max-width:1079px)`]: {
      margin: 0
    }
  },
  metaContainer: {
    textAlign: 'right'
  },
  meta: {
    color: '#ccc',
    textTransform: 'uppercase',
    fontWeight: '800'
  }
}))

export default function StationDrawer(props) {
  const { open, handleClose, bikes, stations, id } = props

  const config = useContext(ConfigContext)
  const classes = useStyles(config)

  const data = bikes.find(x => x.station_id === id)
  const station = stations.find(x => x.station_id === id)
  // console.log(data);

  const sideList = (
    <div className={classes.list} role="presentation" onClick={handleClose} onKeyDown={handleClose}>
      <Container className={classes.container}>
        <Typography variant="subtitle1" className={classes.title}>
          Station {id}
        </Typography>
        {id > 0 && (
          <>
            <Typography variant="h1" className={classes.station}>
              {station.name}
            </Typography>

            <Amplitude
              eventProperties={inheritedProps => ({
                ...inheritedProps,
                scope: [...inheritedProps.scope, 'drawer'],
                drawer: 'Bike station',
                stationId: id,
                stationName: station.name
              })}
            >
              <LogOnMount eventType="Bike station drawer accessed" />
            </Amplitude>

            {/* sitation status grid */}
            <Grid container className={classes.grid} justify="flex-start" alignItems="flex-start">
              <Grid item xs={4} container className={classes.properties} justify="flex-start">
                <Grid item xs={4} className={classes.number}>
                  <Typography variant="h1" className={classes.value}>
                    <BikeRentingIcon style={{ width: '48', height: '48' }} />
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.dots}>
                  <Typography variant="body1" className={classes.category}>
                    Check-out
                  </Typography>
                  <Typography variant="subtitle1" className={classes.category}>
                    {data.is_renting ? 'Open' : 'Closed'}
                    {data.is_renting ? <CheckIcon /> : <CloseIcon />}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} container className={classes.properties} justify="flex-start">
                <Grid item xs={4} className={classes.number}>
                  <Typography variant="h1" className={classes.value}>
                    <BikeReturningIcon style={{ width: '48', height: '48' }} />
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.dots}>
                  <Typography variant="body1" className={classes.category}>
                    Check-in
                  </Typography>
                  <Typography variant="subtitle1" className={classes.category}>
                    {data.is_returning ? 'Open' : 'Closed'}
                    {data.is_returning ? <CheckIcon /> : <CloseIcon />}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} container className={classes.properties} justify="flex-start">
                <Grid item xs={4} className={classes.number}>
                  <Typography variant="h1" className={classes.value}>
                    <BikeChargingIcon style={{ width: '48', height: '48' }} />
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.dots}>
                  <Typography variant="body1" className={classes.category}>
                    Charger
                  </Typography>
                  <Typography variant="subtitle1" className={classes.category}>
                    {data.is_charging_station ? 'Yes' : 'No'}
                    {data.is_charging_station ? <CheckIcon /> : <CloseIcon />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* bike status grid */}
            <Grid
              container
              className={classes.bikeGrid}
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={4} className={classes.bikes} container justify="flex-start">
                <Grid item xs={4} className={classes.number}>
                  <Typography variant="h1" className={classes.value}>
                    {data.num_bikes_available}
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.dots}>
                  <Typography variant="body1" className={classes.category}>
                    Bikes
                  </Typography>

                  {[...Array(data.num_bikes_available_types.ebike)].map((e, i) => (
                    <Fade key={`f-ebikes-${i}`} in style={{ transitionDelay: `${200 + 25 * i}ms` }}>
                      <AvailableElectricalBikeIcon
                        fontSize="large"
                        key={i}
                        className={classes.primaryIcon}
                      />
                    </Fade>
                  ))}
                  {[...Array(data.num_bikes_available_types.mechanical)].map((e, i) => (
                    <Fade
                      key={`f-mechanical-${i}`}
                      in
                      style={{ transitionDelay: `${250 + 25 * i}ms` }}
                    >
                      <AvailableBikeIcon fontSize="large" key={i} className={classes.primaryIcon} />
                    </Fade>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={4} className={classes.docks} container justify="flex-start">
                <Grid item xs={4} className={classes.number}>
                  <Typography variant="h1" className={classes.value}>
                    {data.num_docks_available}
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.dots}>
                  <Typography variant="body1" className={classes.category}>
                    Docks
                  </Typography>

                  {[...Array(data.num_docks_available)].map((e, i) => (
                    <Fade key={`f-docks-${i}`} in style={{ transitionDelay: `${300 + 25 * i}ms` }}>
                      <EmptyDockIcon fontSize="large" key={i} className={classes.icon} />
                    </Fade>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={4} className={classes.malfunctions} container justify="flex-start">
                <Grid item xs={4} className={classes.number}>
                  <Typography variant="h1" className={classes.value}>
                    {station.capacity - data.num_bikes_available - data.num_docks_available}
                  </Typography>
                </Grid>
                <Grid item xs={8} className={classes.dots}>
                  <Typography variant="body1" className={classes.category}>
                    Unavailable
                  </Typography>

                  {[
                    ...Array(station.capacity - data.num_bikes_available - data.num_docks_available)
                  ].map((e, i) => (
                    <Fade
                      in
                      key={`f-malfunctions-${i}`}
                      style={{ transitionDelay: `${350 + 25 * i}ms` }}
                    >
                      <DisabledDockIcon fontSize="large" key={i} className={classes.icon} />
                    </Fade>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.metaContainer}>
              <Grid item xs={12}>
                <Typography variant="caption" className={classes.meta}>
                  LON {station.lon} LAT {station.lat} » Last activity recorded{' '}
                  <Moment unix fromNow>
                    {data.last_reported}
                  </Moment>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </div>
  )

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {sideList}
      </Drawer>
    </div>
  )
}

StationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  bikes: PropTypes.arrayOf(PropTypes.object).isRequired,
  stations: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.number.isRequired
}
