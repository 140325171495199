import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Fade from '@material-ui/core/Fade'

import { FlexibleHeightXYPlot, LineSeries, AreaSeries, GradientDefs, MarkSeries } from 'react-vis'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

import BusSchedule, { BusScheduleTime, THRESHOLDS } from './BusSchedule'

import { ConfigContext } from '../Config'

/*const THRESHOLDS = {
  PROGRESS: 7 * 60,
  FLASHING: 90,
  LOST: -15
}*/

const POSITIONS = {
  STEP: 12,
  GAP: 3,
  PROGRESS: 15,
  PROGRESSMARK: 20,
  FLASHINGMARK: 85,
  FLASHING: 90,
  //LAST: 90,
  END: 100,
  START: 0
}

const useStyles = makeStyles(theme => ({
  list: {
    width: '70vw',
    height: '100%',
    [`@media (max-width:1079px)`]: {
      width: '85vw'
    }
  },
  fullList: {
    width: 'auto'
  },
  container: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    marginTop: theme.spacing(4)
  },
  bikeGrid: {
    marginTop: theme.spacing(4),
    flexGrow: 1
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  station: {
    marginBottom: theme.spacing(12),
    textTransform: 'uppercase',
    [`@media (max-width:1079px)`]: {
      marginBottom: theme.spacing(8)
    }
  },
  value: {},
  genericStop: {
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  properties: {
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  bikes: {
    color: '#e20074',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  dots: {},
  number: {
    paddingTop: theme.spacing(1),
    textAlign: 'right'
  },
  docks: {
    color: '#333',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  malfunctions: {
    color: '#ffcc00',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  icon: {
    margin: theme.spacing(0.5)
  },
  metaContainer: {
    textAlign: 'right'
  },
  meta: {
    color: '#ccc',
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  plotContainer: {
    //flexGrow: 1,
    //minHeight: '900px',
    textAlign: 'right'
  },
  plotItem: {
    //flexGrow: 1
  },
  endStop: {
    minHeight: '8.4rem',
    [`@media (max-width:1079px)`]: {
      minHeight: '6.4rem'
    }
  },
  nextStop: {
    marginBottom: GRAPH => `calc(4.2rem + ${GRAPH.MARGIN}px)`,
    width: 'inherit',
    overflow: 'hidden',
    textOverflo: 'elipsis',
    wordWrap: 'no-wrap',
    [`@media (max-width:1079px)`]: {
      marginBottom: GRAPH => `calc(3.2rem + ${GRAPH.MARGIN}px)`
    }
  },
  stop: {
    minHeight: '8.4rem',
    [`@media (max-width:1079px)`]: {
      minHeight: '6.4rem'
    }
  },
  startStop: {
    marginTop: GRAPH => `calc(4.2rem - 2.2rem + ${GRAPH.MARGIN}px)`,
    [`@media (max-width:1079px)`]: {
      marginTop: GRAPH => `calc(3.2rem - 2.2rem + ${GRAPH.MARGIN}px)`
    }
  },
  nextStopTitle: {
    paddingLeft: theme.spacing(6),
    textTransform: 'uppercase',
    fontWeight: '800',
    [`@media (max-width:1079px)`]: {
      fontSize: '1.6rem'
    }
  },
  nextStopSubTitle: {
    textTransform: 'uppercase',
    fontWeight: '100',
    [`@media (max-width:1079px)`]: {
      fontSize: '1.2rem'
    }
  },
  time: { marginBottom: theme.spacing(6) },
  approachingVehicles: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    padding: theme.spacing(4, 8),
    textAlign: 'left',
    [`@media (max-width:1079px)`]: {
      marginBottom: 0,
      marginTop: 0
    }
  },
  approachingVehicle: {
    padding: 0,
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: '4px solid #fafafa',
    [`@media (max-width:1079px)`]: {
      marginBottom: theme.spacing(2)
    }
  },
  approachingRoute: {
    fontWeight: 700,
    marginTop: theme.spacing(2.5)
  },
  approachingLine: {
    fontWeight: 700
  },
  approach: {
    fontSize: '3rem',
    fontWeight: 100,
    marginBottom: theme.spacing(6),
    [`@media (max-width:1079px)`]: {
      fontSize: '1.5rem'
    }
  },
  timeColumn: { textAlign: 'center' },
  chip: {
    fontSize: '2rem',
    height: theme.spacing(10),
    width: theme.spacing(10),
    borderRadius: theme.spacing(6),
    fontWeight: 800
  }
}))

export default function BusDrawer(props) {
  const { open, handleClose, object, paths } = props

  const GRAPH = {
    MARGIN: 20,
    LAST: POSITIONS.END - POSITIONS.STEP,
    PROGRESS:
      (object.schedule && POSITIONS.END - POSITIONS.STEP * (object.schedule.length + 1)) ||
      POSITIONS.PROGRESS,
    START:
      (object.schedule && POSITIONS.END - POSITIONS.STEP * (object.schedule.length + 2)) ||
      POSITIONS.START
  }

  const config = useContext(ConfigContext)
  const classes = useStyles(GRAPH)

  // const stop = parseInt(Object.keys(object.schedule)[0], 10) // first key of the schedule object
  // const stop = object.properties.CODI_PARADA

  //const s = stations.features.find(el => el.properties.CODI_PARADA === stop)
  /*const r = routes.features.find(
    el => el.properties.ID_RECORREGUT === object.properties.ID_RECORREGUT
  )*/

  /*const remaining = (object.schedule && object.schedule[0].timestamp) - Date.now()
  const slope =
    (POSITIONS.FLASHINGMARK - POSITIONS.PROGRESSMARK) / (THRESHOLDS.PROGRESS - THRESHOLDS.FLASHING)
*/
  const mremaining = object.schedule && object.schedule.map(el => el.timestamp - Date.now())
  const mslope =
    object.schedule &&
    object.schedule.map((e, i, a) => {
      let dist = null
      let time = null
      if (i === a.length - 1) {
        // last one, moves from the bottom
        dist = GRAPH.LAST - i * POSITIONS.STEP - POSITIONS.GAP - GRAPH.PROGRESS
        time = THRESHOLDS.PROGRESS - THRESHOLDS.NEAR
      } else {
        // moves one STEP in the interval of time between schedules
        dist = POSITIONS.STEP - 2 * POSITIONS.GAP
        time = e.timestamp / 1000 - THRESHOLDS.NEAR - a[i + 1].timestamp / 1000 + THRESHOLDS.LEAVING
      }
      return dist / time
    })

  /*const location = rem => {
    const rems = rem / 1000
    let pos = POSITIONS.PROGRESSMARK
    if (rems < THRESHOLDS.PROGRESS) {
      pos = POSITIONS.FLASHINGMARK - (rems - THRESHOLDS.FLASHING) * slope
    }
    if (rems < THRESHOLDS.FLASHING) {
      pos = POSITIONS.FLASHING
    }
    return pos
  }*/

  const next = () => {
    const i = mremaining.findIndex(el => el < -THRESHOLDS.LEAVING * 1000)
    return i === -1 ? mremaining.length - 1 : i - 1
  }

  const mlocation = () => {
    const i = mremaining.findIndex(el => el < -THRESHOLDS.LEAVING * 1000) - 1
    let l = 0
    if (i === -1) {
      // all stops covered
      l = GRAPH.LAST
    } else if (i === -2) {
      // no stops covered yet
      const next = GRAPH.LAST - POSITIONS.STEP * (mremaining.length - 1)
      l =
        next -
        POSITIONS.GAP -
        (mremaining[mremaining.length - 1] / 1000 - THRESHOLDS.NEAR) * mslope[mremaining.length - 1]
      if (l > next - POSITIONS.GAP) l = next
      if (l < GRAPH.PROGRESS) l = GRAPH.PROGRESS
    } else {
      // last stop covered found at index i
      const next = GRAPH.LAST - POSITIONS.STEP * i
      l = next - POSITIONS.GAP - (mremaining[i] / 1000 - THRESHOLDS.NEAR) * mslope[i]
      if (l > next - POSITIONS.GAP) l = next
      if (l < next - POSITIONS.STEP + POSITIONS.GAP) l = next - POSITIONS.STEP
    }
    return l
  }

  const stop = object.schedule && object.schedule[0].station

  //const others = vehicles.filter(el => el.CODI_PARADA === stop && el.id !== object.id)

  const getOthers = () => {
    let i = mremaining.findIndex(el => el < -THRESHOLDS.LEAVING * 1000) - 1
    if (i === -1) return { station: null, vehicles: [] }
    if (i === -2) i = mremaining.length - 1
    return {
      station: object.schedule[i].station,
      vehicles: paths
        .filter(
          el =>
            el.schedule.find(s => s.station === object.schedule[i].station) &&
            el.properties.id !== object.properties.id
        )
        .sort(
          (a, b) =>
            a.schedule.find(el => el.station === object.schedule[i].station).timestamp -
            b.schedule.find(el => el.station === object.schedule[i].station).timestamp
        )
    }
  }
  //const getRouteData = rid => routes.features.find(el => el.properties.ID_RECORREGUT === rid)

  const sideList = (
    <div className={classes.list} role="presentation" onClick={handleClose} onKeyDown={handleClose}>
      <Container className={classes.container}>
        {object.properties && (
          <>
            <Amplitude
              eventProperties={inheritedProps => ({
                ...inheritedProps,
                scope: [...inheritedProps.scope, 'drawer'],
                drawer: 'Bus vehicle',
                busId: object.properties.id,
                busLine: object.properties.NOM_LINIA,
                busRoute: object.properties.DESC_LINIA,
                busFamily: object.properties.NOM_FAMILIA
              })}
            >
              <LogOnMount eventType="Bus vehicle drawer accessed" />
              <Typography variant="subtitle1" className={classes.title}>
                Vehicle #{object.properties.id} of type {object.properties.NOM_FAMILIA}
              </Typography>
            </Amplitude>
          </>
        )}
        {object.properties && (
          <>
            <Typography variant="h1" className={classes.station}>
              {object.properties.NOM_LINIA} {object.properties.DESC_LINIA}
            </Typography>
            {/*<pre>{JSON.stringify(mremaining, null, 2)}</pre>
            <pre>{JSON.stringify(mslope, null, 2)}</pre>
            <pre>{covered()}</pre>
            <pre>{next()}</pre>
        <pre>{mlocation()}</pre>*/}

            <Fade in style={{ transitionDelay: `300ms` }}>
              <Grid
                container
                alignItems="stretch"
                justify="flex-start"
                spacing={0}
                className={classes.plotContainer}
              >
                <Grid item xs={9} container spacing={0} direction="column" alignItems="stretch">
                  <Grid item className={classes.endStop}>
                    <Typography variant="h4" className={classes.endStopTitle}>
                      {object.properties.DESTI_SENTIT}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.nextStop}>
                    {object.schedule.map(stop => (
                      <section key={stop.id} className={classes.stop}>
                        <Typography variant="h3" className={classes.nextStopTitle}>
                          {stop.properties.NOM_PARADA}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.nextStopSubTitle}>
                          {`${stop.properties.ADRECA} (${stop.properties.NOM_DISTRICTE})`}
                        </Typography>
                      </section>
                    ))}
                    {/*
                    <Typography variant="h3" className={classes.nextStopTitle}>
                      {s.properties.NOM_PARADA}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.nextStopSubTitle}
                    >{`${s.properties.ADRECA} (${s.properties.NOM_DISTRICTE})`}</Typography>
                    <Typography variant="h1" className={classes.time}>
                      {remaining / 1000 < THRESHOLDS.FLASHING ? (
                        remaining / 1000 < THRESHOLDS.LOST ? (
                          'away'
                        ) : (
                          'imminent'
                        )
                      ) : (
                        <Moment fromNow unix>
                          {Math.ceil(object.schedule[0].timestamp / 1000)}
                        </Moment>
                      )}
                    </Typography>
                      */}
                  </Grid>
                  <Grid item className={classes.startStop}>
                    <Typography variant="h4" className={classes.startStopTitle}>
                      {object.properties.ORIGEN_SENTIT}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className={classes.plotItem}>
                  <FlexibleHeightXYPlot
                    //height={750}
                    width={75}
                    stroke="black"
                    margin={{
                      top: GRAPH.MARGIN,
                      left: GRAPH.MARGIN,
                      right: GRAPH.MARGIN,
                      bottom: GRAPH.MARGIN
                    }}
                    xDomain={[-1, 1]}
                  >
                    <GradientDefs>
                      <linearGradient id="PredictionGradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="30%" stopColor={config.palette.accent} stopOpacity={1} />
                        <stop offset="75%" stopColor={config.palette.accent} stopOpacity={1}>
                          <animate
                            attributeName="stop-color"
                            values={`${config.palette.accent}; ${config.palette.primary}; ${config.palette.primary}; ${config.palette.accent};`}
                            dur="4s"
                            repeatCount="indefinite"
                          />
                        </stop>

                        <stop offset="100%" stopColor={config.palette.primary} stopOpacity={1} />
                      </linearGradient>
                    </GradientDefs>

                    {/* End of line stroke */}
                    <LineSeries
                      data={[{ x: 0, y: GRAPH.LAST }, { x: 0, y: POSITIONS.END }]}
                      style={{ stroke: 'black', strokeWidth: '5px', strokeLinecap: 'round' }}
                      //strokeDasharray="3.5% 2.5%"
                    />

                    {/* Multiple next stops stroke line */}
                    <LineSeries
                      data={[{ x: 0, y: GRAPH.START }, { x: 0, y: GRAPH.LAST }]}
                      style={{
                        stroke: config.palette.primary,
                        strokeWidth: '6px',
                        strokeLinecap: 'round'
                      }}
                    />

                    {/* Bus progress */}
                    <AreaSeries
                      color="url(#PredictionGradient)"
                      data={[
                        {
                          x: -0.34,
                          y: GRAPH.START,
                          y0: mlocation()
                        },
                        {
                          x: 0.34,
                          y: GRAPH.START,
                          y0: mlocation()
                        }
                      ]}
                      style={{ strokeWidth: 0 }}
                    />
                    <MarkSeries
                      data={[{ x: 0, y: mlocation() }]}
                      fill={config.palette.accent}
                      size={5}
                      stroke={config.palette.accent}
                      strokeWidth={2}
                    />

                    {/* Multiple next stops markers */}
                    <MarkSeries
                      data={object.schedule.map((s, i) => ({
                        x: 0,
                        y: GRAPH.LAST - POSITIONS.STEP * i
                      }))}
                      fill="white"
                      size={10}
                      stroke={config.palette.primary}
                      strokeWidth={6}
                    />

                    {/* Marker for the next station, flashing */}
                    <MarkSeries
                      data={[{ x: 0, y: GRAPH.LAST - POSITIONS.STEP * next() }]}
                      fill={'white'}
                      size={10}
                      stroke={
                        Math.abs(Math.ceil(mremaining[next()] / 1000) % 2) > 0
                          ? config.palette.accent
                          : config.palette.primary
                      }
                      strokeWidth={6}
                    />

                    {/* End of line marker */}
                    <MarkSeries
                      data={[{ x: 0, y: POSITIONS.END }]}
                      fill="white"
                      size={10}
                      stroke="black"
                      strokeWidth={6}
                    />

                    {/* Stops already covered */}
                    <MarkSeries
                      data={object.schedule
                        .map((s, i) => ({
                          x: 0,
                          y: GRAPH.LAST - POSITIONS.STEP * i
                        }))
                        .filter((e, i) => mremaining[i] < 0)}
                      fill="white"
                      size={10}
                      stroke={config.palette.accent}
                      strokeWidth={6}
                    />

                    {/* Line start */}
                    <MarkSeries
                      data={[{ x: 0, y: GRAPH.START }]}
                      fill="white"
                      size={10}
                      stroke={config.palette.primary}
                      strokeWidth={6}
                    />
                  </FlexibleHeightXYPlot>
                </Grid>
                <Grid
                  item
                  xs={1}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="stretch"
                  className={classes.timeColumn}
                >
                  <Grid item className={classes.endStop}></Grid>
                  <Grid item className={classes.nextStop}>
                    {object.schedule.map(stop => (
                      <section key={`time-for-${stop.id}`} className={classes.stop}>
                        <BusScheduleTime expected={stop.timestamp} />
                      </section>
                    ))}
                  </Grid>
                  <Grid item className={classes.startStop}></Grid>
                </Grid>
              </Grid>
            </Fade>

            {getOthers().vehicles.length > 0 && (
              <Fade in={getOthers().vehicles.length > 0} style={{ transitionDelay: `500ms` }}>
                <Container className={classes.approachingVehicles}>
                  <Typography variant="h4" className={classes.approach}>
                    Also at next station:
                  </Typography>
                  {getOthers().vehicles.map((v, i) => (
                    <Container className={classes.approachingVehicle} key={v.properties.id}>
                      <Fade in style={{ transitionDelay: `${500 + 100 * (i + 1)}ms` }}>
                        <BusSchedule
                          line={v.properties.NOM_LINIA}
                          stop={v.properties.DESC_LINIA}
                          destination={v.properties.DESTI_SENTIT}
                          expected={
                            v.schedule.find(el => el.station === getOthers().station).timestamp
                          }
                        />
                      </Fade>
                    </Container>
                  ))}
                </Container>
              </Fade>
            )}

            {/*
            <Typography variant="h6">Object data</Typography>
            <pre>{JSON.stringify(object, null, 2)}</pre>
            <Typography variant="h6">Station data</Typography>
            <pre>{JSON.stringify(s.properties, null, 2)}</pre>
            <Typography variant="h6">Route data</Typography>
            <pre>{JSON.stringify(r.properties, null, 2)}</pre>
            */}
          </>
        )}
      </Container>
    </div>
  )

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        {sideList}
      </Drawer>
    </div>
  )
}

BusDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  paths: PropTypes.arrayOf(PropTypes.object).isRequired,
  object: PropTypes.shape({
    path: PropTypes.arrayOf(PropTypes.object),
    properties: PropTypes.object,
    schedule: PropTypes.arrayOf(PropTypes.object),
    waypoints: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
}
